/**
 * Add path that pertain to custom path flow
 */
import { gboReservationPath, gmaReservationPath } from 'paths/session';

/* Session - GBO Flags */
export const virginReservationPathFlag = [...gboReservationPath, 'virgin_reservation'];
export const expeditedReservationTypePathFlag = [...gboReservationPath, 'expedited_reservation_type']; // This flag is specially only for Custom Path, if the flag is RETAIL or is not in the response then is a retail reservation

/* Session - GMA Flags */
export const gmaCustomPathBrandInformationPathFlag = [...gmaReservationPath, 'check_in', 'custom_path'];
