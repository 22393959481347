import { actions, services, utils as gmiUtils, setAppConfig } from '@ehi/global-marketing-interface';
import { config as utilsConfig } from 'utils/config';
import utilsLocalization from 'utils/localization';
import { timeoutRedirect, gmaRedirect } from 'utils/redirect';
import { loadingOverlay } from 'utils/dom';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';

import { showSessionTimeoutModal } from "actions/modal/showSessionTimeoutModal";

/** Config for the service call that invalidates user session.
 * This is a bit weird, but since we don't have gmiRequest declared yet,
 *  we separate the config and call it after declaration down below.
 */
const invalidateSessionServiceConfig = {
  action: actions.request({
    service: services.gma.session.invalidate,
  }),
  loadingOverlay: true,
};

/**
 * gmiRequest - Promise based GMI request wrapper.
 *
 * @param {Object} config
 *
 * @returns {Function}
 */
export const gmiRequest = (config) => (dispatch, getState) => {
  const request = config?.action;

  const { opts, service, data } = config;

  const actionType = opts?.actionType ? actions[opts.actionType] : actions.request;

  // Since hash route changes within the SPA,
  // we need to update this everytime a request is made
  const existingGmaHeaders = utilsConfig.getGMIConfig()?.GMA_HEADERS || {};
  const countryCode = utilsLocalization.getAkamaiCountryCode();
  setAppConfig({
    GMA_HEADERS: { ...existingGmaHeaders, HASH_ROUTE: window.location.hash, cdn_country_of_residence: countryCode },
  });

  if (config.loadingOverlay) {
    loadingOverlay(true);
  }
  return dispatch(request || actionType({ service, data, opts }))
    .then((response) => {
      if (gmiUtils.types(config.onComplete).isFunction) {
        // onComplete must return the response
        // or the Promise chain breaks
        return config.onComplete(response);
      }

      return response;
    })
    .then((response) => {
      if (config.redirect !== false) {
        response?.go_to && gmaRedirect(response.go_to);
      }

      /* timoutRedirect.start runs on three conditions:
      - config.timeout is set on fetchSession in spa.js, which starts the timeout when res flow first loads.
      - response.go_to will re-start the timeout whenever res flow transitions. It will not fire when the go_to
      sends user outside of the spa, because the redirect refreshes the page, which stops execution and clears timers 
      - Specified SPA flows, so on each GMA request the timeout is reset */
      const timeoutPages = utilsConfig.isInTimeoutExpectedSPAFlow();

      if (response?.go_to || config.timeout || timeoutPages) {
        const state = getState();
        const isCustomPathReservation = isCustomPathReservationSelector(state);

        timeoutRedirect.start(() => dispatch(showSessionTimeoutModal()));

        // Redirect to restricted timeout page if it's a custom path reservation AWR-5859
        isCustomPathReservation && timeoutRedirect.setCustomRedirect(WINDOW_OBJECT_KEYS.RESTRICTED_TIMEOUT);
      }

      config.loadingOverlay && loadingOverlay(false);

      return response;
    });
};

export const invalidateSession = () => (dispatch) => dispatch(gmiRequest(invalidateSessionServiceConfig));

export const updateSession = () => (dispatch) => dispatch();
