export default {
  VALID_STANDARD_HOURS: 'VALID_STANDARD_HOURS',
  VALID_AFTER_HOURS: 'VALID_AFTER_HOURS',
  INVALID_ALL_DAY: 'INVALID_ALL_DAY',
  INVALID_AT_THAT_TIME: 'INVALID_AT_THAT_TIME',
  MISSING_HOURS: 'MISSING_HOURS',
  PICKUP_LOCATION_HOURS_INVALID: 'CROS_RES_PICKUP_LOCATION_HOURS_INVALID',
  DISCOUNT_CODE_INVALID: 'PRICING_12032',
  CROS_RES_PRE_RATE_ADDITIONAL_FIELD_REQUIRED: 'CROS_RES_PRE_RATE_ADDITIONAL_FIELD_REQUIRED',
  CROS_RES_INVALID_ADDITIONAL_FIELD: 'CROS_RES_INVALID_ADDITIONAL_FIELD',
  GMA_PROFILE_DO_NOT_RENT: 'GMA_PROFILE_DO_NOT_RENT',
  CROS_CREDIT_CARD_AUTH_DENIED: 'CROS_CREDIT_CARD_AUTH_DENIED',
  GBO_COUNTER_BYPASS_TURNED_INELIGIBLE: 'GBO_COUNTER_BYPASS_TURNED_INELIGIBLE',
  LOCATION_WITH_NO_VEHICLES_AVAILABLE: 'PRICING_16007',
  GMA_NO_VEHICLES_AVAILABLE: 'GMA_NO_VEHICLES_AVAILABLE',
  CROS_RES_VEHICLE_NOT_AVAILABLE: 'CROS_RES_VEHICLE_NOT_AVAILABLE',
  INVALID_PICK_UP_TIME_IN_THE_PAST: 'CROS_RES_INVALID_START_TIME_PAST',
  GBO_RESERVATION_STATUS_CLOSED: 'GBO_RESERVATION_STATUS_CLOSED',
  GMA_SEARCH_TRIPS_NO_RESULTS_FOUND: 'GMA_SEARCH_TRIPS_NO_RESULTS_FOUND',
};
