import { isValidDateFieldSetInput, getGBOFormatDateFromDateFieldSet } from './date';
import { formatPhoneDataBasedOnCountryCode } from './profile';

/**
 * @name driverProfilePayloadParser
 * @desc Helper function that parses form and state data into the payload format expected by updateProfile.
 *
 * @param {object} formData - Object containing all the form data from the OCI Profile step
 *  - includes Driver's License, Address and Contact Information.
 * @param {object} state - Redux state object - to be read via selectors. Used to get data from user profile/reservation
 *
 * @returns {object}
 */
export function driverProfilePayloadParser(formData, profile, reservationName) {
  const {
    license_info = {},
    address_info: { address_line_1, address_line_2, ...addressFormData } = {},
    contact_info: { phone_set, email } = {},
    license_issue_date,
    license_expiration_date,
    birth_date,
  } = formData;

  // Basic Info (First/Last name) goes both in the DL section and the 'root' of the payload.
  // We can't really change these for any of the profile scenarios, but we send them anyway
  //  in case it's a no match scenario (in which a new profile is created for us by GMA).
  const basicInfo = {
    first_name: license_info.first_name || profile?.basic_profile.first_name || reservationName?.firstName,
    last_name: license_info.last_name || profile?.basic_profile.last_name || reservationName?.lastName,
  };

  // Drivers License Information section
  // format fieldset values into GBO-friendly date strings (with current profile ones as defaults)
  let birthDate = profile?.basic_profile.birth_date || profile?.license_profile.birth_date;
  if (isValidDateFieldSetInput(birth_date)) {
    birthDate = getGBOFormatDateFromDateFieldSet(birth_date, birthDate);
  }
  let expirationDate = profile?.license_profile.license_expiration_date;
  if (isValidDateFieldSetInput(license_expiration_date)) {
    expirationDate = getGBOFormatDateFromDateFieldSet(license_expiration_date, expirationDate);
  }
  let issueDate = profile?.license_profile.license_issue_date;
  if (isValidDateFieldSetInput(license_issue_date)) {
    issueDate = getGBOFormatDateFromDateFieldSet(license_issue_date);
  }

  const driversLicenseData = {
    ...basicInfo,
    country_code: license_info.country_code || profile?.license_profile.country_code,
    country_subdivision_code:
      license_info.country_subdivision_code || profile?.license_profile.country_subdivision_code,
    license_number: license_info.license_number || profile?.license_profile.license_number,
    birth_date: birthDate,
    license_expiration_date: expirationDate,
    license_issue_date: issueDate,
    issuing_authority: license_info?.issuing_authority,
  };

  // Contact Information section
  const contactData = {
    phones: formatPhoneDataBasedOnCountryCode(phone_set || profile?.contact_profile.phones),
    email,
  };

  // Address Information section
  const streetAddresses = [];
  if (address_line_1) {
    streetAddresses.push(address_line_1);
    if (address_line_2) {
      streetAddresses.push(address_line_2);
    }
  } else if (profile) {
    streetAddresses.push(...profile.address_profile.street_addresses);
  }

  const addressData = {
    street_addresses: streetAddresses,
    country_code: addressFormData.country_code || profile?.address_profile.country_code,
    country_subdivision_code:
      addressFormData.country_subdivision_code || profile?.address_profile.country_subdivision_code,
    city: addressFormData.city || profile?.address_profile.city,
    postal: addressFormData.postal || profile?.address_profile.postal,
  };

  return {
    ...basicInfo,
    date_of_birth: birthDate,
    drivers_license: driversLicenseData,
    contact: contactData,
    address: addressData,
    email,
    phones: contactData.phones,
  };
}
export function contactInfoPayloadParser(formData) {
  const { phones = null, phone_set = null, contact_info = {} } = formData;

  const phoneArray = phones || phone_set || contact_info?.phone_set;
  const phoneData = formatPhoneDataBasedOnCountryCode(phoneArray);
  // Contact Information section
  const contactData = {
    phones: phoneData.filter((item) => item?.phone_number.length > 0),
  };

  return {
    contact: contactData,
    phones: contactData.phones,
  };
}

export function driverInfoPhonePayloadParser(phone) {
  if (phone) {
    return {
      phone: {
        country_code: phone.country_code,
        phone_number: phone.phone_number,
      },
      phone_country_code: phone.country_code,
    };
  }
  return {};
}
