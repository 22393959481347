import { Map } from 'immutable';
import { PROGRESS_BAR_ACTIONS } from 'actions/progressBar';
import { RESERVATIONS } from 'constants';
import { BOOKING_WIDGET_ACTIONS } from 'actions/bookingWidget';
import {
  appExpandedTabPath,
  appMobileExpandedPath,
  appTotalDropDownExpandedTabPath,
  appDiscountCodesExpandedTabPath,
  appShowAdditionalDriverIsSpousePath,
  appInResCheckInSaveTimePath,
  appReservationSessionLDTPath,
  appProgressBarLocationInitiatePath,
} from 'paths/app';
import { IN_RESERVATION } from "actions/reservation/IN_RESERVATION";
import { ADDITIONAL_DRIVER } from "actions/reservation/ADDITIONAL_DRIVER";

const initialState = Map({
  progressBar: Map({
    expandedTab: null,
    mobileExpanded: false,
    totalDropDownExpanded: false,
    discountCodesExpanded: false,
    progressBarLocationInitiate: true,
  }),
  inResCheckInSaveTimeSelected: JSON.parse(sessionStorage.getItem('isSelectedInResOCISaveTime')) || null,
  sessionLDT: {},
});

const ACTION_HANDLERS = {
  [PROGRESS_BAR_ACTIONS.SET_EXPANDED_TAB]: (state, { id }) => state.setIn(appExpandedTabPath, id),
  [PROGRESS_BAR_ACTIONS.SET_MOBILE_EXPANDED]: (state, { expanded }) => state.setIn(appMobileExpandedPath, expanded),
  [PROGRESS_BAR_ACTIONS.SET_TOTAL_DROPDOWN_MOBILE_EXPANDED]: (state, { id }) =>
    state.setIn(appTotalDropDownExpandedTabPath, id),
  [PROGRESS_BAR_ACTIONS.SET_PROGRESS_BAR_LOCATION_INITIATE]: (state, { initiateState }) =>
    state.setIn(appProgressBarLocationInitiatePath, initiateState),

  [BOOKING_WIDGET_ACTIONS.SET_EXPANDED_TAB]: (state, { expanded }) =>
    state.setIn(appDiscountCodesExpandedTabPath, expanded),
  [ADDITIONAL_DRIVER.SET_SPOUSE_ADDITIONAL_DRIVER]: (state, { data }) =>
    state.setIn(appShowAdditionalDriverIsSpousePath, data),
  [IN_RESERVATION.IN_RES_ACCELERATE_CHECK_IN_SAVE_TIME]: (state, { data }) => {
    sessionStorage.setItem('isSelectedInResOCISaveTime', data);
    return state.setIn(appInResCheckInSaveTimePath, data);
  },
  [RESERVATIONS.RESERVATION_SESSION_PATH]: (state, { data }) => state.setIn(appReservationSessionLDTPath, data),
};

/**
 * Action handling for modal state updates
 * @param {Object} state An immutable state object to check against
 * @param {Object} action Data to update the redux store state
 * @returns {Object} A (usually changed) immutable state object
 */
const reducer = (state = initialState, action = null) => {
  const handler = action ? ACTION_HANDLERS[action.type] : null;
  return handler ? handler(state, action) : state;
};

export default {
  initialState,
  reducer,
};
