export const updateRangeFilter = ({ filters, keyPath, value }) => {
    // find index of this specific filter in active filters
    const filtersIndex = filters.findIndex(filter => filter.keyPath === keyPath);

    // build filter object
    const filter = {
        keyPath,
        values: v => parseInt(v, 10) >= parseInt(value, 10),
        radioValue: value,
    };

    if (filtersIndex !== -1) {
        // if filter already exists
        if (value) {
            // if selecting a range of values
            filters.splice(filtersIndex, 1, filter);
        } else {
            // if deleting a value
            filters.splice(filtersIndex, 1);
        }
    } else if (value) {
        // if filter doesn't exist
        filters.push(filter);
    }

    return filters;
};