import URL from 'constants/url';
import { utils } from '@ehi/global-marketing-interface';
import { config } from 'utils/config';
import RESERVATIONS from 'constants/reservations';
import { i18n } from './i18n';
import { formatNumber } from './number';

/**
 * Converts Solr Location Object into Map Marker
 */
export const locationsMarkerMapper = ({ marker, googleMaps }) => ({
  position: new googleMaps.LatLng(marker.gps.latitude, marker.gps.longitude),
  icon: 'http://placekitten.com/g/45',
  title: marker.id,
});

const searchTerm = 'ALAMO+RENT+A+CAR';
/**
 * Converts Google Maps Lat / Lng functions into Solr latitude / longitude params
 */
const googleMapLatLngToSolr = ({ lat, lng }) => ({
  latitude: lat(),
  longitude: lng(),
});

/**
 * Converts Center / NE Corner params into latitude / longitude / radius for solr spatial query
 */
export const googleMapBoundsToSolr = ({ center: gCenter, northEastCorner: gNorthEastCorner }) => {
  const center = googleMapLatLngToSolr(gCenter);
  const northEastCorner = googleMapLatLngToSolr(gNorthEastCorner);
  const radius = utils.getKilometersBetweenPoints(center, northEastCorner);
  return {
    ...center,
    radius,
  };
};

/**
 * Returns the calculation from kilometers to miles
 */
export const convertKilometersToMiles = (distance) => distance * 0.62137119;
/**
 * Returns the calculation from miles to kilometers
 */
export const convertMilesToKilometers = (distance) => distance / 0.62137119;

/**
 * getDistanceLabel - Get distance formatted with unit (e.g. 4,600 mi)
 * @param {*} amount - Distance amount
 * @param {*} unit - Distance unit
 * @return {string} formatted distance
 */
export const getDistanceLabel = (amount, unit) => {
  const distance = formatNumber(amount);
  if (unit === RESERVATIONS.DISTANCE_UNIT.MILES) {
    return `${distance} ${i18n('generic_miles_short')}`;
  }
  return `${distance} ${i18n('generic_kilometers_short')}`;
};

export const addressObjToSingleLine = (unFormattedAddress) => {
  //  TODO: this is a temp function, we will formalize formatted of address later in sprint
  if (unFormattedAddress) {
    const { city, country_subdivision_code, country_code, postal, street_addresses } = unFormattedAddress;
    const addressParts = [...street_addresses, city, country_subdivision_code, country_code, postal];
    return addressParts.join(' ');
  }
  return null;
};

// Gets encoded URL for a google maps search
export const getGoogleMapsURL = (address) =>
  `${URL.GOOGLE_MAPS_BASE_LINK}/?api=1&query=${encodeURIComponent(address)}+${encodeURIComponent(searchTerm)}`;

// This is for google maps to be able to use the correct translation when opening nearby gas stations
export const getGasStationURL = (address, gps) => {
  const encodeString = (str) => encodeURIComponent(str.replace(/\s/g, '+'));

  const term = encodeString(i18n('google_search_gas_stations').toLowerCase());
  const addr = encodeString(address);
  const brand = searchTerm.toLowerCase();
  const lat = Number(gps.latitude).toFixed(5);
  const lng = Number(gps.longitude).toFixed(5);

  return `${URL.GOOGLE_MAPS_BASE_LINK}/${term}/@${lat},${lng},15z/data=!3m1!4b1!4m8!2m7!3m6!1s${term}!2s${brand},${addr}!3s0x89c26704d155f2cd:0x73566b659132bd3e!4m2!1d${lng}!2d${lat}`;
};

// Get address formatted (e.g. Saint Louis, MO 63134)
export const getFormattedAddress = (address = {}) => {
  if (address?.country_subdivision_code) {
    return `${address?.city || ''}, ${address?.country_subdivision_code || ''} ${address?.postal || ''}`;
  }
  return `${address?.city || ''} ${address?.postal || ''}`;
};
/**
 * @name getLocationCompleteName
 * @description Returns the location name and, if it is a airport location, airport code in parentheses.
 * @example
 * const nonAirportLocation = { name: 'City Location', ...}
 * getLocationCompleteName(nonAirportLocation) // "City Location"
 * @example
 * const airportLocation = { name: 'Airport Example', airport_code: 'ABC' ...}
 * getLocationCompleteName(airportLocation) // "Airport Example (ABC)"
 * @param {object} location - location object from reservation in session
 * @param {string} location.name
 * @param {string} location.airport_code
 * @returns {string}
 */
export const getLocationCompleteName = ({ name, airport_code }) =>
  name ? `${name}${airport_code ? ` (${airport_code})` : ''}` : '';

/**
 * @name findCountryCode
 * @description Returns the object in the array that cointains the country code passed
 * @param {array} arr - array to find the country code
 * @param {string} countryCode
 * @returns {object}
 */
export const findCountryCode = (arr, countryCode) => arr.find(({ country_code }) => country_code === countryCode);

const domainCountryCode = config.getDomainCountry();
const currentLanguageCode = config.getLanguage();

/**
 * Returns the boolean value if the country code & language code are matches with the input param.
 *
 * @returns {boolean}
 */
export const isDomainCodeLanguageCodeEqual = (countryCode, languageCode) =>
  domainCountryCode === countryCode && currentLanguageCode === languageCode;
