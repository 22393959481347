/* 
Util function to have everything at one place related to Session Storage 
 */

const SessionStorage = {
  get(key, parse = false) {
    const item = sessionStorage.getItem(key);
    if (item && parse) {
      return JSON.parse(item);
    }
    return item;
  },
  set(key, val, stringify = false) {
    // We don't want to stringify items that are already strings
    sessionStorage.setItem(key, stringify ? JSON.stringify(val) : val);
  },
  remove(key) {
    sessionStorage.removeItem(key);
  },
  clear() {
    sessionStorage.clear();
  },
};

const LocalStorage = {
  get(key, parse = false) {
    const item = localStorage.getItem(key);
    if (item && parse) {
      return JSON.parse(item);
    }
    return item;
  },
  set(key, val, stringify = false) {
    // We don't want to stringify items that are already strings
    localStorage.setItem(key, stringify ? JSON.stringify(val) : val);
  },
  remove(key) {
    localStorage.removeItem(key);
  },
  clear() {
    localStorage.clear();
  },
};

export default {
  SessionStorage,
  LocalStorage,
};
