import { createSelector } from 'reselect';
import utils from 'utils';
import { utils as globalUtils } from '@ehi/global-marketing-interface';
import GMI from 'constants/gmiConstants';
import LOCATIONS from 'constants/locations';
import RESERVATIONS from 'constants/reservations';
import {
  gboSessionPath,
  gboReservationConfirmationNumber,
  collectNewPaymentCardModifyPath,
  reservationBarcodePath,
  gmaPreSelectedReservationPath,
  gmaPreSelectedMessagesPath,
  autoCheckInFlagPath,
  oneWayRentalGmaPath,
  keyFactsPoliciesPath,
  gmaInitiateRequestPath,
  gmaInitiatePickupLocation,
  gmaInitiateReturnLocation,
  gboUnauthPastTripsPath,
  gmaDeeplinkPath,
  reservationRentalStatePath,
  gmaInitiatePickupTimePath,
  gmaInitiateReturnTimePath,
  tourVoucherReservationPath,
  secretRatePath,
  gboReservationPath,
  commitReservationRequestPath,
  gmaShowDriveTypeFilterPath,
} from 'paths/session';
import { carClassDetailsPath, carClassPriceDifferencePath, carClassMileageInfoPath } from 'paths/carClassDetails';
import {
  afterHoursPickupSelectedPath,
  pickupLocationPath,
  pickupTimePath,
  returnTimePath,
  pickupLocationTypePath,
  pickupLocationMultiTerminalPath,
  reservationAirlineInfoPath,
  returnLocationPath,
  oneWayRentalPath,
  rentalTermsAndConditionsPath,
  reservationTravelIndicatorPath,
  pickupWithDetailLicenseePath,
  licenseeNamePath,
  legalNamePath,
} from 'paths/reservationLocation';
import {
  selectedPaymentMethodPath,
  paymentsPath,
  cardSubmissionUrlPath,
  sessionIdPath,
} from 'paths/reservationPayment';
import {
  reservationDriverInfoPath,
  reservationProfileLoyaltyNumberPath,
  reservationDriverFirstName,
  reservationDriverLastName,
  reservationDriverEmail,
  reservationDriverPhoneNumber,
} from 'paths/reservationProfile';
import {
  reservationContractDetailsPath,
  gmaPreSelectedReservationContractDetailsPath,
  gmaPreSelectedReservationCouponsPath,
  gmaPreSelectedReservationBillingAccountPath,
  gmaInitiateContractNumberPath,
} from 'paths/reservationContract';
import { uiStateDriverProfilePath } from 'paths/uiState';
import { vehicleReservationPath } from 'paths/reservationVehicle';
import { appShowAdditionalDriverIsSpousePath, appReservationSessionLDTPath } from 'paths/app';
import { isLocationWithNoVehiclesAvailableSelector } from 'selectors/errorSelectors';
import {
  priceSummarySelector,
  appliedCancelFeeDetailsSelector,
  getDidPrepay,
} from 'selectors/reservationFlowSelectors';
import { profileDataSelector } from 'selectors/profileSelectors';

const reservationSessionObjectCursor = ['gmi', 'session', 'gbo', 'reservation'];

// ---- Getters -----
export const getSessionGboReservationExists = (state) => !!state.getIn(reservationSessionObjectCursor);
export const getSessionGboReservation = (state) => state.getIn(reservationSessionObjectCursor);
export const sessionInitiateRequest = (state) => state.getIn(gmaInitiateRequestPath);

// use when you need the reservation object
// but perhaps have not set a branch-location yet. (only selected a city)
export const sessionReservationObjectSelector = (state) =>
  state.getIn(['gmi', 'session', 'gbo', 'reservation']) || // will return here if a branch were chosen
  sessionInitiateRequest(state) || // will return here if only a city were chosen
  null;

export const getModifyFlag = (state) => state.getIn(['gmi', 'session', 'gma', 'modify']);

const isGBOSessionEmpty = (state) => {
  const gboSessionObj = utils.safeToJS(state.getIn(gboSessionPath));
  return gboSessionObj && Object.keys(gboSessionObj).length === 0;
};

export const isLoyaltyData = (state) => {
  const profileData = profileDataSelector(state, { memberProgram: true }) || {};
  return profileData?.memberProgram ? profileData?.memberProgram !== RESERVATIONS.PROFILE.LOYALTY_PROGRAM : true;
};

export const getProfileData = (state) =>
  profileDataSelector(state, { email_update_required: true, email_preference: true }) || {};

export const getOneWayRentalResFlow = (state) =>
  // getModifyFlag is for adding a different return in true modify, need to get GMA one_way_rental state instead of GBO's
  // isGBOSessionEmpty is to check if GBO is empty, so would need to use GMA one_way_rental state instead of GBO's
  // if location is sold out, need to get the GMA one_way_rental state instead of GBO's
  isLocationWithNoVehiclesAvailableSelector(state) ||
  getModifyFlag(state) ||
  isGBOSessionEmpty(state) ||
  isLoyaltyData(state)
    ? state.getIn(oneWayRentalGmaPath)
    : state.getIn(oneWayRentalPath);

// TODO: Maybe move this to an extras selectors file?
export const getExtrasInvalidFlag = (state) => state.getIn(['gmi', 'session', 'gma', 'modify_previous_extras_invalid']);

export const isEuropeanUnionCountry = (state) =>
  state.getIn(['gmi', 'session', 'gbo', 'reservation', 'european_union_country']);

const getCountrySupportNumbers = (state) => {
  const countryCode = state.getIn([
    'gmi',
    'session',
    'gbo',
    'reservation',
    'pickup_location',
    'address',
    'country_code',
  ]);
  return state.getIn(['gmi', 'gma', 'content', 'support', 'contact', countryCode, 'support_phone_numbers']);
};

const reservationCouponsCursor = [...gboReservationPath, 'coupons'];

export const getRentalTermsAndConditions = (state) => state.getIn(rentalTermsAndConditionsPath);
export const getPickupLocationLicensee = (state) => state.getIn(pickupWithDetailLicenseePath);
export const getLicenseeName = (state) => state.getIn(licenseeNamePath);
export const getLegalName = (state) => state.getIn(legalNamePath);

// TODO: change to use a path instead
export const getReservationRenterInfoFirstName = (state) => state.getIn(reservationDriverFirstName);

export const getReservationRenterInfoLastName = (state) => state.getIn(reservationDriverLastName);

export const getReservationRenterInfoEmail = (state) => state.getIn(reservationDriverEmail);

export const getReservationRenterInfoPhoneNumber = (state) => state.getIn(reservationDriverPhoneNumber);

export const getReservationPickupLocation = (state) => utils.safeToJS(state.getIn(pickupLocationPath), {});

export const getReservationPickupTime = (state) => state.getIn(pickupTimePath);

export const getReservationReturnLocation = (state) => utils.safeToJS(state.getIn(returnLocationPath), {});

export const getReservationReturnTime = (state) => state.getIn(returnTimePath);

export const getUnauthPastTrips = (state) => state.getIn(gboUnauthPastTripsPath);

const getPaymentsObject = (state) => state.getIn(paymentsPath);

export const getContractDetails = (state) => state.getIn(reservationContractDetailsPath);

const getHoursForStore = (state, locationId) => state.getIn(['gmi', 'solr', 'hours', locationId, 'data']);

const getModalQueue = (state) => state.getIn(['app', 'modal', 'modalQueue']);

const getInflightPayment = (state) => state.getIn(GMI.STATE.GMA.RESERVATION.PAYMENT);

const getCarClassDetails = (state) => state.getIn(carClassDetailsPath);

const getGuaranteedVehicleFlag = (state) =>
  state.getIn([...reservationSessionObjectCursor, 'car_class_details', 'guaranteed_vehicle']);

export const getGuaranteedVehicleRequiresCardFlag = (state) =>
  state.getIn([...reservationSessionObjectCursor, 'car_class_details', 'guaranteed_res_credit_card_required']);

const getCarClassPriceDifferences = (state) => state.getIn(carClassPriceDifferencePath);

// selected_payment_method session property appears to be where card details for cards that have not been charged yet go,
// like for guaranteed res
export const getSelectedPaymentMethod = (state) => state.getIn(selectedPaymentMethodPath);

const getMileageInfo = (state) => state.getIn(carClassMileageInfoPath);

export const getIsAfterHoursPickup = (state) => state.getIn(afterHoursPickupSelectedPath);

const getPickupLocationType = (state) => state.getIn(pickupLocationTypePath);
export const getPickupLocationMultiTerminal = (state) => state.getIn(pickupLocationMultiTerminalPath);
export const getReservationAirlineInfo = (state) => state.getIn(reservationAirlineInfoPath);
export const getReservationTravelIndicator = (state) => state.getIn(reservationTravelIndicatorPath);
export const getCommitReservationRequest = (state) => utils.safeToJS(state.getIn(commitReservationRequestPath), {});

const getReservationDriverInfo = (state) => state.getIn(reservationDriverInfoPath);
export const getReservationProfileLoyaltyNumber = (state) => state.getIn(reservationProfileLoyaltyNumberPath);

const getUiReservationsDriverProfile = (state) => state.getIn(uiStateDriverProfilePath);

export const getOneWayRentalFlag = (state) => state.getIn(oneWayRentalPath);
export const getOneWayGmaRentalFlag = (state) => state.getIn(oneWayRentalGmaPath);

export const getCollectNewPaymentCardInModify = (state) => state.getIn(collectNewPaymentCardModifyPath);

export const getReservationConfirmationNumber = (state) => state.getIn(gboReservationConfirmationNumber);

const getReservationBarcode = (state) => state.getIn(reservationBarcodePath);

const getPreSelectedReservation = (state) => state.getIn(gmaPreSelectedReservationPath);
const getPreselectedReservationContractDetails = (state) => state.getIn(gmaPreSelectedReservationContractDetailsPath);
const getPreselectedReservationCoupons = (state) => state.getIn(gmaPreSelectedReservationCouponsPath);
export const getPreselectedReservationBillingAccount = (state) =>
  state.getIn(gmaPreSelectedReservationBillingAccountPath);
const getPreselectedMessages = (state) => state.getIn(gmaPreSelectedMessagesPath);

export const getCardSubmissionUrl = (state) => state.getIn(cardSubmissionUrlPath);

export const getSessionId = (state) => state.getIn(sessionIdPath);

export const getAutoOnlineCheckInFlag = (state) => state.getIn(autoCheckInFlagPath);

export const getIsDeeplink = (state) => state.getIn(gmaDeeplinkPath);

const getTourVoucherReservation = (state) => state.getIn(tourVoucherReservationPath);

const getSecretRate = (state) => state.getIn(secretRatePath);

const getReservationCoupons = (state) => state.getIn(reservationCouponsCursor);

export const reservationSessionLDT = (state) => state.getIn(appReservationSessionLDTPath);

export const getPreSelectedAdditionalInfo = (state) =>
  state.getIn(['gmi', 'session', 'gbo', 'reservation', 'additional_information']);

const getCarClassFilters = (state) => state.getIn(['gmi', 'session', 'gbo', 'reservation', 'car_classes_filters']);

// ---- Selectors ------

export const carClassFiltersSelector = createSelector([getCarClassFilters], (result) => result);

export const modalQueueSelector = createSelector([getModalQueue], (modalQueue) => modalQueue.get(0));

export const debitPaymentObjectSelector = createSelector([getPaymentsObject], (paymentsObject) =>
  utils.safeToJS(paymentsObject?.find((paymentObj) => paymentObj.get('transaction_type') === 'DEBIT'))
);

export const creditPaymentObjectSelector = createSelector([getPaymentsObject], (paymentsObject) =>
  utils.safeToJS(paymentsObject?.find((paymentObj) => paymentObj.get('transaction_type') === 'CREDIT'))
);

export const getSessionPickupLocation = (state) => state.getIn(pickupLocationPath);

export const getSessionReturnLocation = (state) => state.getIn(returnLocationPath);

export const getInitiatePickupLocation = (state) => state.getIn(gmaInitiatePickupLocation);

export const getInitiateReturnLocation = (state) => state.getIn(gmaInitiateReturnLocation);

export const getInitiatePickupTime = (state) => state.getIn(gmaInitiatePickupTimePath);

export const getInitiateReturnTime = (state) => state.getIn(gmaInitiateReturnTimePath);

export const getInitiateContractNumber = (state) => state.getIn(gmaInitiateContractNumberPath);

export const getReservationRentalState = (state) => state.getIn(reservationRentalStatePath);

export const getShouldShowDriveTypeFilter = (state) => {
  const showDriveTypeFilter = state.getIn(gmaShowDriveTypeFilterPath);

  return !!showDriveTypeFilter;
};

const getExclusions = (state, lrdPolicyCode) =>
  lrdPolicyCode
    ? state
        .getIn(keyFactsPoliciesPath)
        ?.find((policy) => policy.get('code') === lrdPolicyCode)
        ?.getIn(['policy_exclusions', 0]) // TODO: Can there be more than one policy in the policy_exclusions array?
    : null;

export const exclusionsSelector = createSelector([getExclusions], (exclusions) =>
  exclusions
    ? {
        exclusionsDescription: exclusions.get('description'),
        exclusionsText: exclusions.get('policy_text'),
      }
    : null
);

export const getReservationSkipTheCounterBarcode = createSelector([getReservationBarcode], (barcodeData) =>
  utils.safeToJS(barcodeData)
);

export const getThirdPartyReservationSupportNumberObjectSelector = createSelector(
  [getCountrySupportNumbers],
  (phoneNumbersObject) =>
    utils.safeToJS(phoneNumbersObject?.find((phoneNum) => phoneNum?.get('phone_type') === 'THIRDPARTY_RESERVATION'))
);

export const contractDetailsSelector = createSelector([getContractDetails], (contractDetails) =>
  contractDetails
    ? {
        contractName: contractDetails.get('contract_name'),
        contractType: contractDetails.get('contract_type'),
        defaultLoyaltyContract: contractDetails.get('default_loyalty_contract'),
        contractAcceptsBilling: contractDetails.get('contract_accepts_billing'),
        contractBillingAccount: contractDetails.get('contract_billing_account'),
        contractRequirements: utils.safeToJS(
          contractDetails
            .get('additional_information')
            ?.filter((requirement) => !requirement.get('validate_additional_info')),
          []
        ),
      }
    : null
);

export const isTourReservationSelector = createSelector(
  [getContractDetails],
  (contractDetails) => contractDetails?.get('contract_sub_type') === RESERVATIONS.TOUR_RESERVATION
);

export const isLastMinuteSpecialsReservationSelector = createSelector(
  [getContractDetails],
  (contractDetails) => contractDetails?.get('contract_sub_type') === RESERVATIONS.LAST_MINUTE_SPECIALS
);

export const isPlanAheadSpecialsReservationSelector = createSelector(
  [getContractDetails],
  (contractDetails) => contractDetails?.get('contract_sub_type') === RESERVATIONS.PLAN_AHEAD_SPECIALS
);

export const marketingMessageIndicatorSelector = createSelector([getContractDetails], (contractDetails) =>
  contractDetails?.get('marketing_message_indicator')
);

export const getHoursForStoreSelector = createSelector([getHoursForStore], (hoursForStore) =>
  utils.safeToJS(hoursForStore)
);

export const inflightPaymentSelector = createSelector([getInflightPayment], (inflightPayment) =>
  inflightPayment
    ? {
        number: inflightPayment.get('masked_number'),
        card_type: inflightPayment.get('card_type'),
        payment_id: inflightPayment.get('payment_session_id'),
      }
    : null
);

// //card_type: selectedPaymentMethod.get('card_type'),
export const selectedPaymentMethodSelector = createSelector([getSelectedPaymentMethod], (selectedPaymentMethod) =>
  selectedPaymentMethod
    ? {
        number: selectedPaymentMethod.get('masked_number'),
        card_type: selectedPaymentMethod.get('payment_subtype'),
        payment_id: selectedPaymentMethod.get('payment_reference_id'),
      }
    : null
);

export const carClassDetailsSelector = createSelector([getCarClassDetails], (carClassDetails) =>
  utils.safeToJS(carClassDetails, {})
);

export const guaranteedResCardRequiredSelector = createSelector(
  [getGuaranteedVehicleFlag, getGuaranteedVehicleRequiresCardFlag],
  (guaranteedVehicle, cardRequired) => guaranteedVehicle && cardRequired
);

export const havePriceDifferenceSelector = createSelector([getCarClassPriceDifferences], (priceDifferences) =>
  utils.gmi.isArrayNotEmpty(utils.safeToJS(priceDifferences))
);

export const previousReservationPriceDifferenceSelector = createSelector(
  [getCarClassPriceDifferences],
  (priceDifferences) =>
    utils.safeToJS(
      priceDifferences?.find(
        (item) => item.get('difference_type') === RESERVATIONS.PRICE_DIFFERENCE_TYPE.PREVIOUS_RESERVATION
      ),
      {}
    )
);

export const unpaidRefundReservationPriceDifferenceSelector = createSelector(
  [getCarClassPriceDifferences],
  (priceDifferences) =>
    utils.safeToJS(
      priceDifferences?.find(
        (item) => item.get('difference_type') === RESERVATIONS.PRICE_DIFFERENCE_TYPE.UNPAID_REFUND_AMOUNT
      ),
      {}
    )
);

export const mileageInfoSelector = createSelector([getMileageInfo], (mileageInfo) => utils.safeToJS(mileageInfo));

export const reservationVehicleSummarySelector = (state) => {
  const vehicleDetails = state.getIn(carClassDetailsPath);
  return {
    name: vehicleDetails?.get('name'),
    similarText: vehicleDetails?.get('make_model_or_similar_text'),
    images: utils.safeToJS(vehicleDetails?.get('images')),
    code: vehicleDetails?.get('code'),
  };
};

export const reservationVehicleDetailsSelector = (state) => {
  const vehicle = state.getIn(vehicleReservationPath);
  return {
    color: vehicle?.get('color'),
    model: vehicle?.get('model'),
    license_plate: vehicle?.get('license_plate'),
  };
};

export const pickupLocationIsAirportSelector = (state) =>
  getPickupLocationType(state) === LOCATIONS.TYPE_AIRPORT.toLowerCase();

export const reservationAirlineInfoSelector = (state) => utils.safeToJS(getReservationAirlineInfo(state), {});

export const travelIndicatorIsFlightSelector = (state) =>
  getReservationTravelIndicator(state) === RESERVATIONS.TRAVEL_INDICATOR_FLIGHT;

const sessionOrInitiateLocation = (isTrueModify, sessionLocationData, initiateLocationData) => {
  const sessionLocation = utils.safeToJS(sessionLocationData, {});
  const initiateLocation = utils.safeToJS(initiateLocationData, {});

  const hasSessionLocation = !utils.gmi.isObjectEmpty(sessionLocation);
  const hasInitiateLocation = !utils.gmi.isObjectEmpty(initiateLocation);

  let location = null;

  // for true modify, prioritize initiate location
  // otherwise prioritize session location
  // then fallback on initiate (for non-true modify scenario)
  // else return null
  if (isTrueModify && hasInitiateLocation) {
    location = initiateLocation;
  } else if (hasSessionLocation) {
    location = sessionLocation;
  } else if (hasInitiateLocation) {
    location = initiateLocation;
  }

  return location;
};

// chooses the location to display based on whether or not we're in a True Modify
// specifically created for the Location Finder res-flow view
export const selectedPickupLocationSelector = createSelector(
  [getModifyFlag, getSessionPickupLocation, getInitiatePickupLocation],
  sessionOrInitiateLocation
);

// chooses the location to display based on whether or not we're in a True Modify
// specifically created for the Location Finder res-flow view
export const selectedReturnLocationSelector = createSelector(
  [getModifyFlag, getSessionReturnLocation, getInitiateReturnLocation],
  sessionOrInitiateLocation
);

export const pickupLocationSTCWayfindingsSelector = createSelector([getReservationPickupLocation], (pickupLocation) =>
  pickupLocation?.wayfindings?.filter((item) => item.level_of_service_code === RESERVATIONS.LEVEL_OF_SERVICE.QR)
);

export const isLoyaltyReservationSelector = createSelector(
  [getReservationProfileLoyaltyNumber],
  (reservationProfileLoyaltyNumber) => !!reservationProfileLoyaltyNumber
);

export const reservationDriverInfoSelector = createSelector([getReservationDriverInfo], (driverInfo) =>
  utils.safeToJS(driverInfo)
);

export const uiReservationDriverProfileSelector = createSelector([getUiReservationsDriverProfile], (driverProfile) => ({
  license_profile: {
    country_code: driverProfile.getIn(['drivers_license', 'country_code']),
    country_name: driverProfile.getIn(['drivers_license', 'country_name']),
    country_subdivision_code: driverProfile.getIn(['drivers_license', 'country_subdivision_code']),
    license_number: driverProfile.getIn(['drivers_license', 'license_number']),
    license_expiration_date: driverProfile.getIn(['drivers_license', 'license_expiration_date']),
    birth_date: driverProfile.getIn(['date_of_birth']),
  },
  address_profile: {
    street_addresses: utils.safeToJS(driverProfile.getIn(['address', 'street_addresses']), []),
    country_name: driverProfile.getIn(['address', 'country_name']),
    country_code: driverProfile.getIn(['address', 'country_code']),
    country_subdivision_code: driverProfile.getIn(['address', 'country_subdivision_code']),
    city: driverProfile.getIn(['address', 'city']),
    postal: driverProfile.getIn(['address', 'postal']),
  },
  contact_profile: {
    phones: utils.safeToJS(driverProfile.getIn(['contact', 'phones']), []),
  },
}));

export const rentalTermsAndConditions = createSelector([getRentalTermsAndConditions], (rentalTerms) =>
  utils.safeToJS(rentalTerms)
);

export const isLicensee = createSelector([getPickupLocationLicensee], (flagValue) => flagValue);

export const licenseeName = createSelector([getLicenseeName], (licenseeNameVal) => licenseeNameVal);

export const legalName = createSelector([getLegalName], (legalNameVal) => legalNameVal);

export const preSelectedReservationRetrieveDataSelector = createSelector(
  [getPreSelectedReservation],
  (preSelectedReservation) =>
    preSelectedReservation && {
      firstName: preSelectedReservation.getIn(['driver_info', 'first_name']),
      lastName: preSelectedReservation.getIn(['driver_info', 'last_name']),
      confirmationNumber: preSelectedReservation.get('confirmation_number'),
    }
);

export const preSelectedMessagesSelector = createSelector([getPreselectedMessages], (messages) =>
  utils.safeToJS(messages)
);

export const preSelectedCouponsSelector = createSelector([getPreselectedReservationCoupons], (coupons) =>
  utils.safeToJS(coupons)
);

export const preSelectedContractNumberSelector = createSelector(
  [getPreselectedReservationContractDetails],
  (contractDetails) => contractDetails?.contract_number
);

/**
 * Verifies if the reservation is currently being modified, either inflight or true modify.
 * - For inflight, we consider if the reservation does not have a confirmation number yet;
 * - For true modify, we consider the GMA session flag.
 */
export const isInflightOrTrueModifySelector = createSelector(
  [getReservationConfirmationNumber, getModifyFlag],
  (confirmationNumber, isTrueModify) => !confirmationNumber || isTrueModify
);

export const getInitiateRequest = createSelector([sessionInitiateRequest], (initiate) => utils.safeToJS(initiate, {}));

export const unauthPastTripsSelector = createSelector([getUnauthPastTrips], (pastTrips) =>
  utils.safeToJS(pastTrips?.getIn(['trip_summaries']), [])
);

/**
 * Returns true if user has initiated a reservation with either a product code or a coupon.
 */
export const hasCouponOrProductCodeSelector = createSelector(
  [getInitiateRequest],
  ({ product_code: productCode, coupons = [] }) => !!productCode || coupons.length > 0
);

/**
 * Returns true if reservation is cancelled.
 */
export const isReservationCancelledSelector = createSelector(
  [getReservationRentalState],
  (rentalState) => rentalState === RESERVATIONS.RENTAL_STATE_CANCELLED
);

/**
 * Returns true if is current reservation.
 */
export const isCurrentReservationSelector = createSelector(
  [getReservationRentalState],
  (rentalState) => rentalState === RESERVATIONS.RENTAL_STATE_OVER_DUE
);

export const gboReservationFirstAndLastNameSelector = createSelector(
  [getReservationRenterInfoFirstName, getReservationRenterInfoLastName],
  (firstName, lastName) => ({
    resFirstName: firstName,
    resLastName: lastName,
  })
);

export const gmaInitiateLocationsSelector = createSelector(
  [getInitiatePickupLocation, getInitiateReturnLocation],
  (pickupLocation, returnLocation) => ({
    pickupLocation: utils.safeToJS(pickupLocation, {}),
    returnLocation: utils.safeToJS(returnLocation, {}),
  })
);

export const voucherExcludedSelector = createSelector([priceSummarySelector], (priceSummary) => ({
  view: priceSummary.voucher_excluded_charge_view,
  payment: priceSummary.voucher_excluded_charge_payment,
}));

export const isTourVoucherReservationSelector = createSelector(
  [getTourVoucherReservation],
  (isTourVoucherReservation) => !!isTourVoucherReservation
);

export const isSecretRateSelector = createSelector([getSecretRate], (isSecretRate) => !!isSecretRate);

export const couponContractDetailsSelector = createSelector(
  [getContractDetails, getReservationCoupons, sessionReservationObjectSelector],
  (contractDetails, coupons, reservationObj) => {
    let discountElements = [];
    // Coupon Code  details
    const couponData = utils.safeToJS(coupons, []);
    // Contract ID details
    const isContractDetails = reservationObj?.get('contract_details');
    const showContractDetails = isContractDetails?.get('default_loyalty_contract') === false;
    const contract_details = utils.safeToJS(isContractDetails, {});
    const shouldShowProgressBarCID = showContractDetails
      ? contract_details?.contract_type && contract_details?.contract_name
      : null;
    let data = {};
    if (shouldShowProgressBarCID) {
      data = {
        description: contractDetails.get('contract_name'),
        contractType: contractDetails.get('contract_type'),
        defaultLoyaltyContract: contractDetails.get('default_loyalty_contract'),
        contractAcceptsBilling: contractDetails.get('contract_accepts_billing'),
        contractBillingAccount: contractDetails.get('contract_billing_account'),
        contractRequirements: utils.safeToJS(
          contractDetails
            .get('additional_information')
            ?.filter((requirement) => !requirement.get('validate_additional_info')),
          []
        ),
      };
      discountElements.push(data);
    }
    if (globalUtils.isArrayNotEmpty(couponData)) {
      discountElements = [data, ...couponData];
    }
    return discountElements;
  }
);

const getShowAdditionalDriverIsSpouse = (state) => state.getIn(appShowAdditionalDriverIsSpousePath);

export const getShowAdditionalDriverIsSpouseSelector = createSelector(
  [getShowAdditionalDriverIsSpouse],
  (result) => result
);

/* selector to get additionalInfoQuestion */
export const getPreSelectedAdditionalInfoQuestions = createSelector(
  [contractDetailsSelector, getPreSelectedAdditionalInfo],
  (contractDetails, additionalInformation) => {
    const { contractRequirements } = contractDetails || {};
    const additionalInfoDetails = utils.safeToJS(additionalInformation, {});
    const additionInfo = {};
    if (contractRequirements && additionalInfoDetails) {
      contractRequirements.forEach((field) => {
        const { name, id } = field;
        const value = additionalInfoDetails.find((item) => item.id === id)?.value;
        const nameKey = utils.string.stringUnderscorer(name);
        additionInfo[nameKey] = value;
      });
    }
    return additionInfo;
  }
);
/* selector to show email specials checkbox */
export const showEmailSpecialsSelector = createSelector(
  [getProfileData, getModifyFlag, marketingMessageIndicatorSelector],
  (profileData, isTrueModify, marketingMessageIndication) => {
    const isEmailUpdateRequired = profileData?.email_update_required;
    const emailPreference = profileData?.email_preference;

    return (
      !isTrueModify &&
      !isEmailUpdateRequired &&
      !emailPreference?.special_offers &&
      marketingMessageIndication !== false
    );
  }
);

/* returns boolean value if it is US domain and email update requied flag is false */
export const setRequestEmailSpecialSelector = createSelector([getProfileData], (profileData) => {
  const isEmailUpdateRequired = profileData?.email_update_required;
  const isUSDomain = utils.config.isUnitedStatesDomain();

  return isUSDomain && !isEmailUpdateRequired;
});

export const hasNoCancellationDetailsSelector = createSelector(
  [isReservationCancelledSelector, guaranteedResCardRequiredSelector, getDidPrepay, appliedCancelFeeDetailsSelector],
  (isReservationCancelled, guranteedReservation, isPrepaySelected, appliedCancellationFeeDetails) =>
    isReservationCancelled &&
    (isPrepaySelected || guranteedReservation) &&
    globalUtils.isObjectEmpty(appliedCancellationFeeDetails)
);
