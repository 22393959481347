import { getDeadTimeForSessionTimeout } from "utils/date";
import utils from "utils";
import { closeModal } from "actions/modal/closeModal";
import { MODAL, RESERVATIONS } from "constants";
import { openModalWithProps } from "actions/modal/openModalWithProps";

export const showSessionTimeoutModal = () => (dispatch) => {
    const getSessionTimeoutDeadlineValue = getDeadTimeForSessionTimeout();
    const TAB_ID_SESSION = utils.localStorage.getTabIdSession();
    // set deadline endtime in localstorage
    sessionStorage.removeItem(`sessionDeadlineValue_${TAB_ID_SESSION}`);
    sessionStorage.setItem(`sessionDeadlineValue_${TAB_ID_SESSION}`, getSessionTimeoutDeadlineValue);
    sessionStorage.setItem(`pauseTimer_${TAB_ID_SESSION}`, false);

    // Since Canada French Redirection Modal stays open on user
    // redirect to the Canada French site, we need to close it before
    // opening the session timeout modal. Nothing will happen if this modal is not open.
    // and if other modals are open, flow stays the same.
    dispatch(closeModal(MODAL.CANADA_FRENCH_REDIRECTION_MODAL));

    dispatch(
        openModalWithProps(MODAL.SESSION_TIMEOUT_MODAL, {
            countDown: RESERVATIONS.GMA_SESSION_TIMEOUT_INFORMATION,
            runTimer: true,
            currentTime: getSessionTimeoutDeadlineValue,
        })
    );
};