export const PAYMENT = {
  SELECTED: 'PAYMENT_SELECTED',
};

/**
 * Set Billing type selected in trueModify flow
 * @param data
 */

export const setSelectedBillingDetails = (data = {}) => ({
  type: PAYMENT.SELECTED,
  data,
});
