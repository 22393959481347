import { utils } from "@ehi/global-marketing-interface";

export const getActiveFilters = (filters = []) =>
    filters
        .filter(filter => filter.values !== null && filter.values.length > 0)
        .map(filter => {
            const { keyPath, values, available } = filter;
            const valuesType = utils.types(values);

            // todo: think about returning radioValue so the below doesn't need to happen
            let { radioValue } = filter;
            if (!radioValue && valuesType.isFunction) {
                const selected = available.filter(option => option.checked)[0];
                radioValue = selected ? selected.value : null;
            }

            return {
                keyPath,
                values: valuesType.isArray ? values.slice() : values,
                radioValue,
            };
        });