import GMA_RESPONSE_CODES from 'constants/gmaResponseCodes';
import GLOBAL from 'constants/global';
import sanitizeHtml from 'sanitize-html-react';
import { utils } from '@ehi/global-marketing-interface';
import { selectedCountryDetails, getRawPhoneNumber, isValidCountryPhoneNumber, isDialingCode } from 'utils/phoneInput';
import { i18n } from './i18n';
import { flattenArrayAndObject } from './array';

/**
 * Validation helpers
 * @module
 */

/**
 * Utility function that ensures argument function type before execution
 * @param {Function} func - function to be executed
 * @param {Array} args - optional arguments passed down to executable function
 */
export const safeExecute = (func, ...args) => {
  if (utils.types(func).isFunction) {
    return func(...args);
  }
  return null;
};

/**
 * Sanitizes string input before dangerously writing it into dom as HTML
 * @param {String} dirty - string potentially containing HTML markup
 * @returns {Object} - object to be passed into react's dangerouslySetInnerHTML attribute
 */
export const sanitize = (dirty) => ({
  __html: dirty
    ? sanitizeHtml(dirty, {
        allowedTags: ['strong', 'a', 'p', 'i', 'b', 'em', 'br', 'sup', 'div', 'li', 'ul', 'h3', 'h4', 'img'],
        allowedAttributes: {
          a: ['href', 'name', 'target'],
          img: ['src', 'alt'],
          '*': ['class', 'id'],
          td: ['colspan'],
        },
        allowedSchemes: ['http', 'https', 'tel'],
        allowedSchemesByTag: {
          img: ['data'],
        },
      })
    : '',
});

/**
 * Composes a chain of validators and runs the value against them
 * @param  {...function} validators
 * @returns {(null|String)} - null if validation passes otherwise string error message
 */
export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce((error, validator) => error || validator(value), null);

/**
 * Final Form required validation
 * @param {any} value
 * @returns {(null|String)} - null if validation passes otherwise string error message
 */
export const required = (value) => (!value || value === '' ? i18n('field_required') : null);

/**
 * Final Form is value the boolean true validation for check boxes
 * @param {any} value
 * @returns {(null|String)} - null if validation passes otherwise string error message
 */
export const isTrueBool = (value) => (value && typeof value === 'boolean' ? null : i18n('field_must_be_checked'));

/**
 * Final Form is value a number validation
 * @param {any} value
 * @returns {(null|String)} - null if validation passes otherwise string error message
 */
// eslint-disable-next-line no-restricted-globals
export const mustBeNumber = (value) => (!value || !isNaN(value) ? null : i18n('field_must_be_number'));

/**
 * Final Form is value a number and greater than boundary validation
 * @param {any} value
 * @returns {(null|String)} - null if validation passes otherwise string error message
 */
export const greaterThan = (boundary) => (value) => {
  if (!value) {
    return null;
  }
  const mustBeNumberResult = mustBeNumber(value);
  if (mustBeNumberResult) {
    return mustBeNumberResult;
  }
  return value > boundary ? null : `${i18n('field_greater_than')} ${boundary}`;
};

/**
 * Checks if the location store search is invalid all day
 * @param {string} GMA message
 * @returns {(boolean)} - returns true if is invalid all day
 */

export const hasInvalidDay = (dayMessage) => dayMessage === GMA_RESPONSE_CODES.INVALID_ALL_DAY;

/**
 * Checks if the location store search has any error at all
 * @param {string} GMA message
 * @returns {(boolean)} - returns true if we any error
 */

export const hasError = (message) =>
  message === GMA_RESPONSE_CODES.VALID_AFTER_HOURS || message === GMA_RESPONSE_CODES.VALID_STANDARD_HOURS;

/**
 * Checks if the location store search has valid hours
 * @param {string} GMA message
 * @returns {(boolean)} - Checks if the location store search has valid hours. FYI: if the day is invalid we still consider it to be 'valid hours'
 *
 */

export const hasValidHours = (message) =>
  message === GMA_RESPONSE_CODES.VALID_AFTER_HOURS ||
  message === GMA_RESPONSE_CODES.VALID_STANDARD_HOURS ||
  message === GMA_RESPONSE_CODES.INVALID_ALL_DAY;

/**
 * Checks if String is an email address
 *
 * This regex pattern ensures that the string is a valid email address and without the follow characters
 * < > ( ) [ ] " , \ /
 * And that domain extension only contains alphabetic characters
 * @param {String} value
 * @returns {Boolean}
 */
// Search a valid email (name@example.com)

export const email = (value) => (new RegExp(GLOBAL.REGEX_EMAIL).test(value) ? null : i18n('field_must_be_valid_email'));

export function isMasked(value = '') {
  return !!value && typeof value === 'string' && value.indexOf(GLOBAL.BULLET) > -1;
}

export const phone = (value) => (/^[+\d\s\-().•]+$/.test(value) ? null : i18n('review_page_phone_validation'));

export const checkPhoneCode = (value) => {
  const parsedCountryObject = selectedCountryDetails.get();

  const rawPhoneNumber = isMasked(value) ? value : getRawPhoneNumber(parsedCountryObject, value);
  const hasValidCountryPhoneNumber = isValidCountryPhoneNumber(parsedCountryObject, rawPhoneNumber);
  const phoneNumbeFieldRequiredError = rawPhoneNumber === '' || isDialingCode(value);

  if (!isMasked(rawPhoneNumber) && (phoneNumbeFieldRequiredError || !hasValidCountryPhoneNumber)) {
    if (phoneNumbeFieldRequiredError) {
      return i18n('field_required_dont_forget_to_enter', [i18n('common_phone').toLowerCase()]);
    }

    return i18n('field_must_be_valid_phone_number');
  }
  return null;
};

export const getIfNotMasked = (value = '') => (!isMasked(value) ? value : '');

/**
 * Validates whether the errors object contains field errors of the required kind
 * @param {object} errorsObj - object that maps errors found in the form to their respective fields
 */
export const noRequiredErrors = (errorsObj) => flattenArrayAndObject(errorsObj).includes(i18n('field_required'));

/**
 * Wraps a callback and creates a curried function that, when called with a response object (GMA/GBO formatted),
 *  runs the callback only if the response does not contain an error or warning message within it.
 *
 * @param {function} callback - function called if the response does not contain errors
 * @param {object} responseObj - response object in GMA/GBO format
 */
export const runOnSuccessResponse = (callback) => (responseObj) => {
  if (!utils.isArrayNotEmpty(responseObj?.messages)) {
    callback?.();
  }
};

/**
 * This function checks if phone number validation
 * is met (phone number is valid) in order to show an error on the field or not.
 * This is different from showing a 'required' error on the password field.
 */
export const altPhoneNumberValidation = (phoneNumber) => {
  const parsedCountryObject = selectedCountryDetails.get();
  const rawPhoneNumber = isMasked(phoneNumber) ? phoneNumber : getRawPhoneNumber(parsedCountryObject, phoneNumber);
  if (
    phoneNumber.length > 0 &&
    !isDialingCode(phoneNumber) &&
    !isMasked(rawPhoneNumber) &&
    rawPhoneNumber.length > 0 &&
    rawPhoneNumber !== '' &&
    checkPhoneCode(rawPhoneNumber)
  ) {
    return i18n('field_required_dont_forget_to_enter', [i18n('common_phone').toLowerCase()]);
  }

  return null;
};
