/**
 * Additional driver flow routes config - should match GMA's go_to responses
 */
import utils from 'utils';

const ADDITIONAL_DRIVER_PATH_CONFIG = {
  edit: ['/edit'],
  confirm: ['/confirm'],
};

export default {
  ADDITIONAL_DRIVER_PATH_CONFIG,
  YOUNG_RENTER_FEE_CODE: utils.i18n('YOUNG_RENTER_FEE'), // Since 'description' coming in LOCAL language, using keyword To match GMA's responses
};
