import { getModelJsonPath } from 'utils/url';

/**
 * Object helpers
 * @module
 */

/**
 * @name getModelJson
 * @desc Makes a network request to an AEM url that contains model.json.
 *
 * @param {Function} setData - useState hook to save the data into state
 * @returns {Object}
 */
export const getModelJson = setData => {
  fetch(getModelJsonPath())
    .then(res => res.json())
    .then(result => setData(result))
    .catch(err =>
      setData({
        error: `Cannot serve request to ${getModelJsonPath()}`,
        catchErr: err,
      })
    );
};
