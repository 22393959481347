import { createSelector } from 'reselect';
import utils from 'utils';
import { RESERVATIONS } from 'constants';

const getLocationAgeOptions = state =>
  state.getIn(['gmi', 'ui', 'reservations', 'initiate', 'pickup_location', 'additional_data', 'age_options']);
const getLocationCountryAgeOptions = state => {
  const countryCode = state.getIn([
    'gmi',
    'ui',
    'reservations',
    'initiate',
    'pickup_location',
    'address',
    'country_code',
  ]);
  return state.getIn(['gmi', 'solr', 'renter_age', countryCode]);
};
const getDefaultCountryAgeOptions = state =>
  state.getIn(['gmi', 'solr', 'renter_age', utils.config.getGMIConfig().COUNTRY]);
// TODO: reintroduce defaultValue once we have modify
// const getDefaultValue = state => state.getIn(['reservation', 'preflight', 'renter_age'], null);

export const driversAgeOptionsSelector = createSelector(
  [getLocationAgeOptions, getLocationCountryAgeOptions, getDefaultCountryAgeOptions],
  (locationAgeOptions, locationCountryAgeOptions, defaultCountryAgeOptions) => {
    const options = utils.safeToJS(locationAgeOptions || locationCountryAgeOptions || defaultCountryAgeOptions, []);

    // replace tokens from labels with strings
    return options.map(option => ({
      ...option,
      label: option.label
        .replace(RESERVATIONS.AGE_SELECTOR_LABEL_VARIABLE_TO, ' - ')
        .replace(RESERVATIONS.AGE_SELECTOR_LABEL_VARIABLE_OR_OLDER, ' +'),
    }));
  }
);

export const driversAgeInitialValueSelector = createSelector(
  [driversAgeOptionsSelector],
  driversAgeOptions => driversAgeOptions.find(option => option.selected)?.value || ''
);
