import { services } from '@ehi/global-marketing-interface';

export default {
  RESERVATIONS_INITIATE: services.gma.reservations.initiate().endpoint,
  PROFILE_LOGIN: services.gma.profile.login().endpoint,
  CREATE_PROFILE: services.gma.profile.createProfile().endpoint,
  MODIFY_PROFILE: services.gma.profile.modify().endpoint,
  RESET_PASSWORD_START: services.gma.profile.resetPasswordStart().endpoint,
  RESET_PASSWORD: services.gma.profile.resetPassword().endpoint,
  CAR_CLASS_DETAILS: services.gma.reservations.carClassDetails().endpoint,
  FORGOT_USERNAME: services.gma.profile.forgotUsername().endpoint,
};
