import { createSelector } from 'reselect';
import utils from 'utils';
import GLOBAL from 'constants/global';
import RESERVATIONS from 'constants/reservations';
import { carClassVehicleRatesPath } from 'paths/carClassDetails';
import { gmaPreSelectedReservationPath } from 'paths/session';
import {
  uiStateExtrasPath,
  bundlesPath,
  highlyRecommendedProtectionsPath,
  requiredProtectionsPath,
} from 'paths/extras';

import { isDestinationCurrencySelected } from 'selectors/vehicleSelectSelectors';
import { updateVehicleExtrasSelector } from 'selectors/currencyToggleSelectors';
import { vehicleRateSelector, additionalDriversPolicySelector } from './reservationFlowSelectors';

const {
  PREPAY_CODE,
  PAYLATER_CODE,
  EXTRAS_INCLUDED_STATUS,
  EXTRAS_OPTIONAL_STATUS,
  EXTRAS_MANDATORY_STATUS,
  EXTRAS_WAIVED_STATUS,
  ADDITIONAL_DRIVER_EXTRAS_CODE,
  ADDITIONAL_DRIVER_INCLUDED_STATUS,
} = RESERVATIONS;
const getBundles = (state) => state.getIn(bundlesPath);

// assign numerical values to known statuses for sorting
// lesser values will appear earlier in the list
const STATUS_SORT_VALUES = {
  [EXTRAS_INCLUDED_STATUS]: 0,
  [ADDITIONAL_DRIVER_INCLUDED_STATUS]: 1,
  [EXTRAS_MANDATORY_STATUS]: 2,
  [EXTRAS_WAIVED_STATUS]: 3,
  [EXTRAS_OPTIONAL_STATUS]: 4,
};

export const sortByStatus = (a, b) => STATUS_SORT_VALUES[a.status] - STATUS_SORT_VALUES[b.status];

const getUiStateExtras = (state) => state.getIn(uiStateExtrasPath);

export const getListOfRecommended = (state) =>
  state.getIn(highlyRecommendedProtectionsPath) ||
  // @todo: remove `protections_required_at_counter`. This is a fallback to the legacy (national) pattern
  // resulting in a deminished list on extra addition. Alamo _wants_ a persistant list, hence
  // `highly_recommended_protections`, but this is not yet in the GBO responses @see GBO-29581
  state.getIn(requiredProtectionsPath);

export const getVehicleRatesWithSelectedVehicle = (state) => state.getIn(carClassVehicleRatesPath);

const getPreSelectedReservation = (state) => state.getIn(gmaPreSelectedReservationPath);

export const bundlesSelector = createSelector([getBundles], (bundles) => utils.safeToJS(bundles, []));

export const selectedBundleNotDefaultSelector = createSelector([getBundles], (bundles) => {
  const selected = bundles?.find((item) => item.get('selected') && !item.get('default_bundle'));
  return utils.safeToJS(selected, {});
});

// Gets the number of additional drivers added from a bundle selection
export const bundledAdditionalDriversSelector = createSelector(
  [selectedBundleNotDefaultSelector],
  (selectedBundle) =>
    selectedBundle?.vehicle_rates?.supplemental?.additional_driver?.number_of_free_additional_drivers || null
);

// pulls the extras object from the correct vehicle rate type [paynow, paylater]
export const extrasSelector = createSelector(
  [vehicleRateSelector, isDestinationCurrencySelected],
  (vehicleRate, destinationCurrency) => {
    if (destinationCurrency) {
      let extras = utils.safeToJS(vehicleRate?.get('extras'), {});
      extras = updateVehicleExtrasSelector(extras);
      return extras;
    }
    return utils.safeToJS(vehicleRate?.get('extras'), {});
  }
);

// determines if we need to show a "no extras" message or the list of extras
export const hasExtrasSelector = createSelector([extrasSelector], (extras) => {
  const { equipment = [], insurance = [], fuel = [] } = extras;

  return !![...insurance, ...equipment, ...fuel].length;
});

// Returns an object with { [bundle name]: [list of extras] } only for bundles that have included extras which are NOT also included as part of default bundle
export const includedNotDefaultBundleExtrasSelector = createSelector([getBundles], (bundles) => {
  const includedBundleExtras = {};

  utils.safeToJS(bundles, []).forEach(({ name, vehicle_rates }) => {
    const extras = vehicle_rates?.extras || {};
    const includedExtras = [];
    const keys = Object.keys(extras);

    if (keys.length) {
      keys.forEach((key) => {
        const included = extras[key].filter(
          (extra) =>
            (extra.status === EXTRAS_INCLUDED_STATUS || extra.status === EXTRAS_MANDATORY_STATUS) &&
            !extra.default_bundle_inclusion
        );

        includedExtras.push(...included);
      });
      includedBundleExtras[name] = includedExtras;
    }
  });

  return includedBundleExtras;
});

// Derives flag for whether to show bundles based on whether the bundles have any included extras that aren't also included in the default bundle
export const showBundlesSectionSelector = createSelector(
  [includedNotDefaultBundleExtrasSelector],
  (includedBundlesExtras) =>
    Object.keys(includedBundlesExtras).some((bundleExtras) => includedBundlesExtras[bundleExtras].length)
);

export const selectedBundleWithExtrasSelector = createSelector(
  [includedNotDefaultBundleExtrasSelector, selectedBundleNotDefaultSelector],
  (includedBundleExtras, selectedBundle) =>
    selectedBundle?.name
      ? {
          ...selectedBundle,
          bundleExtras: includedBundleExtras[selectedBundle.name],
        }
      : null
);

/*
 * Derives includedAdditionalDrivers object with policy as detailed_description and other necessary properties for
 * Resflow consumption (ProgressBar, Confirmation, Extras FlyoutTabs)
 */
const includedAdditionalDriversSelector = createSelector(
  [vehicleRateSelector, bundledAdditionalDriversSelector, additionalDriversPolicySelector],
  (vehicleRate, bundledAdditionalDrivers, additionalDriversPolicy) => {
    if (!vehicleRate || bundledAdditionalDrivers) {
      // if bundleAdditionalDrivers exists, additional drivers are from bundle and override any included drivers
      // so included driver object is set to null. vehicleRate check here just returns null if the reservation data isn't loaded yet
      return null;
    }
    const additionalDrivers = vehicleRate?.getIn(['supplemental', 'additional_driver']);
    const status = additionalDrivers?.get('status');
    const numberOfDrivers = additionalDrivers?.get('number_of_free_additional_drivers');
    if (status === RESERVATIONS.ADDITIONAL_DRIVER_INCLUDED_STATUS && numberOfDrivers > 0) {
      return {
        name: utils.i18n('included_additional_drivers_extra_name'),
        status,
        selected_quantity: numberOfDrivers,
        showCount: true, // currently controls whether to show count (selected_quantity) on Extras FlyoutTab
        code: additionalDrivers?.get('code'),
        detailed_description: additionalDriversPolicy?.policy_text,
      };
    }
    return null;
  }
);

// grabs extras that are selected in any way, to be show on the review page
export const selectedExtras = createSelector(
  [extrasSelector, includedAdditionalDriversSelector],
  (extras, includedAdditionalDrivers) => {
    const { equipment = [], insurance = [], fuel = [] } = extras;

    return [...insurance, ...equipment, ...fuel, includedAdditionalDrivers]
      .filter((extra) => !!extra?.selected_quantity)
      .sort(sortByStatus);
  }
);

// Gets only insurance/protection extras selected in any way
export const selectedInsuranceExtrasSelector = createSelector([extrasSelector], (extras) => {
  const { insurance = [] } = extras;

  return [...insurance].filter((extra) => !!extra?.selected_quantity).sort(sortByStatus);
});

//  Checks to see if the user has added a limited inventory extra
export const hasLimitedInventoryExtraSelector = createSelector(
  [selectedExtras],
  (selectedExtrasArray) =>
    selectedExtrasArray?.filter(({ allocation }) => allocation === GLOBAL.LIMITED_INVENTORY_EXTRA_CODE)?.length > 0
);

export const extrasWithoutBundlesSelector = createSelector(
  [selectedExtras, selectedBundleWithExtrasSelector],
  (extras, bundleWithExtras) => {
    const bundlesExtras = bundleWithExtras?.bundleExtras || [];
    return (
      extras?.filter((extra) => {
        const inBundle = bundlesExtras.some((ext) => ext.code === extra.code);
        return !inBundle || (inBundle && extra.selected_quantity > 0 && extra.status === EXTRAS_OPTIONAL_STATUS);
      }) || []
    );
  }
);

/*
  TODO:
  Once GBO fixes the issue where the extras array is matching the bundles' extras array,
  we need to ONLY filter out an item thats in the bundles array IF the quantity is not matched.
  We should be able to read this based on the extra.status not matching amongst the two,

  Example: User selects GOLD GPS bundle, which includes a GPS and FSO, but also manually adds
  a GPS device. In this case, the addedExtras array should still include the GPS extra object,
  because ONE of the two, was manually added.

  This will also affect numberOfExtrasSelector below because the quantities won't always match up.

  Example 2: User selects GOLD GPS bundle, which includes a GPS and FSO, but also manually adds
  2 more GPS devices. In this case, the total number of extras selected extras should be 3 (not 4)
  which is +1 for added bundle (overall) and +2 for GPS devices added
*/
// grabs extras with a status of INCLUDED || MANDATORY (but not in a bundle) and ADDED and returns two separate arrays
export const includedOrAddedExtrasSelector = createSelector([extrasWithoutBundlesSelector], (extras) => {
  const includedExtras = [];
  const addedExtras = [];

  extras.forEach((extra) => {
    if (extra.status === EXTRAS_INCLUDED_STATUS) {
      includedExtras.push(extra);
    } else if (
      extra.status === EXTRAS_OPTIONAL_STATUS ||
      extra.status === EXTRAS_MANDATORY_STATUS ||
      extra.status === EXTRAS_WAIVED_STATUS
    ) {
      addedExtras.push(extra);
    }
  });

  return { includedExtras, addedExtras };
});

// Auxiliary function to be used when reducing an extras array to get total of selected extras. Counts
// additional driver extras
const getSumOfSelectedQuantities = (acc, curr) =>
  curr.code !== ADDITIONAL_DRIVER_EXTRAS_CODE ? acc + utils.number.isSafeNumberVal(curr.selected_quantity) : acc + 1;

// Specific selector to get added extras + bundle total amounts; see `numberOfExtrasSelector` for more info
export const numberOfAddedExtrasSelector = createSelector(
  [includedOrAddedExtrasSelector, selectedBundleNotDefaultSelector],
  ({ addedExtras }, bundleSelected) => {
    const addedExtrasAmount = addedExtras?.reduce(getSumOfSelectedQuantities, 0);
    const bundleAmount = bundleSelected?.name ? 1 : 0;
    return addedExtrasAmount + bundleAmount;
  }
);

// adds includedAdditionalDrivers object to the includedExtras array
export const includedExtrasAndDriversSelector = createSelector(
  [includedOrAddedExtrasSelector, includedAdditionalDriversSelector],
  ({ includedExtras }, includedAdditionalDrivers) => [
    ...includedExtras,
    ...(includedAdditionalDrivers ? [includedAdditionalDrivers] : []),
  ]
);

/*
  We should count multiples of something per item, but additional drivers and the bundle should be one item,
  thus calculation of extras is broken down like so:
    - Single Extra = 1
    - Extra w multiple (x3) = 3
    - Additional Driver (x3) = 1
    - Bundle = 1
    - Extras in a bundle = 0
*/
export const numberOfExtrasSelector = createSelector(
  [includedExtrasAndDriversSelector, numberOfAddedExtrasSelector],
  (includedExtrasAndDrivers, addedExtrasAmount) => {
    const includedExtrasAmount = includedExtrasAndDrivers?.reduce(getSumOfSelectedQuantities, 0);
    return includedExtrasAmount + addedExtrasAmount;
  }
);

/**
 * hashTableOfRecommendedExtrasSelector - returns a hash table with the codes for recommended extras for keys
 *
 * @param {array}    getListOfRecommended - Array of recommended extras
 * @return {object}  hash table with codes for keys
 */
export const hashTableOfRecommendedExtrasSelector = createSelector(
  [getListOfRecommended],
  (listOfRecommendedInsurance) => {
    const hashTableOfRecommendedInsurance = {};
    listOfRecommendedInsurance?.forEach((singleInsurance) => {
      hashTableOfRecommendedInsurance[singleInsurance] = true;
    });
    return hashTableOfRecommendedInsurance;
  }
);

/**
 * recommendedExtrasSelector returns an array of the recommended extras
 *
 * @param {object}    vehicleRate - list of insurances
 * @param {object}    hashTableOfRecommendedExtras - Hash table of recommended extras, with code as keys
 * @param {boolean}   isPrePaySelected - if the reservation is pre pay or not
 * @return {array}    An array of recommended extras
 */
export const recommendedExtrasSelector = createSelector(
  [vehicleRateSelector, hashTableOfRecommendedExtrasSelector],
  (vehicleRate, hashTableOfRecommendedExtras) => {
    const vehicleRatesJS = utils.safeToJS(vehicleRate);
    if (!vehicleRatesJS) {
      return [];
    }

    const arrayOfExtras = vehicleRatesJS?.extras?.insurance;
    if (!utils.gmi.isArrayNotEmpty(arrayOfExtras)) {
      return [];
    }
    const recommendedExtras = [];
    //  Uses the hashtable to see if the extras are recommended
    arrayOfExtras.forEach((extra) => {
      if (hashTableOfRecommendedExtras[extra?.code]) {
        recommendedExtras.push(extra);
      }
    });
    return recommendedExtras;
  }
);

/**
 * recommendedExtrasNotIncludedSelector returns an array of the recommended extras that are not included
 *
 * @param {object}    recommendedExtras - list of recommended Extras
 * @return {array}    An array of recommended extras filtered by non-added
 */
export const recommendedExtrasNotIncludedSelector = createSelector([recommendedExtrasSelector], (recommendedExtras) => {
  if (!utils.gmi.isArrayNotEmpty(recommendedExtras)) {
    return [];
  }
  const recommendedExtrasNotIncluded = recommendedExtras.filter((extra) => extra.status !== EXTRAS_INCLUDED_STATUS);
  return recommendedExtrasNotIncluded;
});

/**
 * recommendedExtrasNotAddedSelector returns an array of the recommended extras that are not included
 * and was not added to reservation by user
 *
 * @param {object}    recommendedExtras - list of recommended Extras
 * @return {array}    An array of recommended extras filtered by non-added
 */
export const recommendedExtrasNotAddedSelector = createSelector(
  [recommendedExtrasNotIncludedSelector],
  (recommendedExtras) => {
    if (!utils.gmi.isArrayNotEmpty(recommendedExtras)) {
      return [];
    }
    const nonAddedRecommendedExtras = recommendedExtras.filter((extra) => !extra.selected_quantity);
    return nonAddedRecommendedExtras;
  }
);

/**
 * hasAddedExtrasSelector
 * Check selected extras/bundle against included ones to see if the user added any themselves.
 *
 * @return {boolean} indicates whether the user has added any extra or bundle besides included ones
 */
export const hasAddedExtrasSelector = createSelector(
  [getUiStateExtras, includedOrAddedExtrasSelector, selectedBundleNotDefaultSelector],
  (uiStateExtras, includedOrAddedExtras, selectedBundle) => {
    const extras = utils.safeToJS(uiStateExtras, []);
    const numberOfExtrasSelected = extras.length;

    const { includedExtras } = includedOrAddedExtras;
    const numberOfIncludedExtras = includedExtras.length;

    // extras already included in the reservation do not count towards "added"
    const hasSelectedExtrasNotIncluded =
      numberOfExtrasSelected > 0 && numberOfExtrasSelected !== numberOfIncludedExtras;

    // should return true if either the user has selected an extra or a bundle
    return hasSelectedExtrasNotIncluded || !!selectedBundle.name;
  }
);

export const preSelectedExtrasSelector = createSelector([getPreSelectedReservation], (preSelectedReservation) => {
  const isPrepay = preSelectedReservation?.get('prepay_selected');
  const vehicleRates = preSelectedReservation?.getIn(['car_class_details', 'vehicle_rates']);
  const extras =
    utils.safeToJS(vehicleRates)?.find((rate) => rate.charge_type === (isPrepay ? PREPAY_CODE : PAYLATER_CODE))
      ?.extras || {};
  const { equipment = [], insurance = [], fuel = [] } = extras;
  return [...insurance, ...equipment, ...fuel].map(({ code, selected_quantity }) => ({
    code,
    quantity: selected_quantity,
  }));
});

/**
 * If an item have more than one status (OPTIONAL, INCLUDED, etc..) GBO request will
 * return this items in two lines. To avoid confusion in the UI we add the optional
 * item selected quantity to the one previously added, normally a INCLUDED with bundles.
 *
 * @param {Array} extras Sorted extras
 * @return {Array} Extras without duplicated items
 */
const clearDuplicatedItems = (allExtras) => {
  const parsedItems = [];
  allExtras.forEach((item) => {
    const firstItem = parsedItems.find((i) => i.code === item.code);
    if (firstItem) {
      firstItem.selected_quantity += item.selected_quantity;
    } else {
      parsedItems.push(item);
    }
  });
  return parsedItems;
};

export const individualExtrasSelector = createSelector([extrasSelector], (extras) => {
  const { equipment = [], insurance = [], fuel = [] } = extras;
  const sortedProtectionProducts = [...insurance].sort(sortByStatus);
  const sortedTripEnhancements = [...equipment, ...fuel].sort(sortByStatus);

  return {
    protectionProducts: clearDuplicatedItems(sortedProtectionProducts),
    tripEnhancements: clearDuplicatedItems(sortedTripEnhancements),
  };
});
