import { gboReservationPath } from './session';

// TODO: review paths used here and in reservationVehicle (duplicated)
export const carClassDetailsPath = [...gboReservationPath, 'car_class_details'];
export const carClassVehicleRatesPath = [...carClassDetailsPath, 'vehicle_rates'];
export const carClassPriceDifferencePath = [...carClassDetailsPath, 'price_differences'];
export const carClassMileageInfoPath = [...carClassDetailsPath, 'mileage_info'];

// vehicleRates paths - these paths start from either PREPAY or PAYLATER vehicleRate objects. Selectors need to
//  first retrieve the correct object with a .find() based on relevant payment type then they can use these paths
//  to dig deeper into those objects
export const vehicleRatesFeesPath = ['price_summary', 'payment_line_items', 'FEE'];
