/**
 * State Cursors
 */
export default {
  DEFAULT_AGE_RANGE: [],
  DEFAULT_COUNTRY: 'US',
  HAPPY_DEAL_CODE: 'XXAR',
  TEXT: 'TEXT',
  DROPDOWN: 'DROPDOWN',
  DATE: 'DATE',
  LIMITED_INVENTORY_CAR_CODE: 'ON_REQUEST',
  LIMITED_INVENTORY_EXTRA_CODE: 'ON_REQUEST',
  HAS_LIMITED_INVENTORY_ON_CONFIRMATION_PAGE: 'PR',
  UPCOMING: 'UPCOMING',
  PENDING_CONFIRMATION: 'PENDING_CONFIRMATION',
  BULLET: '•',
  REGEX_EMAIL: /([+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})/,
};
