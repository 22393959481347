import { Iterable } from 'immutable';

/**
 * Takes an immutable structure and converts it to a JS object if it is safe
 * @param {Map} immutableStructure immutable map or list to be converted to a JS object
 * @param fallback if immutable is undefined or null, the fallback value will be returned which defaults to an empty object
 * @returns {Object}
 */

export const safeToJS = (immutableStructure, fallback) =>
  (Iterable.isIterable(immutableStructure) && immutableStructure.toJS()) || fallback;
