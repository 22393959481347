/**
 * ParentNode.append Polyfill
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/ParentNode/append
 */

(arr => {
  arr.forEach(item => {
    if (item.append) {
      return;
    }
    Object.defineProperty(item, 'append', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function append(...args) {
        const argArr = Array.prototype.slice.call(args);
        const docFrag = document.createDocumentFragment();

        argArr.forEach(argItem => {
          const isNode = argItem instanceof Node;
          docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
        });

        this.appendChild(docFrag);
      },
    });
  });
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);
