/**
 * Profile & Enrollment related constants
 */
export default {
  // email preference source codes - should vary by page where user is subscribing
  PREFERENCE_SOURCE_CODE: {
    RES_PATH: 'ARNTCONF1',
    AI_PROFILE: 'AIACOM', // enroll + account edit
    HOMEPAGE: 'ARNTHOME1',
  },
  ENROLLMENT_REQUEST_ORIGIN_CHANNEL: 'ENROLLMENT',
  PHONE_TYPE_HOME: 'HOME',
  PREFERENCE_REWARD_PARTNER: 'PARTNER',

  FIELD_STATUS_MANDATORY: 'MANDATORY',
  FIELD_STATUS_OPTIONAL: 'OPTIONAL',

  ISSUING_AUTHORITY_FIELD_COUNTRIES: ['DE', 'ES', 'FR', 'GB', 'IE'],
  // Mandatory Issuing Authorities for countries (in case of countries that specify one)
  ISSUING_AUTHORITIES_BY_COUNTRY: {
    ES: 'ESP',
    GB: 'DVLA',
  },

  PROFILE_PATHS_CONFIG: {
    myProfile: ['/my_profile'],
    myTrips: ['/my_trips'],
    receipt: ['/receipt/:invoice/:isFromMyTrips'],
  },

  DUPLICATE_EMAIL_ERROR_CODES: ['RNTR72003', 'DVRPR11082', 'GBO_PROFILE_INDIVIDUAL_DUPLICATE_EMAIL'],

  CROS_LOGIN_ACCOUNT_ERROR: {
    CODE: 'CROS_LOGIN_ACCOUNT_ERROR',
    PRIORITY: 'WARN',
  },
  LOYALTY_CODE: 'AI',
};
