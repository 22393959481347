/**
 * Check-In flow routes config - should match GMA's go_to responses
 */
const CHECKIN_PATH_CONFIG = {
  driverLookup: ['/driver_lookup'],
  profile: ['/profile'],
  checkInReview: ['/check_in_review'],
  extras: ['/extras'],
  skipTheCounterBridge: ['/confirmation_skip_the_counter_bridge'],
};

/**
 * Accepted values on the `onlineCheckInOrigin` param on check-in requests
 */
const CHECKIN_ORIGIN = {
  LOOK_UP: 'LOOK_UP',
  CONFIRMATION: 'CONFIRMATION',
  RESERVATION_DETAILS: 'RESERVATION_DETAILS',
  RESERVATION_TILE: 'RESERVATION_TILE',
};

export default {
  CHECKIN_PATH_CONFIG,
  CHECKIN_ORIGIN,
};
