/*
 *
 * Utilty function for locking and unlocking the ability to scroll
 */

//  If we change the consts, please update in `_generic.scss`
const NOSCROLL = 'noscroll';
// software-keyboard-noscroll class applies css to mobile and tablet views when simple scrollLock is disrupted by
// software keyboard displacement that may occur in certain browsers
const SOFTWARE_KEYBOARD_NOSCROLL = 'software-keyboard-noscroll';

export const scrollLock = ({ toggleValue = true, usesSoftwareKeyboard } = {}) => {
  if (toggleValue) {
    document.body.classList.add(NOSCROLL);
    usesSoftwareKeyboard && document.body.classList.add(SOFTWARE_KEYBOARD_NOSCROLL);
  } else {
    document.body.classList.remove(SOFTWARE_KEYBOARD_NOSCROLL);
    document.body.classList.remove(NOSCROLL);
  }
};
