import CHECKIN from 'constants/checkin';
import { i18n } from './i18n';

const { CHECKIN_PATH_CONFIG, CHECKIN_ORIGIN } = CHECKIN;

/**
 * Based on the current step hash, returns the step content.
 * @param {string} currentStepHash - Current page in the OCI flow SPA (i.e. '/driver_lookup', '/profile')
 * @param {string} authUserFirstName - The first name on the profile in session (as the authenticated user, non-OCI)
 * @param {object} flags - Some indicators about the current OCI profile in session
 * @param {boolean} flags.isProfileFound - whether a successful lookup occured, and a partial/full profile is in session
 * @param {boolean} flags.isOCIProfileComplete - whether the OCI profile is complete (i.e. fully ready for OCI)
 * @param {boolean} flags.isOCIProfileLoyalty - whether the OCI profile is a valid loyalty profile
 * @param {boolean} flags.isUserAuthenticated - whether there's an Alamo Insiders account in session (non-OCI)
 * @return {Object}
 */
export const getStepsContent = (
  currentStepHash,
  authUserFirstName,
  {
    isProfileFound = false,
    isOCIProfileComplete = false,
    isOCIProfileLoyalty = false,
    isUserAuthenticated = false,
  } = {}
) => {
  const profileFoundStepTitle = isOCIProfileComplete
    ? i18n('check_in_step_profile_confirm_info')
    : i18n('check_in_step_profile_renter_info');

  switch (currentStepHash) {
    case CHECKIN_PATH_CONFIG.driverLookup[0]:
      // Authenticated Res/User DL Lookup
      if (isUserAuthenticated) {
        return {
          title: i18n('check_in_step_driver_lookup_auth'),
          description: [i18n('check_in_step_driver_lookup_text_1'), i18n('check_in_step_driver_lookup_text_2_auth')],
        };
      }
      // Unauth Res/User DL Lookup
      return {
        title: i18n('check_in_step_driver_lookup'),
        description: [i18n('check_in_step_driver_lookup_text_1'), i18n('check_in_step_driver_lookup_text_2')],
      };

    case CHECKIN_PATH_CONFIG.profile[0]:
      // No Profile Match Scenario
      if (!isProfileFound) {
        return {
          title: i18n('check_in_step_profile_add_info'),
          description: [i18n('check_in_step_profile_not_found_text'), i18n('check_in_step_profile_pickup_info_text')],
        };
      }
      // Profile Matched, Loyalty profile, unauth user (can just confirm)
      if (isOCIProfileLoyalty && isUserAuthenticated) {
        return {
          title: profileFoundStepTitle,
          description: [
            i18n('check_in_step_profile_loyalty_found_signed_in_text', [authUserFirstName]),
            i18n('check_in_step_profile_pickup_info_text'),
          ],
        };
      }
      // Profile Matched, Loyalty profile, authenticated user
      if (isOCIProfileLoyalty && !isUserAuthenticated) {
        return {
          title: profileFoundStepTitle,
          description: [
            i18n('check_in_step_profile_loyalty_found_text'),
            i18n('check_in_step_profile_pickup_info_text'),
          ],
        };
      }
      // Profile Matched, NL Driver profile
      return {
        title: profileFoundStepTitle,
        description: [i18n('check_in_step_profile_found_text'), i18n('check_in_step_profile_pickup_info_text')],
      };

    case CHECKIN_PATH_CONFIG.checkInReview[0]:
      // Review is thankfully the same for every scenario :)
      return {
        title: i18n('check_in_step_review'),
        description: [i18n('check_in_step_review_text')],
      };
    default:
      return {};
  }
};

// TODO: change this to have the initialStep stored in GMA session instead
let initialStep = null;

/**
 * Based on the current step hash and where the check-in process was started by the user,
 *  determines both the current and total steps for the check-in step counter.
 * @param {string} currentStepHash - Current page in the OCI flow SPA (i.e. '/driver_lookup', '/profile')
 * @param {string} onlineCheckInOrigin - Where the check-in process was started (saved on GMA session)
 * @return {Object}
 */
export const parseStepCounter = (currentStepHash, onlineCheckInOrigin) => {
  const maxNumberOfCheckInSteps = 3;
  // TODO: change this to have the initialStep stored in GMA session instead
  initialStep = !initialStep || currentStepHash === CHECKIN_PATH_CONFIG.driverLookup[0] ? currentStepHash : initialStep;
  const isDriverLookupFirstStep = initialStep === CHECKIN_PATH_CONFIG.driverLookup[0];
  const shouldOmitReviewStep = onlineCheckInOrigin === CHECKIN_ORIGIN.CONFIRMATION;
  const total = maxNumberOfCheckInSteps - !isDriverLookupFirstStep - shouldOmitReviewStep;

  let current = 1;
  switch (currentStepHash) {
    case CHECKIN_PATH_CONFIG.driverLookup[0]:
      current = 1;
      break;
    case CHECKIN_PATH_CONFIG.profile[0]:
      current = 2 - !isDriverLookupFirstStep;
      break;
    case CHECKIN_PATH_CONFIG.checkInReview[0]:
      current = 3 - !isDriverLookupFirstStep;
      break;
    default:
      break;
  }

  return { current, total };
};

export const parseProfileUpdatePayload = (formData, shouldUpdateLicenseInfo, shouldUpdateContactInfo) => {
  const payload = { ...formData };

  if (!shouldUpdateLicenseInfo) {
    delete payload.license_info;
    delete payload.license_expiration_date;
    delete payload.license_issue_date;
    delete payload.birth_date;
  }
  if (!shouldUpdateContactInfo) {
    delete payload.address_info;
    delete payload.contact_info;
  }

  return payload;
};
