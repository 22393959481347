/**
 * Array helpers
 * @module
 */

import { utils } from '@ehi/global-marketing-interface';

/**
 * Sorts array by given key
 * @param {array} array
 * @param {string} key
 * @returns {array}
 */
export const sortByKey = (array, key) => array.sort((a, b) => a[key] > b[key]);

/**
 * Sorts array of objects by given key,
 * converting value from string to number
 * @param array
 * @returns {array}
 */
export function sortArrayOfObjectsByKeyStringToNumber(array, key) {
  return array.sort((p1, p2) =>
    // eslint-disable-next-line
    parseInt(p1[key], 10) > parseInt(p2[key], 10) ? 1 : parseInt(p1[key], 10) < parseInt(p2[key], 10) ? -1 : 0
  );
}

/**
 * Returns the next element of source or the first if reached the end
 * @param {integer} currentIndex
 * @param {Array} source
 * @returns {any}
 */
export const getNextOrFirstElement = (currentIndex, source) => {
  if (utils.isArrayNotEmpty(source)) {
    return currentIndex === source.length - 1 ? source[0] : source[currentIndex + 1];
  }
  return null;
};

/**
 * Returns the previous element of the source or the last if reached the top
 * @param {integer} currentIndex
 * @param {Array} source
 * @returns {any}
 */
export const getPreviousOrLastElement = (currentIndex, source) => {
  if (utils.isArrayNotEmpty(source)) {
    return currentIndex === 0 ? source[source.length - 1] : source[currentIndex - 1];
  }
  return null;
};

/**
 * Checks if the index is valid for provided source
 * @param {integer} index
 * @param {Array} source
 * @returns {Boolean}
 */
export const isValidIndex = (index, source) => {
  if (utils.isArrayNotEmpty(source)) {
    return index >= 0 && index < source.length;
  }
  return false;
};

/**
 * Converts an Array of Objects to an Object by an Object Key
 * @param {Array} array
 * @param {String} keyField
 * @returns {Object}
 */
export const arrayToObjectByKey = (array, keyField) =>
  array.reduce((accumulatedObj, item) => ({ ...accumulatedObj, [item[keyField]]: item }), {});

/**
 * Recursively converts an object or array of objects into a flattened array with its' values
 * @param {object/array} obj
 * @returns {array}
 */
export const flattenArrayAndObject = (obj) => {
  if (Array.isArray(obj)) {
    return [].concat(...obj.map(flattenArrayAndObject));
  }
  if (typeof obj === 'object') {
    return [].concat(...Object.values(obj).map(flattenArrayAndObject));
  }

  return obj;
};

/**
 * Merge two arrays. Useful if arrays are of unequal size and a rendering function needs to map through them to
 * generate rows of data.
 * @param {array} x
 * @param {array} y
 * @returns {array}
 */
export const mergeTwo = (x, y) => {
  const ret = [];
  const keysMerged = [...new Set([...x.map((valx) => valx.description), ...y.map((valy) => valy.description)])];
  for (let i = 0; i < keysMerged.length; i += 1) {
    const indexOnX = x.findIndex((valx) => valx.description === keysMerged[i]);
    const indexOnY = y.findIndex((valy) => valy.description === keysMerged[i]);

    if (indexOnY === -1) {
      ret.push([x[indexOnX], {}]);
    } else if (indexOnX === -1) {
      ret.push([{}, y[indexOnY]]);
    } else {
      ret.push([x[indexOnX], y[indexOnY]]);
    }
  }
  return ret;
};

/**
 * Change the order of an element in an array.
 *
 * @param {array} array
 * @param {number} from
 * @param {number} to
 * @returns {array}
 */
export const moveArrayIndex = (array, from, to) => {
  const newArray = [...array];
  newArray.splice(to, 0, newArray.splice(from, 1)[0]);

  return newArray;
};

/**
 *  Change the order of an given array with an another array.
 * @param {string} object
 * @param {string} keyName
 * @param {array} array
 * @returns {array}
 */

export const sortArray = (a, b, keyName, sortingArr) => sortingArr.indexOf(a[keyName]) - sortingArr.indexOf(b[keyName]);
