// TODO: Combine with reservationSelectors

import { createSelector } from 'reselect';
import utils from 'utils';
import { isObject } from 'utils/object';
import POLICIES from 'constants/policies';
import RESERVATIONS from 'constants/reservations';
import GMA_RESPONSE_CODES from 'constants/gmaResponseCodes';
import ADDITIONAL_DRIVER from 'constants/additionalDriver';
import { vehicleRatesFeesPath } from 'paths/carClassDetails';
import {
  gmaTrueModifyPath,
  gboReservationPath,
  gboReservationMessagesPath,
  reservationStatusPath,
  allowedResScreensPath,
  reservationExternalLinksPath,
} from 'paths/session';
import {
  selectedCarClassDetailsPath,
  selectedCarClassCodePath,
  selectedCarClassVehicleRatesPath,
  selectedCarClassCouponsPath,
  selectedCarClassesPath,
} from 'paths/reservationVehicle';
import { cancelRebookPath, selectedPaymentTypePath } from 'paths/reservationPayment';
import {
  inPathAdditionalDriversPath,
  numberAdditionalDriversAllowedPath,
  gmaAdditionalDriversPath,
} from 'paths/additionalDrivers';
import { counterBypassEligiblePath } from 'paths/skipTheCounter';
import { isDestinationCurrencySelected } from 'selectors/vehicleSelectSelectors';
import {
  updateVehiclePriceSelector,
  updateAdditionalDriverSelectors,
  updateVehicleRatesSelectors,
} from 'selectors/currencyToggleSelectors';

import {
  appExpandedTabPath,
  appMobileExpandedPath,
  appTotalDropDownExpandedTabPath,
  appDiscountCodesExpandedTabPath,
  appInResCheckInSaveTimePath,
  selectedPaymentPath,
} from 'paths/app';
import { onlineCheckInEligiblePath } from 'paths/checkin';

const {
  PREPAY_CODE,
  PAYLATER_CODE,
  THIRD_PARTY_RES_CODES,
  RESERVATION_STATUS_CHECKED_IN,
  RESERVATION_SUB_STATUS,
  ADVANCE_FEE,
  CANCEL_FEE,
  PAYMENT_TYPE,
  EXTERNAL_LINKS,
} = RESERVATIONS;

const prepayCursor = [...gboReservationPath, 'prepay_selected'];
const allVehiclesCursor = [...gboReservationPath, 'car_classes'];
const cancellationCursor = [...gboReservationPath, 'cancellation_details', 'cancel_fee_details'];
const modifyResCursor = [...gboReservationPath, 'res_eligibility', 'modify'];
const cancelResCursor = [...gboReservationPath, 'res_eligibility', 'cancel'];
const reservationSubStatusCursor = [...gboReservationPath, 'reservation_sub_status'];
const keyFactsPoliciesCursor = [...gboReservationPath, 'key_facts_policies'];
const reservationCouponsCursor = [...gboReservationPath, 'coupons'];

export const getDidPrepay = (state) => state.getIn(prepayCursor);
const getCancelRebook = (state) => state.getIn(cancelRebookPath);
const getSelectedPaymentType = (state) => state.getIn(selectedPaymentTypePath);
const getCarClassDetails = (state) => state.getIn(selectedCarClassDetailsPath);
const getVehicleRates = (state) => state.getIn(selectedCarClassVehicleRatesPath);
const getCarClassCode = (state) => state.getIn(selectedCarClassCodePath);
export const getAllVehicles = (state) => state.getIn(allVehiclesCursor);
const getCancelFees = (state) => state.getIn(cancellationCursor);
const getModifyResStatus = (state) => state.getIn(modifyResCursor);
const getCancelResStatus = (state) => state.getIn(cancelResCursor);
const getReservationSubStatus = (state) => state.getIn(reservationSubStatusCursor);
const getReservationStatus = (state) => state.getIn(reservationStatusPath);
const getKeyFactsPolicies = (state) => state.getIn(keyFactsPoliciesCursor);
const getReservationCoupons = (state) => state.getIn(reservationCouponsCursor);
const getSelectedCarClassCoupons = (state) => state.getIn(selectedCarClassCouponsPath);
const getSelectedCarClasses = (state) => state.getIn(selectedCarClassesPath);
const getInPathAdditionalDrivers = (state) => state.getIn(inPathAdditionalDriversPath);
const getGmaAdditionalDrivers = (state) => state.getIn(gmaAdditionalDriversPath);
const getGboErrorMessages = (state) => state.getIn(gboReservationMessagesPath);
export const getNumberAdditionalDriversAllowed = (state) => state.getIn(numberAdditionalDriversAllowedPath);
export const getCounterBypassEligibleFlag = (state) => state.getIn(counterBypassEligiblePath);
export const getOnlineCheckInEligibleFlag = (state) => state.getIn(onlineCheckInEligiblePath);
const getIsTrueModify = (state) => state.getIn(gmaTrueModifyPath);
const getAllowedResScreens = (state) => state.getIn(allowedResScreensPath);
const getResExternalLinks = (state) => state.getIn(reservationExternalLinksPath);
export const getProgressBarExpandedTab = (state) => state.getIn(appExpandedTabPath);
export const getProgressBarTotalDropDownExpandedTab = (state) => state.getIn(appTotalDropDownExpandedTabPath);
export const getProgressBarMobileExpanded = (state) => state.getIn(appMobileExpandedPath);
export const getDiscountCodesExpandedTab = (state) => state.getIn(appDiscountCodesExpandedTabPath);
export const getSelectedInResOCISaveTime = (state) => state.getIn(appInResCheckInSaveTimePath);
export const getBillingTypeSelected = (state) => state.getIn(selectedPaymentPath);

// Determines whether there is a selected vehicle in the reservation session
export const hasSelectedVehicleSelector = createSelector([getCarClassCode], (carClassCode) => !!carClassCode);

// This selector will grab the vehicle object inside of the car_classes list
// in order to acquire information about 'charges', which are no present
// on car_class_details. This object contains the full rates of the vehicles WITH taxes (when applicable)
export const carClassChargesSelector = createSelector(
  [getCarClassDetails, getAllVehicles, getDidPrepay],
  (carClassDetails, allCars, didPrepay) => {
    const code = carClassDetails?.get('code');
    const selectedCarCode = carClassDetails?.get('selected_car_class_code');
    const carClassCharges = allCars
      ?.find((vehicle) => vehicle.get('code') === code || vehicle.get('code') === selectedCarCode)
      ?.getIn(['charges', didPrepay ? PREPAY_CODE : PAYLATER_CODE]);
    return carClassCharges?.toJS();
  }
);

// we have various needs to peek inside of the selected vehicle rate
// this eliminates the need to repeatedly code to check which rate type is applied
export const vehicleRateSelector = createSelector([getDidPrepay, getVehicleRates], (didPrepay, vehicleRates) =>
  vehicleRates?.find((rate) => rate.get('charge_type') === (didPrepay ? PREPAY_CODE : PAYLATER_CODE))
);

// this selector is a dup of the above but returns a safe JS instead of the immutable
// TODO: refactor uses of vehicleRateSelector to this safe one
export const reservationVehicleRateSelector = createSelector(
  [getDidPrepay, getVehicleRates, isDestinationCurrencySelected],
  (didPrepay, vehicleRates, destinationCurrencySelected) => {
    if (destinationCurrencySelected) {
      let modifyVehicleRates = utils.safeToJS(
        vehicleRates?.find((rate) => rate.get('charge_type') === (didPrepay ? PREPAY_CODE : PAYLATER_CODE))
      );
      modifyVehicleRates = updateVehicleRatesSelectors(modifyVehicleRates);
      return modifyVehicleRates;
    }

    return utils.safeToJS(
      vehicleRates?.find((rate) => rate.get('charge_type') === (didPrepay ? PREPAY_CODE : PAYLATER_CODE))
    );
  }
);

// Get additional driver free spouse
export const reservationHasAdditionalDriverFreeSpouseSelector = createSelector(
  [reservationVehicleRateSelector],
  (vehicleRates) => vehicleRates?.supplemental?.additional_driver?.free_spouse
);

export const estimatedTotalViewSelector = createSelector(
  [isDestinationCurrencySelected, vehicleRateSelector],
  (destinationCurrencySelected, vehicleRate) => {
    if (destinationCurrencySelected) {
      return utils.safeToJS(vehicleRate?.getIn(['price_summary', 'estimated_total_payment']), {});
    }
    return utils.safeToJS(vehicleRate?.getIn(['price_summary', 'estimated_total_view']), {});
  }
);

export const estimatedTotalVehiclePaymentSelector = (state) =>
  utils.safeToJS(vehicleRateSelector(state)?.getIn(['price_summary', 'estimated_total_vehicle_payment']), {});

// Used for when you need access to opposing vehicle rate display (e.g. 'switch to prepay for $xx');
export const oppositeVehicleRateSelector = createSelector([getDidPrepay, getVehicleRates], (didPrepay, vehicleRates) =>
  vehicleRates?.find((rate) => rate.get('charge_type') === (didPrepay ? PAYLATER_CODE : PREPAY_CODE))
);

export const appliedCancelFeeDetailsSelector = createSelector([getCancelFees], (cancelFeeDetails) => {
  const cancelFeesArray = utils.safeToJS(cancelFeeDetails, []);
  const cancelledObject = cancelFeesArray.find(({ fee_apply }) => fee_apply);
  return isObject(cancelledObject) ? cancelledObject : {};
});

export const cancelFeeSelector = createSelector([getCancelFees], (cancelFees) => {
  const cancelFeesArray = utils.safeToJS(cancelFees, []);
  return cancelFeesArray.find((cancelObject) => cancelObject?.cancel_fee_type === CANCEL_FEE);
});

export const cancelFeePrePayTypeSelector = createSelector([getCancelFees], (cancelFees) => {
  const cancelFeesArray = utils.safeToJS(cancelFees, []);
  const prePayCancellationArrayIndex = cancelFeesArray.find(
    (cancelObject) => cancelObject?.cancel_fee_type === ADVANCE_FEE
  );
  return prePayCancellationArrayIndex;
});

const canModifyReservationSelector = createSelector([getModifyResStatus], (modifyRes) => modifyRes?.get('allowed'));

export const canCancelReservationSelector = createSelector([getCancelResStatus], (cancelRes) =>
  cancelRes?.get('allowed')
);

export const resEligibilityReasonCodesSelector = createSelector(
  [getModifyResStatus, getCancelResStatus],
  (modifyRes, cancelRes) => {
    const modifyResStatus = utils.safeToJS(modifyRes?.get('reason_codes'), []);
    const cancelResStatus = utils.safeToJS(cancelRes?.get('reason_codes'), []);

    return [...modifyResStatus, ...cancelResStatus];
  }
);

export const isThirdPartyReservationSelector = createSelector(
  [resEligibilityReasonCodesSelector, canModifyReservationSelector, canCancelReservationSelector],
  (reasonCodes, canModify, canCancel) => {
    const thirdPartyMessageExists = reasonCodes?.some((reason) =>
      Object.values(THIRD_PARTY_RES_CODES).includes(reason)
    );
    return thirdPartyMessageExists && !canModify && !canCancel;
  }
);

// Returns array with any reason codes if modify is not allowed, otherwise returns null
export const modifyNotAllowedReasonsSelector = createSelector([getModifyResStatus], (modifyRes) =>
  modifyRes?.get('allowed') === false ? utils.safeToJS(modifyRes.get('reason_codes'), []) : null
);

export const modifyCancelResExternalLinkSelector = createSelector([getResExternalLinks], (externalLink) => {
  const externalLinkArray = utils.safeToJS(externalLink, []);
  return externalLinkArray.find((externalLinkObj) => externalLinkObj?.code === EXTERNAL_LINKS.MODIFY_BLOCK);
});

export const onlineCheckinExternalLinkSelector = createSelector([getResExternalLinks], (externalLink) => {
  const externalLinkArray = utils.safeToJS(externalLink, []);
  return externalLinkArray.find((externalLinkObj) => externalLinkObj?.code === EXTERNAL_LINKS.ONLINE_CHECK_IN);
});

export const counterByPassExternalLinkSelector = createSelector([getResExternalLinks], (externalLink) => {
  const externalLinkArray = utils.safeToJS(externalLink, []);
  return externalLinkArray.find((externalLinkObj) => externalLinkObj?.code === EXTERNAL_LINKS.COUNTER_BYPASS);
});

export const isCheckedInReservationSelector = createSelector(
  [getReservationSubStatus],
  (status) => status === RESERVATION_STATUS_CHECKED_IN
);

export const isSkipTheCounterCompletedReservationSelector = createSelector(
  [getReservationSubStatus],
  (status) => status === RESERVATION_SUB_STATUS.COUNTER_BYPASS
);

export const isCheckedInOrSkippingTheCounterSelector = createSelector(
  [isCheckedInReservationSelector, isSkipTheCounterCompletedReservationSelector],
  (ociStatus, stcStatus) => ociStatus || stcStatus
);

export const additionalDriversPolicySelector = createSelector([getKeyFactsPolicies], (policies) =>
  (utils.safeToJS(policies) || []).find(({ code }) => code === POLICIES.ADDITIONAL_DRIVERS)
);

export const reservationCouponsSelector = createSelector([getReservationCoupons], (coupons) =>
  utils.safeToJS(coupons, [])
);

export const selectedCarClassCoupons = createSelector([getSelectedCarClassCoupons], (coupons) =>
  utils.safeToJS(coupons, [])
);

export const hasCarClassesSelector = createSelector([getSelectedCarClasses], (carClasses) =>
  utils.gmi.isArrayNotEmpty(utils.safeToJS(carClasses, []))
);

// TODO - maybe move additional driver selectors to separate file, theres at least 4 now
export const inPathAdditionalDriversSelector = createSelector([getInPathAdditionalDrivers], (inPathAdditionalDrivers) =>
  inPathAdditionalDrivers ? inPathAdditionalDrivers.toJS() : []
);

export const additionalDriverReservationDetailsSelector = createSelector(
  [vehicleRateSelector, isDestinationCurrencySelected],
  (vehicleRate, destinationCurrencySelected) => {
    if (destinationCurrencySelected) {
      let additionDrivers = utils.safeToJS(vehicleRate?.getIn(['supplemental', 'additional_driver']), {});
      additionDrivers = updateAdditionalDriverSelectors(additionDrivers);
      return additionDrivers;
    }
    return utils.safeToJS(vehicleRate?.getIn(['supplemental', 'additional_driver']), {});
  }
);

export const euYoungDriverFeeSelector = createSelector(
  [vehicleRateSelector, inPathAdditionalDriversSelector, isDestinationCurrencySelected],
  (vehicleRates, driversList, destinationCurrencySelected) => {
    let individual_id;
    let rate;
    const isYoungDriver = vehicleRates?.getIn(vehicleRatesFeesPath)?.some((fee) => {
      const isYoungRenterFee = fee.get('description') === ADDITIONAL_DRIVER.YOUNG_RENTER_FEE_CODE;
      if (isYoungRenterFee) {
        individual_id = fee.get('driver_individual_id');
        const totalAmount = destinationCurrencySelected
          ? utils.safeToJS(fee.get('total_amount_payment'))
          : utils.safeToJS(fee.get('total_amount_view'));
        const totalPrice = utils.getFormattedPrice(totalAmount);
        const incrementalPricingEnabled = true;
        if (incrementalPricingEnabled) {
          const incrementalPricingView_Payment = destinationCurrencySelected
            ? utils.safeToJS(fee.get('rate_amount_payment'))
            : utils.safeToJS(fee.get('rate_amount_view'));
          const incrementalPrice = utils.getFormattedPrice(incrementalPricingView_Payment);
          const rateType = fee.get('rate_type');
          rate = `${incrementalPrice}/${utils.i18n(`vehicle_select_incremental_unit_${rateType}`)} (${totalPrice})`;
        } else {
          rate = totalPrice;
        }
      }
      return isYoungRenterFee;
    });
    if (!isYoungDriver) {
      return { youngDriverFee: null, youngDriverName: null };
    }
    const youngDriver = driversList.find((driver) => driver.individual_id === individual_id);
    return {
      youngDriverFee: rate,
      youngDriver: { first_name: youngDriver?.first_name, last_name: youngDriver?.last_name, individual_id },
    };
  }
);

export const gmaAdditionalDriversSelector = createSelector([getGmaAdditionalDrivers], (gmaAdditionalDrivers) =>
  utils.safeToJS(gmaAdditionalDrivers, [])
);

export const priceSummarySelector = createSelector(
  [vehicleRateSelector, isDestinationCurrencySelected],
  (vehicleRates, destinationCurrencySelected) => {
    if (destinationCurrencySelected && vehicleRates) {
      let priceSummaryDetails = utils.safeToJS(vehicleRates?.getIn(['price_summary']), {});
      priceSummaryDetails = updateVehiclePriceSelector(priceSummaryDetails);
      return priceSummaryDetails;
    }
    return utils.safeToJS(vehicleRates?.getIn(['price_summary']), {});
  }
);

/**
 * Identifies a reservation as EU PP.
 * According to https://jira.ehi.com/browse/GBO-30281 we can identify an EU PP reservation
 * by its' payment type (voucher) or by the cancel_rebook flag.
 */
export const isEUPrepaySelector = createSelector(
  [getDidPrepay, getCancelRebook, getSelectedPaymentType],
  (isPrepaySelected, isCancelRebook, selectedPaymentType) =>
    isPrepaySelected && (isCancelRebook || selectedPaymentType === PAYMENT_TYPE.VOUCHER)
);

// Identifies a reservation as NA PP. Basically checking if it's PP, but not EU.
export const isNAPrepaySelector = createSelector(
  [getDidPrepay, isEUPrepaySelector],
  (isPrepaySelected, isEUPrepay) => isPrepaySelected && !isEUPrepay
);

// Identifies whether the current reservation is NA PP and being currently modified.
export const isNAPrepayModifySelector = createSelector(
  [getIsTrueModify, isNAPrepaySelector],
  (isTrueModify, isNAPrepay) => isTrueModify && isNAPrepay
);

export const isCounterBypassIneligibleSelector = createSelector([getGboErrorMessages], (messages) =>
  utils
    .safeToJS(messages, [])
    .some((message) => message.code === GMA_RESPONSE_CODES.GBO_COUNTER_BYPASS_TURNED_INELIGIBLE)
);

export const isReservationFlowNotAllowedSelector = createSelector(
  [getReservationStatus, getIsTrueModify, getAllowedResScreens],
  (reservationStatus, isTrueModify, allowedScreens) => !!reservationStatus && !isTrueModify && !allowedScreens
);

export const isOciOrStcFlowNotAllowedSelector = createSelector(
  [getReservationStatus, getIsTrueModify],
  (reservationStatus, isTrueModify) => !!reservationStatus && !isTrueModify
);

export const hasSelectedPayAtCounter = createSelector(
  [getBillingTypeSelected],
  (paymentSelected) => paymentSelected && paymentSelected === RESERVATIONS.PAY_AT_COUNTER
);
