/**
 * Key Event Utils
 * @author thomas.miller@isobar.com
 * @author yauheni.mukavozchyk@isobar.com
 */

import KEYS from 'constants/keys';

/**
 * Determine what keys were pressed, format as object of booleans
 * @param {Event} e
 * @returns {Object}
 */
export const formatKeys = e => {
  const key = e.keyCode || e.which;

  const keys = {
    shift: key === KEYS.SHIFT,
    tab: key === KEYS.TAB,
    arrowUp: key === KEYS.ARROW_UP,

    arrowRight: key === KEYS.ARROW_RIGHT,
    arrowDown: key === KEYS.ARROW_DOWN,

    arrowLeft: key === KEYS.ARROW_LEFT,
    space: key === KEYS.SPACE,
    escape: key === KEYS.ESCAPE,
    enter: key === KEYS.ENTER,

    home: key === KEYS.HOME,
    end: key === KEYS.END,
  };

  return Object.assign(keys, {
    shiftTab: keys.shift && keys.tab,
    arrowLeftOrRight: keys.arrowLeft || keys.arrowRight,
    arrowUpOrDown: keys.arrowUp || keys.arrowDown,
  });
};
