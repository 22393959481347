/* 
Session storage keys
*/
export const SESSION_STORAGE = {
  USER_AGE_SELECTED: 'USER_AGE_SELECTED',
  SKIP_THE_COUNTER_ORIGIN: 'SKIP_THE_COUNTER_ORIGIN',
  COUNTRY_SELECTED: 'COUNTRY_SELECTED',
  SOLD_OUT_LOCATION: 'soldOutLocation',
  IS_SELECTED_IN_RESOCI_SAVE_TIME: 'isSelectedInResOCISaveTime',
  SHOW_EMAIL_UPDATE_MODAL: 'SHOW_EMAIL_UPDATE_MODAL',
  JSON_PARSE: true,
  ADD_FEATURED_CAR_CLASS: 'ADD_FEATURED_CAR_CLASS',
  INITIATE_RES_FLOW_TRUE_MODIFY: 'INITIATE_RES_FLOW_TRUE_MODIFY',
  RENTAL_TICKET_DETAILS: 'RENTAL_TICKET_DETAILS',
  MODIFY_RENTAL_ORIGINAL_DATE: 'MODIFY_RENTAL_ORIGINAL_DATE',
};
