/**
 * NOTE: PLEASE USE MORE CURRENT useClickOutside HOOK IF USING FUNCTIONAL COMPONENTS
 *
 * withClickOutside HOC util
 * @module
 * @author react-click-outside
 * @author yauheni.mukavozchyk@isobar.com
 * Usage:
 *    0. wrap target component with withClickOutside(...) or add as LAST hoc in container compose pattern (will break if not last)
 *    1. define 'handleClickOutside(e)' in target component
 *    2. set 'ref={this.clickOutsideRef}' on the corresponding node of your target component
 */

import React from 'react';

// WrappedComponent must be a class which extends React.Component
export const withClickOutside = WrappedComponent => {
  class ClickOutsideEnhancer extends WrappedComponent {
    constructor(props) {
      super(props);
      this.isTouch = false;
      this.clickOutsideRef = React.createRef();
    }

    componentDidMount() {
      super.componentDidMount && super.componentDidMount();
      document.addEventListener('touchend', this.handle, true);
      document.addEventListener('mousedown', this.handle, true);
    }

    componentWillUnmount() {
      super.componentWillUnmount && super.componentWillUnmount();
      document.removeEventListener('touchend', this.handle, true);
      document.addEventListener('mousedown', this.handle, true);
    }

    handle = e => {
      if (e.type === 'touchend') {
        this.isTouch = true;
      }
      if (e.type === 'mousedown' && this.isTouch) {
        return;
      }

      const node = this.clickOutsideRef.current;
      if (node && !node.contains(e.target)) {
        this.handleClickOutside && this.handleClickOutside(e);
      }
    };

    render() {
      return super.render();
    }
  }

  ClickOutsideEnhancer.displayName = `ClickOutsideEnhancer(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    'Component'})`;

  return ClickOutsideEnhancer;
};
