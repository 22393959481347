import RESERVATIONS from "constants/reservations";
import { sessionReservationObjectSelector } from "selectors";
import { getUiInitiateState } from "selectors/uiStateSelectors";
import { preSelectedContractNumberSelector, preSelectedCouponsSelector } from "selectors/reservationSelectors";
import utils from "utils";

/**
 * @name setReservationSessionState
 * @desc storing the session ldt values in redux state
 * @returns {Function}
 */
export const setReservationSessionState = () => (dispatch, getState) => {
    const state = getState();
    // getting the stored res initiatedState  value for cid ,coupons if stored
    const getCouponOrContractDetails = JSON.parse(sessionStorage.getItem(RESERVATIONS.IS_RES_INITIATED_COUPON_CID));
    const initiateRequestSessionObject = sessionReservationObjectSelector(state);

    const isCurrentPathSameasPreviousPath = getCouponOrContractDetails?.pathname === location.pathname;

    const initiateUiState = getUiInitiateState(state);
    const preSelectedCoupons = preSelectedCouponsSelector(state);
    const preSelectedContractNumber = preSelectedContractNumberSelector(state);

    let data = {
        one_way_rental: initiateRequestSessionObject.get('one_way_rental'),
        pickup_location: initiateRequestSessionObject.get('pickup_location').toJS(),
        pickup_location_id: initiateRequestSessionObject.get('pickup_location_id'),
        pickup_time: initiateRequestSessionObject.get('pickup_time'),
        renter_age: initiateRequestSessionObject.get('renter_age'),
        return_location: initiateRequestSessionObject.get('return_location').toJS(),
        return_location_id: initiateRequestSessionObject.get('return_location_id'),
        return_time: initiateRequestSessionObject.get('return_time'),
        partner_reward_program_code: initiateRequestSessionObject.get('partner_reward_program_code'),
        partner_reward_program_member_id: initiateRequestSessionObject.get('partner_reward_program_member_id'),
        partner_reward_program_name: initiateRequestSessionObject.get('partner_reward_program_name'),
        isCurrentPathSameasPreviousPath,
    };

    if (getCouponOrContractDetails?.coupons && isCurrentPathSameasPreviousPath) {
        data = {
            ...data,
            coupons: getCouponOrContractDetails?.coupons && utils.safeToJS(initiateRequestSessionObject.get('coupons')),
        };
    } else {
        data = {
            ...data,
            coupons: initiateUiState?.coupons || preSelectedCoupons,
        };
    }

    if (getCouponOrContractDetails?.contractNumber && isCurrentPathSameasPreviousPath) {
        data = {
            ...data,
            contract_number: initiateRequestSessionObject.get('contract_number'),
            contract_details: utils.safeToJS(initiateRequestSessionObject.get('contract_details')),
        };
    } else {
        data = {
            ...data,
            contract_number: initiateUiState?.contract_number || preSelectedContractNumber,
        };
    }

    return dispatch({
        type: RESERVATIONS.RESERVATION_SESSION_PATH,
        data,
    });
};