import { actions } from '@ehi/global-marketing-interface';
import { uiStateShowPartnersLocations } from 'paths/uiState';

export const VEHICLES_FILTERS = {
  SET_PASSENGER_FILTERS: 'SET_PASSENGER_FILTERS',
};

export const setShowPartnersLocations = display => actions.setGmiState(uiStateShowPartnersLocations, display);

export const setPassengerFiltersAction = (data = {}) => ({
  type: VEHICLES_FILTERS.SET_PASSENGER_FILTERS,
  data,
});
