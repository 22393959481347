import { actions, services } from '@ehi/global-marketing-interface';
import utils from 'utils';

import { gmiRequest } from 'actions/request';
import { loadingOverlay } from 'utils/dom';

let cancelBranchHours = null;
let peopleSoftIdBeingCalled = null;
export const getBranchHours = (peopleSoftId) => (dispatch) => {
  //  Don't cancel hours if are making the same call with different peopleSoftId's
  if (cancelBranchHours !== null && peopleSoftIdBeingCalled === peopleSoftId) {
    utils.safeExecute(cancelBranchHours);
    peopleSoftIdBeingCalled = peopleSoftId;
  }
  dispatch(
    actions.request({
      data: {
        endpoint: { peopleSoftId },
        params: {
          //  YYYY-MM-DD is expected by solr and is not part of our domain-specific date/time config
          from: utils.gmi.getCurrentDateTimeObj().format('YYYY-MM-DD'),
        },
      },
      opts: {
        cancelFn: (c) => {
          cancelBranchHours = c;
        },
        clearStateOnRequest: false,
      },
      service: services.solr.hours,
    })
  ).then(() => {
    cancelBranchHours = null;
    peopleSoftIdBeingCalled = null;
  });
};

let cancelLocationById = null;
export const locationInfoById = (id, showLoadingOverlay) => (dispatch) => {
  if (cancelLocationById !== null) {
    utils.safeExecute(cancelLocationById);
  }
  if (showLoadingOverlay) {
    loadingOverlay(true);
  }
  return dispatch(
    actions.request({
      data: {
        endpoint: {
          id,
        },
      },
      opts: {
        cancelFn: (c) => {
          cancelLocationById = c;
        },
        clearStateOnRequest: false,
      },
      service: services.gma.location.byId,
    })
  ).then((data) => {
    cancelLocationById = null;

    if (showLoadingOverlay) {
      loadingOverlay(false);
    }

    return data;
  });
};

/**
 * getCountries
 * gmi service call fetch country data from GBO
 *
 * @return {Promise}
 */
export const getCountries =
  (loading = true) =>
  (dispatch) =>
    dispatch(
      gmiRequest({
        loadingOverlay: loading,
        action: actions.request({
          opts: {
            clearStateOnRequest: false,
          },
          data: {
            endpoint: {
              iso3: true,
            },
          },
          service: services.gma.location.countries,
        }),
      })
    );

/**
 * getStatesOrProvinces
 * gmi service call fetch state and province data from GBO
 *
 * @param {string} countryCode - the country code for which state data is being fetched
 * @return {Promise}
 */
export const getStatesOrProvinces = (countryCode) => (dispatch) =>
  dispatch(
    gmiRequest({
      loadingOverlay: true,
      action: actions.request({
        data: {
          endpoint: {
            countryCode,
            iso3: true,
          },
        },
        opts: {
          clearStateOnRequest: false,
        },
        service: services.gma.location.statesOrProvinces,
      }),
    })
  );
