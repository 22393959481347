import { actions, utils } from '@ehi/global-marketing-interface';
import { List } from 'immutable';

export const VEHICLE_GRID = {
  VEHICLE_GRID_DATA: '@@vehicle/VEHICLE_GRID_DATA',
};

/**
 * @name setVehicleGridData
 * @desc Sets vehicle grid related data in state
 * @returns {{type: string, data: object}}
 * @example
 * dispatch(setVehicleLandingFlag(false));
 */
export const setVehicleGridData = data => ({ type: VEHICLE_GRID.VEHICLE_GRID_DATA, data });

// clone values if array
const getActiveVehicleFilters = (filters = []) =>
  filters
    .filter(filter => filter.values !== null && filter.values.length > 0)
    .map(filter => {
      const { keyPath, values, available } = filter;
      const valuesType = utils.types(values);

      // todo: think about returning radioValue so the below doesn't need to happen
      let { radioValue } = filter;
      if (!radioValue && valuesType.isFunction) {
        const selected = available.filter(option => option.checked)[0];
        radioValue = selected ? selected.value : null;
      }

      return {
        keyPath,
        values: valuesType.isArray ? values.slice() : values,
        radioValue,
      };
    });

const updateRangeFilter = ({ filters, keyPath, value }) => {
  // find index of this specific filter in active filters
  const filtersIndex = filters.findIndex(filter => filter.keyPath === keyPath);

  // build filter object
  const filter = {
    keyPath,
    values: v => parseInt(v, 10) >= parseInt(value, 10),
    radioValue: value,
  };

  if (filtersIndex !== -1) {
    // if filter already exists
    if (value) {
      // if selecting a range of values
      filters.splice(filtersIndex, 1, filter);
    } else {
      // if deleting a value
      filters.splice(filtersIndex);
    }
  } else if (value) {
    // if filter doesn't exist
    filters.push(filter);
  }

  return filters;
};

const updateMultiSelectFilter = ({ filters, keyPath, value, checked }) => {
  let filteredFilters = filters.slice();
  // find index of this specific filter in active filters
  const filtersIndex = filteredFilters.findIndex(filter => filter.keyPath === keyPath);

  if (filtersIndex !== -1) {
    // if filter is already active
    // find the index of the specific value within the active filter
    const valueIndex = filteredFilters[filtersIndex].values.findIndex(v => v === value);
    if (valueIndex !== -1 && !checked) {
      // if the value is in the filter and we want to remove it
      filteredFilters = filteredFilters.reduce((acc, filter) => {
        const updatedFilter = { ...filter };
        if (updatedFilter.keyPath === keyPath) {
          updatedFilter.values = filter.values.filter(val => val !== value || checked);
        }
        if (updatedFilter.values) {
          acc.push(updatedFilter);
        }
        return acc;
      }, []);
    } else if (checked) {
      // if the value is not present in the filter
      filteredFilters[filtersIndex].values.push(value);
    }
  } else if (checked) {
    // if the filter is not already active and setting the value to checked
    filteredFilters.push({ keyPath, values: [value] });
  }
  return filteredFilters;
};

// update vehicle filters without removing existing filters
export const setCarClassFiltersByValue = ({ value, checked, keyPath, isRadio }) => (dispatch, getState) => {
  const state = getState();
  const currentFilters = state.getIn(['gmi', 'ui', 'filters', 'vehicle-page__grid', 'filters'], List()).toJS();
  const currentActiveFilters = getActiveVehicleFilters(currentFilters).filter(filter => filter.values);
  const filters = isRadio
    ? updateRangeFilter({ filters: currentActiveFilters, keyPath, value })
    : updateMultiSelectFilter({ filters: currentActiveFilters, keyPath, value, checked });
  dispatch(actions.setFiltersUiByKey('vehicle-page__grid', filters));
};
