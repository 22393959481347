/**
 * Reducer for managing breakpoint state
 * @module
 * @author brian.fegan@isobar.com
 */

import { Map } from 'immutable';

/**
 * Checks for breakpoint actions to update state, or returns original state
 * @param {Object} state An immutable state object to check against
 * @param {Object} action Data to update the redux store state
 * @returns {Object} A (usually changed) immutable state object
 */
export default function(state = Map(), action) {
  switch (action.type) {
    case 'BREAKPOINT_CHANGE':
      return state.setIn(['app', 'breakpoint'], action.breakpoint);
    default:
      return state;
  }
}
