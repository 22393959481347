import { Map } from 'immutable';
import { selectedPaymentPath } from 'paths/app';
import { PAYMENT } from 'actions/payment';

const initialState = Map({
  payment_type: null,
});

const ACTION_HANDLERS = {
  [PAYMENT.SELECTED]: (state, { data }) => state.setIn([...selectedPaymentPath], data),
};

/**
 * Action handling for billing type option state updates during trueModify flow
 * @param {Object} state An immutable state object to check against
 * @param {Object} action Data to update the redux store state
 * @returns {Object} A (usually changed) immutable state object
 */

const reducer = (state = initialState, action = null) => {
  const handler = action ? ACTION_HANDLERS[action.type] : null;
  return handler ? handler(state, action) : state;
};

export default {
  initialState,
  reducer,
};
