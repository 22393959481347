import { i18n } from './i18n';
/*
 *
 * Utilty function for display plural or singular version a word,
 * @input: Number Of Items, Singular Version, Plural Version, if the function should return null
 * when give a value of zero or just return 0, replacements (defaults to array)
 */

export const pluralHandler = (count, singularVersion, pluralVersion, nullOnZero = false, ...replacements) => {
  if (nullOnZero && count === 0) {
    //  If we want something to not render when we have 0 items
    return null;
  }
  if (count === 0 || count > 1) {
    return i18n(pluralVersion, replacements);
  }
  return i18n(singularVersion, replacements);
};
