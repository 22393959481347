import SKIP_THE_COUNTER from 'constants/skipTheCounter';
import { i18n } from './i18n';

const { STC_PATH_CONFIG } = SKIP_THE_COUNTER;

/**
 * Based on the current step hash, returns the step content.
 * @param {string} currentStepHash - Current page in the STC flow SPA (i.e. '/review_selections', '/skip_the_counter')
 * @param {boolean} isNAPrepay - Is reservation NA Prepay?
 * @param {boolean} isCustomPathReservation - Is reservation custom path enabled
 * @return {Object}
 */
export const getSkipTheCounterStepsContent = (currentStepHash, isNAPrepay, isCustomPathReservation) => {
  // Set description based on the type of reservation
  let stcDescription = '';
  if (isNAPrepay) {
    stcDescription = i18n('stc_get_ready_step_description_na_prepay');
  } else if (isCustomPathReservation) {
    stcDescription = i18n('stc_get_ready_step_description_custom_path');
  } else {
    stcDescription = i18n('stc_get_ready_step_description');
  }

  switch (currentStepHash) {
    case STC_PATH_CONFIG.reviewSelections[0]:
      return {
        title: i18n('stc_review_selections_step_title'),
        description: i18n('stc_review_selections_step_description'),
      };
    case STC_PATH_CONFIG.skipTheCounter[0]:
      return {
        title: isCustomPathReservation ? i18n('stc_eligible_title') : i18n('stc_get_ready_step_title'),
        description: stcDescription,
      };
    default:
      return {};
  }
};

/**
 * Based on the current step hash determines the current step for the Skip The Counter step counter.
 * @param {string} currentStepHash - Current page in the STC flow SPA (i.e. '/review_selections', '/skip_the_counter')
 * @return {Object}
 */
export const parseSkipTheCounterStepCounter = currentStepHash => {
  let current = 1;
  switch (currentStepHash) {
    case STC_PATH_CONFIG.reviewSelections[0]:
      current = 1;
      break;
    case STC_PATH_CONFIG.skipTheCounter[0]:
      current = 2;
      break;
    default:
      break;
  }

  return current;
};
