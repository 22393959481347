import PLATFORMS from 'constants/platforms';
/**
 * isInternetExplorer11
 * https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
 * feature detection specifically true for IE11, false on other IE versions and Edge
 */
export const isInternetExplorer11 = () => !!window.MSInputMethodContext && !!document.documentMode;

/**
 * function to detect Mobile device Platform
 */

export const isMobile = {
  hasMobileKey: () => navigator.userAgent.match(/Mobile/i),

  Android: () => navigator.userAgent.match(/Android/i),

  IOS: () => navigator.userAgent.match(/iPhone/i) && isMobile.hasMobileKey(),
};

export const getPlatform = () => {
  if (isMobile.Android()) {
    return PLATFORMS.ANDROID;
  }
  if (isMobile.IOS()) {
    return PLATFORMS.IOS;
  }
  if (isMobile.hasMobileKey()) {
    return PLATFORMS.OTHER_MOBILE;
  }
  return PLATFORMS.NON_MOBILE;
};
