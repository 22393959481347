import { config } from './config';
import { i18n } from './i18n';

const getCookieValue = (name) => document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || '';

export default {
  // this mimics the shape that date-fns creates.
  // doing this to avoid importing their locales and using our AEM data instead
  getAkamaiCountryCode() {
    const akamaiHeaders = {};
    getCookieValue('Akamai-Edgescape')
      .replace(', ', ',')
      .split(',')
      .map((x) => x.split('='))
      .map((x) => {
        const [, val] = x;
        akamaiHeaders[x[0]] = val;
        return akamaiHeaders[x[0]];
      });
    return akamaiHeaders.country_code || 'US';
  },
  buildDatePickerLocale(code) {
    const monthsDays = this.getMonthsDays();
    return {
      code,
      formatLong: {
        date: (format) => {
          switch (format) {
            case 'full':
              return config.getWeek('llll');
            case 'long':
              return config.getWeek('lll');
            case 'medium':
              return config.getWeek('ll');
            case 'short':
              return config.getWeek('l');
            default:
              return config.getWeek('ll');
          }
        },
        time: (format) => {
          switch (format) {
            case 'full':
              return config.getWeek('lts');
            case 'long':
              return config.getWeek('lts');
            case 'medium':
              return config.getWeek('lt');
            case 'short':
              return config.getWeek('lt');
            default:
              return config.getWeek('lt');
          }
        },
        dateTime: () => 'dateTime', // I don't think this is used in the date picker
      },
      localize: {
        month: (index, format) => {
          switch (format) {
            case 'narrow':
              return monthsDays.monthsShort[index];
            case 'short':
              return monthsDays.monthsShort[index];
            case 'wide':
              return monthsDays.months[index];
            default:
              return monthsDays.months[index];
          }
        },
        day: (index, format) => {
          switch (format) {
            case 'narrow':
              return monthsDays.weekdaysMin[index];
            case 'short':
              return monthsDays.weekdaysShort[index];
            case 'abbreviated':
              return monthsDays.weekdaysShort[index];
            case 'wide':
              return monthsDays.weekdays[index];
            default:
              return monthsDays.weekdays[index];
          }
        },
      },
      options: {
        weekStartsOn: config.getWeek('dow'),
        firstWeekContainsDate: config.getWeek('doy'),
      },
    };
  },
  getMonthsDays() {
    return {
      months: i18n('months').split('.'),
      monthsShort: i18n('months_short').split('.'),
      weekdays: i18n('weekdays').split('.'),
      weekdaysShort: i18n('weekdays_short').split('.'),
      weekdaysMin: i18n('weekdays_min').split('.'),
    };
  },
};
