import { createSelector } from 'reselect';
import utils from 'utils';
import { pickupLocationAirlineDetailsPath } from 'paths/reservationLocation';

// getters
const getReservationDriverDetails = (state) => state.getIn(['gmi', 'session', 'gbo', 'reservation', 'driver_info']);

export const getPreSelectedDriverInfo = (state) =>
  state.getIn(['gmi', 'session', 'gma', 'pre_selected', 'reservation', 'driver_info']);

export const prepayTerms = (state) =>
  state.getIn(['gmi', 'gma', 'content', 'terms', 'prepay', 'prepay_terms_and_conditions']);

const getPickupLocationAirlineDetails = (state) => state.getIn(pickupLocationAirlineDetailsPath);

// selectors
export const driverDetailsSelector = createSelector([getReservationDriverDetails], (reservationDriverDetails) =>
  reservationDriverDetails
    ? {
        reservationFullName: `${reservationDriverDetails.get('first_name')} ${reservationDriverDetails.get(
          'last_name'
        )}`,
        reservationEmail: reservationDriverDetails.get('email_address'),
        reservationPhone: reservationDriverDetails.getIn(['phone', 'phone_number']),
        reservationLastName: reservationDriverDetails.get('last_name'),
        reservationFirstName: reservationDriverDetails.get('first_name'),
      }
    : null
);

export const airlineOptionsSelector = createSelector([getPickupLocationAirlineDetails], (airlineDetails) => {
  const airlines = utils.safeToJS(airlineDetails, []);
  return airlines?.map(({ description, code }) => ({ label: description, value: code }));
});
