import { gmaSessionPath, gboReservationPath } from './session';

// GMA
export const onlineCheckInProfilePath = [...gmaSessionPath, 'online_check_in_profile'];
export const profileFoundPath = [...gmaSessionPath, 'profile_found'];
export const onlineCheckInBasicProfilePath = [...onlineCheckInProfilePath, 'profile', 'basic_profile'];
export const onlineCheckInProfileLoyaltyDataPath = [...onlineCheckInBasicProfilePath, 'loyalty_data'];
export const onlineCheckInProfileStatusPath = [...onlineCheckInProfilePath, 'additional_data', 'oci_individual_status'];
export const onlineCheckInProfileOciEligiblePath = [...onlineCheckInProfilePath, 'additional_data', 'oci_eligible'];
export const onlineCheckInOriginPath = [...gmaSessionPath, 'online_check_in_origin'];

// GBO
export const onlineCheckInEligiblePath = [...gboReservationPath, 'online_check_in_eligible'];
