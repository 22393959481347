/**
 * Reservations Specific
 */
const EXCHANGE_TYPE_PICKUP = 'pickup';
const EXCHANGE_TYPE_RETURN = 'return';
const RESFLOW_PATHS_CONFIG = {
  datesTimes: [], // has no actual route
  location: ['/map_pickup', '/map_return'],
  vehicle: ['/car_select'],
  extras: ['/extras'],
  review: ['/review'],
  total: [], // has no actual route
  start: ['/start'],
};
const RESFLOW_DEEPLINK_STOPS = {
  location: 'book',
  vehicle: 'cars',
  extras: 'extras',
  review: 'commit',
};

export default {
  EXCHANGE_TYPE_PICKUP,
  EXCHANGE_TYPE_RETURN,
  EXCHANGE_TYPE_ACCESSOR_OBJECT: {
    [EXCHANGE_TYPE_RETURN]: 'dropoff_validity',
    [EXCHANGE_TYPE_PICKUP]: 'pickup_validity',
  },
  DEEP_LINK_CREATOR_PARAM: 'stop', //  indicates how far should GMA call actual GBO services, https://git-ext.ehi.com/projects/GMA/repos/global-marketing-interface/browse/lib/utils/app/reservations.js#258
  TRANSMISSION_FEATURE_CODES: {
    AUTOMATIC: '25',
    MANUAL: '26',
  },
  PREPAY_CODE: 'PREPAY',
  PAYLATER_CODE: 'PAYLATER',
  SERVICE_INDICATORS: {
    offersAfterHoursDropoff: true,
    afterHoursPickup: true,
    skipTheCounter: 'location_service_skip_counter',
    kiosk: 'location_service_kiosk',
    onlineCheckIn: 'location_service_online_checkin',
  },
  FEE_YOUNG_DRIVER: 'YOUNG RENTER FEE',
  EXTRAS_CHARGED_STATUS: 'CHARGED',
  EXTRAS_INCLUDED_STATUS: 'INCLUDED',
  EXTRAS_HIDDEN_STATUS: 'HIDDEN',
  EXTRAS_OPTIONAL_STATUS: 'OPTIONAL',
  EXTRAS_MANDATORY_STATUS: 'MANDATORY',
  EXTRAS_WAIVED_STATUS: 'WAIVED',
  EXTRAS_ADDITIONAL_STATUS: 'ADDITIONAL',
  TAXES_FEES_INCLUDED_STATUS: 'INCLUDED',
  ADDITIONAL_DRIVER_INCLUDED_STATUS: 'I', // gbo additional driver data uses I instead of INCLUDED like other extras
  ADDITIONAL_DRIVER_EXTRAS_CODE: 'ADDR',
  RES_INIT_LOCALSTORAGE: 'RES_INIT_LOCALSTORAGE',
  RESFLOW_PATHS_CONFIG,
  RESFLOW_DEEPLINK_STOPS,
  AIRLINE_NO_FLIGHT: 'WALK IN',
  AIRLINE_NO_AIRLINE: 'OTHER',
  RENTAL_STATE_CANCELLED: 'CANCELLED',
  RENTAL_STATE_OVER_DUE: 'OVER_DUE',
  TRAVEL_INDICATOR_FLIGHT: 'FLIGHT',
  POLICY_TYPES: {
    RENTER_REQUIREMENTS: 'Renter Requirements',
    GEOGRAPHIC_RESTRICTIONS: 'Geographic Restrictions',
    AFTER_HOURS_SERVICE: 'After Hours Service',
    FORMS_OF_PAYMENT: 'Forms of Payment',
    REFUELING_SERVICE: 'Refueling Service',
    PERSONAL_ACCIDENT_INSURANCE: 'Personal Accident Insurance',
    AGE_REQUIREMENTS: 'Age Requirements',
    ADDITIONAL_DRIVER: 'Additional Driver',
  },
  POLICY_CODES: {
    AFHR: 'AFHR',
  },
  POLICY_REQUIREMENTS: {
    MINIMUM: 'MINIMUM_REQUIREMENTS',
    ADDITIONAL: 'ADDITIONAL',
  },
  BILLING_TYPE_EXISTING: 'EXISTING',
  BILLING_TYPE_CUSTOM: 'CUSTOM',
  PAY_AT_COUNTER: 'PAY_AT_COUNTER',
  PREPAY_UI_STEPS: {
    EXISTING_PREPAY: 'existing_prepay',
    CONFIRM_PREPAY: 'confirm_prepay',
    CARD_FORM: 'card_form',
    CARD_ALERT: 'card_alert',
  },
  THIRD_PARTY_RES_CODES: {
    GBO_EXTERNAL_RESERVATIONS: 'GBO_EXTERNAL_RESERVATIONS',
    GBO_REM_EXTERNAL_RESERVATIONS: 'GBO_REM_EXTERNAL_RESERVATIONS',
    GBO_TOUR_OPERATOR_EXTERNAL_RESERVATION: 'GBO_TOUR_OPERATOR_EXTERNAL_RESERVATION',
    GBO_REM_TOUR_OPERATOR_EXTERNAL_RESERVATION: 'GBO_REM_TOUR_OPERATOR_EXTERNAL_RESERVATION',
    GBO_REM_NOT_TRAVEL_AGENT: 'GBO_REM_NOT_TRAVEL_AGENT',
  },
  RESERVATION_STATUS_CHECKED_IN: 'CHECKED_IN',
  // TODO: may need a separate timeout configs file, but for now this is our only timeout
  GMA_TIMEOUT: 1200, // Timeout constants are in seconds
  GMA_SESSION_TIMEOUT_INFORMATION: 300, // Timeout constants are in seconds

  // AGE SELECT
  AGE_SELECTOR_LABEL_VARIABLE_TO: '#{age_to}',
  AGE_SELECTOR_LABEL_VARIABLE_OR_OLDER: '#{age_or_older}',

  // IBM SUCCESS/FAILURE MESSAGE
  IBM_SUCCESS: 'success',
  IBM_FAILURE: 'failure',

  GUARANTEED_RES_MODIFY_REASON: 'GBO_REM_GUARANTEED_RES',
  GBO_REM_PICK_UP_TIME_PASSED: 'GBO_REM_PICK_UP_TIME_PASSED',

  // WNG PREPAY RES CODE
  GBO_REM_WNG_PREPAY: 'GBO_REM_WNG_PREPAY',

  // CANCEL FEE TYPES
  ADVANCE_FEE: 'ADVANCE_FEE',
  CANCEL_FEE: 'FEE',

  RESERVATION_SUB_STATUS: {
    CHECKED_IN: 'CHECKED_IN',
    COUNTER_BYPASS: 'COUNTER_BYPASS',
  },

  // RESERVATION SUBTYPES
  TOUR_RESERVATION: 'TOUR',
  LAST_MINUTE_SPECIALS: 'LMS',
  PLAN_AHEAD_SPECIALS: 'PLAN_AHEAD_SPECIAL',

  // EXTERNAL LINK CODE
  EXTERNAL_LINKS: {
    MODIFY_BLOCK: 'MODIFY_BLOCK',
    ONLINE_CHECK_IN: 'ONLINE_CHECK_IN',
    COUNTER_BYPASS: 'COUNTER_BYPASS',
  },

  // KEY FACTS POLICIES CONSTANTS
  KEY_FACTS_SECTION_KEY: 'key_facts_section',
  MINIMUM_REQUIREMENTS: 'MINIMUM_REQUIREMENTS',

  PAYMENT_TYPE: {
    VOUCHER: 'VOUCHER',
    CREDIT_CARD: 'CREDIT_CARD',
  },

  PRICE_DIFFERENCE_TYPE: {
    PREVIOUS_RESERVATION: 'PREVIOUS_RESERVATION',
    UNPAID_REFUND_AMOUNT: 'UNPAID_REFUND_AMOUNT',
  },

  PAYMENT_METHOD: {
    CASH: 'CSH',
    CARD: {
      AX: 'American Express',
      BC: 'Bankcard',
      EC: 'Eurocard',
      MC: 'Mastercard',
      VI: 'VISA',
      DS: 'Discover',
    },
  },

  DISTANCE_UNIT: {
    MILES: 'Miles',
  },

  LEVEL_OF_SERVICE: {
    QR: 'QR',
  },

  SITE_SECTION: {
    RESERVE: 'reserve',
  },

  PROFILE: {
    LOYALTY_PROGRAM: 'Non-Loyalty',
  },

  VEHICLES_INIT_LOCALSTORAGE: 'VEHICLES_INIT_LOCALSTORAGE',
  VEHICLES_SEARCH_LOCALSTORAGE: 'VEHICLES_SEARCH_LOCALSTORAGE',
  CURRENCY_TOGGLE_SELECTED: 'CURRENCY_TOGGLE_SELECTED',
  IN_RES_ACCELERATE_CHECK_IN_SAVE_TIME: 'IN_RES_ACCELERATE_CHECK_IN_SAVE_TIME',
  RESERVATION_SESSION_PATH: 'RESERVATION_SESSION_PATH',
  SELECTED_LANGUGAGE_SESSION_STORAGE: 'SELECTED_LANGUGAGE_SESSION_STORAGE',
  START: 'START',
  IS_RES_INITIATED_COUPON_CID: 'IS_RES_INITIATED_COUPON_CID',
  AUTO_COMMS_CONSENT_OPT_IN: 'auto_comms_consent_opt_in',

  THIRDPARTY_RESERVATION: 'THIRDPARTY_RESERVATION',

  EDIT_CID_GMA: 'EDIT_CID_GMA',

  PAYMENT: {
    BUSINESS_ACCOUNT_APPLICANT: 'BUSINESS_ACCOUNT_APPLICANT',
    VOUCHER: 'VOUCHER',
  },
};
