import { Map } from 'immutable';
import utils from 'utils';
import { VEHICLE_LIST } from 'actions/vehicleClass';
import { RESERVATIONS } from 'constants';

const sessionData = sessionStorage.getItem(RESERVATIONS.VEHICLES_SEARCH_LOCALSTORAGE);
const selectedCurrencyToggle = sessionStorage.getItem(RESERVATIONS.CURRENCY_TOGGLE_SELECTED);

const initialState = Map({
  filters: (sessionData && sessionData !== 'undefined' && JSON.parse(sessionData)) || {},
  vehiclesAvailability: {},
  currency_toggle: (selectedCurrencyToggle &&
    selectedCurrencyToggle !== 'undefined' &&
    JSON.parse(selectedCurrencyToggle)) || {
    tabId: utils.localStorage.getTabIdSession(),
    currency_code: 0,
  }, // O indicates default to select source currency
});

export const VEHICLE_LIST_BASE_PATH = ['app', 'vehicles_list'];
export const VEHICLE_FILTERS_PATH = [...VEHICLE_LIST_BASE_PATH, 'filters'];
export const VEHICLE_AVAILABILITY_PATH = [...VEHICLE_LIST_BASE_PATH, 'vehicles_availability'];
export const VEHICLE_SELECTED_CURRENCY_PATH = [...VEHICLE_LIST_BASE_PATH, 'currency_toggle'];

const ACTION_HANDLERS = {
  [VEHICLE_LIST.VEHICLE_CLASS_DATA]: (state, { data }) => state.setIn(VEHICLE_LIST_BASE_PATH, Map(data)),
  [VEHICLE_LIST.SET_VEHICLE_CLASS_FILTER_DATA]: (state, { data = {} }) => {
    sessionStorage.setItem(RESERVATIONS.VEHICLES_SEARCH_LOCALSTORAGE, JSON.stringify(data));
    return state.setIn(VEHICLE_FILTERS_PATH, data);
  },
  [VEHICLE_LIST.SET_VEHICLE_AVAILABILITY_DATA]: (state, { data }) => state.setIn(VEHICLE_AVAILABILITY_PATH, Map(data)),
  [VEHICLE_LIST.SET_SELECTED_VEHICLE_CURRENCY_TOGGLE_DATA]: (state, { data }) => {
    const currencyData = {
      tabId: utils.localStorage.getTabIdSession(),
      currency_code: data,
    };
    sessionStorage.setItem(RESERVATIONS.CURRENCY_TOGGLE_SELECTED, JSON.stringify(currencyData));
    return state.setIn(VEHICLE_SELECTED_CURRENCY_PATH, currencyData);
  },
  [VEHICLE_LIST.CLEAR_VEHICLE_CLASS_FILTER_DATA]: state => {
    sessionStorage.removeItem(RESERVATIONS.VEHICLES_SEARCH_LOCALSTORAGE);
    return state.setIn(VEHICLE_FILTERS_PATH, {});
  },
};

/**
 * Action handling for modal state updates
 * @param {Object} state An immutable state object to check against
 * @param {Object} action Data to update the redux store state
 * @returns {Object} A (usually changed) immutable state object
 */

const reducer = (state = initialState, action = null) => {
  const handler = action ? ACTION_HANDLERS[action.type] : null;
  return handler ? handler(state, action) : state;
};

export default {
  initialState,
  reducer,
};
