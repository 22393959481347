// NOTE: To avoid circular dependencies, store is not imported or exported by the util/index.js
import { initGmi } from '@ehi/global-marketing-interface';
import { initialReducers, initialState } from 'reducers';
import utils from 'utils';
import { analytics } from './middleware';

const GMI_CONFIG = utils.config.getGMIConfig();

// We augment the config to make sure the date/time is exactly what moment needs
// BE can and should update to reflect this, but for now we're just doing it here
const createGmiConfig = () => {
  const config = {
    ...GMI_CONFIG,
    DATE_TIME_FORMAT: {
      long_date_format: {},
      short_date_format: {},
      weeks: { ...GMI_CONFIG.DATE_TIME_FORMAT.weeks },
    },
  };
  delete config.DATE_TIME_FORMAT.long_date_format; // these will get re-added with proper naming/casing below
  delete config.DATE_TIME_FORMAT.short_date_format; // these are custom and don't need to get passed to GMI
  config.DATE_TIME_FORMAT.longDateFormat = Object.keys(GMI_CONFIG.DATE_TIME_FORMAT.long_date_format || {}).reduce(
    (acc, curr) => {
      acc[curr.toUpperCase()] = GMI_CONFIG.DATE_TIME_FORMAT.long_date_format[curr];
      return acc;
    },
    {}
  );

  config.DATE_TIME_FORMAT = {
    ...config.DATE_TIME_FORMAT,
    ...utils.localization.getMonthsDays(),
  };
  return config;
};

// Build Store / Setup GMI for future use
// Inject our custom app-level reducers and initial state
const store = initGmi(
  createGmiConfig(),
  {
    initialReducers,
    initialState,
    middleware: [analytics],
  },
  {}
);

export default store;
