import { createSelector } from 'reselect';
import utils from 'utils';
import RESERVATIONS from 'constants/reservations';
import { pickupLocationCurrencyCodePath } from 'paths/reservationLocation';
import { getSelectedCurrencyToggle, getSortFilterValue } from 'selectors/uiStateSelectors';

// selector to get destination currency
export const getDestinationCurrencyCode = state => state.getIn(pickupLocationCurrencyCodePath);

// Function to update VehicleExtrasSelector on which currency is selected
export const updateVehicleExtrasSelector = extras => {
  const modifyVehicleExtraRates = extras;

  if (modifyVehicleExtraRates && Object.keys(modifyVehicleExtraRates).length > 0) {
    Object.keys(modifyVehicleExtraRates).forEach(key => {
      modifyVehicleExtraRates[key] = modifyVehicleExtraRates[key]?.map(item => ({
        ...item,
        rate_amount_view: item?.rate_amount_payment,
        max_amount_view: item?.max_amount_payment,
        total_amount_view: item?.total_amount_payment,
      }));
    });
    return modifyVehicleExtraRates;
  }
  return modifyVehicleExtraRates;
};

// Function to update VehiclePriceSelector on which currency is selected
export const updateVehiclePriceSelector = priceSummary => {
  const modifyVehiclePriceSummary = priceSummary;
  if (modifyVehiclePriceSummary && Object.keys(modifyVehiclePriceSummary).length > 0) {
    const { payment_line_items } = modifyVehiclePriceSummary;
    Object.keys(modifyVehiclePriceSummary).forEach(key => {
      if (key !== 'payment_line_items' && key.endsWith('_view')) {
        modifyVehiclePriceSummary[key] = modifyVehiclePriceSummary[key.split('_view').join('_payment')];
      }
      if (key === 'payment_line_items') {
        Object.keys(payment_line_items).forEach(paykey => {
          payment_line_items[paykey] = payment_line_items?.[paykey]?.map(item => ({
            ...item,
            rate_amount_view: item.rate_amount_payment,
            total_amount_view: item.total_amount_payment,
          }));
        });
      }
    });
    return modifyVehiclePriceSummary;
  }
  return modifyVehiclePriceSummary;
};

// Function to update AdditionalDriverSelectors on which currency is selected

export const updateAdditionalDriverSelectors = additionalDrivers => {
  const modifyAdditionalDriverRates = additionalDrivers;
  if (modifyAdditionalDriverRates && Object.keys(modifyAdditionalDriverRates).length > 0) {
    modifyAdditionalDriverRates.rate_amount_view = modifyAdditionalDriverRates?.rate_amount_payment;
    modifyAdditionalDriverRates.total_amount_view = modifyAdditionalDriverRates?.total_amount_payment;
  }
  return modifyAdditionalDriverRates;
};

// Function to update vehicle rates selectors on which currency is selected
export const updateVehicleRatesSelectors = vehicleRates => {
  const modifyVehicleRates = vehicleRates;
  if (modifyVehicleRates && Object.keys(modifyVehicleRates).length > 0) {
    const { extras, price_summary, supplemental = {} } = modifyVehicleRates;
    modifyVehicleRates.extras = updateVehicleExtrasSelector(extras);
    modifyVehicleRates.price_summary = updateVehiclePriceSelector(price_summary);
    if (Object.keys(supplemental).length > 0) {
      const {
        supplemental: { additional_driver },
      } = modifyVehicleRates;
      supplemental.additional_driver = updateAdditionalDriverSelectors(additional_driver);
    }
  }
  return modifyVehicleRates;
};

export const getSourceAndDestinationCurrencyValues = createSelector(
  [getSortFilterValue],
  vehicles => {
    if (vehicles?.length) {
      const charges = vehicles[0]?.charges;
      const destinationCurrency = charges?.PAYLATER?.total_price_payment || charges?.PREPAY?.total_price_payment;
      const sourceCurrency = charges?.PAYLATER?.total_price_view || charges?.PREPAY?.total_price_view;
      return {
        destinationCurrency,
        sourceCurrency,
      };
    }
    return null;
  }
);

// Function to get currency toggle details
export const getCurrencyToggleDetails = createSelector(
  [getSourceAndDestinationCurrencyValues, getDestinationCurrencyCode, getSelectedCurrencyToggle],
  (sourceAndDestinationCurrencyValues, destinationCurrencyCode, selectedCurrencyToggle) => {
    if (sourceAndDestinationCurrencyValues) {
      const { destinationCurrency, sourceCurrency } = sourceAndDestinationCurrencyValues;
      const labels = {
        [sourceCurrency.code]: sourceCurrency.code,
        [destinationCurrency.code]: destinationCurrency.code,
      };

      // delete if source/destination currency doesn't matches with the session currency

      if (![sourceCurrency.code, destinationCurrency.code].includes(selectedCurrencyToggle?.currency_code)) {
        sessionStorage.removeItem(RESERVATIONS.CURRENCY_TOGGLE_SELECTED);
      }
      const storeSelectedCurrencyInSession =
        selectedCurrencyToggle?.currency_code === 0 ||
        ![sourceCurrency.code, destinationCurrency.code].includes(selectedCurrencyToggle?.currency_code)
          ? sourceCurrency.code
          : selectedCurrencyToggle?.currency_code;

      const currencyData = {
        tabId: utils.localStorage.getTabIdSession(),
        currency_code: storeSelectedCurrencyInSession === 0 ? sourceCurrency.code : storeSelectedCurrencyInSession,
      };
      sessionStorage.setItem(RESERVATIONS.CURRENCY_TOGGLE_SELECTED, JSON.stringify(currencyData));

      return {
        currencyToggleLabels: labels,
        showCurrencyToggleUI:
          utils.config.getIsEnableCurrencyToggle() && sourceCurrency?.code !== destinationCurrency?.code,
        destinationCurrency: destinationCurrencyCode,
        sourceCurrency: sourceCurrency.code,
        isPayTypeSelected:
          selectedCurrencyToggle?.currency_code === 0 ? sourceCurrency.code : selectedCurrencyToggle?.currency_code,
        convertedCurrency: sourceCurrency.code !== destinationCurrency.code,
      };
    }
    return {};
  }
);
