/**
 * Keyboard keys constants - keep in numeric order
 * @module
 * @author geraldo.neto@isobar.com
 */

const KEYS = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  SPACE: 32,
  END: 35,
  HOME: 36,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  DELETE: 46,
  NUMBER_0: 48,
  NUMBER_9: 57,
  NUMPAD_0: 96,
  NUMPAD_9: 105,
  UNIDENTIFIED: 229,
  SHIFT: 16,
};

export const SPECIAL_KEYS = [
  KEYS.ARROW_DOWN,
  KEYS.ARROW_LEFT,
  KEYS.ARROW_UP,
  KEYS.ARROW_RIGHT,
  KEYS.BACKSPACE,
  KEYS.DELETE,
  KEYS.ENTER,
  KEYS.TAB,
];

export default KEYS;
