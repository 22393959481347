import { actions, services } from '@ehi/global-marketing-interface';
import utils from 'utils';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import { setReservationSessionState } from "actions/reservation/setReservationSessionState";
import { gmiRequest } from './request';

let cancelFetchCurrentSession = null;
export const fetchCurrentSession =
  (opts = { clean: false, enteredThroughSpa: false }) =>
  (dispatch) => {
    if (cancelFetchCurrentSession !== null) {
      utils.safeExecute(cancelFetchCurrentSession);
    }
    return dispatch(
      gmiRequest({
        action: () =>
          dispatch(
            actions.request({
              data: {},
              opts: {
                cancelFn: (c) => {
                  cancelFetchCurrentSession = c;
                },
                clearStateOnRequest: false,
              },
              service: services.gma.session.current,
            })
          ),
        timeout: opts.enteredThroughSpa,
      })
    ).then((res) => {
      cancelFetchCurrentSession = null;
      const initiateRequestSessionObject = res?.gma?.initiate_request;
      utils.phoneInput.selectedCountryDetails.remove();
      // check for res?.gma?.initiate_request , if present store values in redux state.
      if (initiateRequestSessionObject) {
        dispatch(setReservationSessionState());
      }

      //  If we have no session in redux while going through spa AND not at the start of rez flow, redirect to start of rez flow
      if (
        opts.enteredThroughSpa &&
        !utils.dom.isAtStartOfStandAloneBookingWidgetPage() &&
        !initiateRequestSessionObject
      ) {
        location.replace(utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.RESERVATION_INFLOW));
      }
    });
  };
