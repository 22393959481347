import ANALYTICS from "constants/analytics";

const { ACTION_CLOSE_MODAL } = ANALYTICS;

/**
 * @name closeModal
 * @desc Closes currently opened modal
 * @param {Object} args - This is an object of aditional "arguments" that are intended to be passed down
 *                        to the action object. The main use for this is analytics properties that _won't_
 *                        be used by the reducer, but rather by a middleware. These values are spread into
 *                        the returned action object.
 * @returns {{type: string}}
 * @memberof actions.modal
 * @example
 * dispatch(closeModal());
 */
export function closeModal(args) {
    return { ...args, type: ACTION_CLOSE_MODAL };
}