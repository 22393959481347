import ANALYTICS from "constants/analytics";

const { ACTION_OPEN_MODAL } = ANALYTICS;

/**
 * @name openModalWithProps
 * @desc opens regular modals but passes them props
 * @param {String} modalKey - this string represents the name/id of the current modal being opened.
 * @param {Object} props - properties passed down through the action creator to the action object.
 * @param {Object} args - This is an object of aditional "arguments" that are intended to be passed down
 *                        to the action object. The main use for this is analytics properties that _won't_
 *                        be used by the reducer, but rather by a middleware. These values are spread into
 *                        the returned action object.
 * @returns {{type: string}}
 * @memberof actions.modal
 * @example
 * dispatch(openModalWithProps(key, props));
 */
export function openModalWithProps(modalKey, props, args) {
    return { ...args, modalKey, props, type: ACTION_OPEN_MODAL };
}