const REQUEST = {
  MESSAGES: {
    CODES: {
      WRONG_FORMAT_EMAIL_ADDRESS: 'RNTR00304', // E-mail address format is wrong
      WRONG_FORMAT_EMAIL_ADDRESS_DVRPR_CODE: 'DVRPR10056', // E-mail address format is wrong
      DUPLICATE_EMAIL_ADDRESS: 'RNTR72003', // E-mail address is duplicate
      DUPLICATE_EMAIL_ADDRESS_DVRPR_CODE: 'DVRPR11082', // E-mail address is duplicate,
      GBO_PHONE_INPUT_INVALID: "GBO_PHONE_INPUT_INVALID" // Gbo Invalid phone number
    },
  },
};

export default REQUEST;
