/**
 * Reservation Look-up Origin
 */
const LOOK_UP_TYPE = {
  CHECKIN_LOOK_UP: 'CHECKIN_LOOK_UP',
  STC_LOOK_UP: 'STC_LOOK_UP',
  CUSTOM_PATH_LOOK_UP: 'CUSTOM_PATH_LOOK_UP',
  COUNTRY_SELECTED: 'COUNTRY_SELECTED',
};

export default {
  LOOK_UP_TYPE,
};
