/**
 * Customer Support related constants
 */
export default {
  PHONE_TYPE: {
    ALAMO_INSIDER: 'ALAMO_INSIDER',
    THIRDPARTY_RESERVATION: 'THIRDPARTY_RESERVATION',
    CONTACT_US: 'CONTACT_US',
    ROADSIDE_ASSISTANCE: 'ROADSIDE_ASSISTANCE',
    UNSUPPORTED_FORMATTING_MASK: 'UNSUPPORTED',
    GBO_DIGIT_REGEX_REPLACE_FROM: /#/g, // GBO formatting digits are #
    GBO_DIGIT_REPLACE_TO: '9', // VMasker understand 9 as digits
    GBO_MAX_PHONE_DIGITS_ALLOWED: 14,
  },
};
