const GMI = 'gmi';

const SESSION = [GMI, 'session'];
const SESSION_ANALYTICS = [...SESSION, 'analytics'];

/**
 * State Cursors
 */
export default {
  /**
   * Solr Cursors
   */
  STANDARD: 'STANDARD',

  // Session
  SESSION,
  SESSION_ANALYTICS,

  /**
   * Solr Location Hours By Id
   * @param {String} id
   * @returns {Array}
   */
  locationHours: id => [GMI, 'solr', 'hours', id, 'data'],
  DOMAIN: ['domain'],
};
