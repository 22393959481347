/**
 * Object helpers
 * @module
 */

import { utils as gmiUtils } from '@ehi/global-marketing-interface';

/**
 * Updating existing Object Values by trimming whitespace from string
 * @param {Object} obj - object with sting values to trim
 * @returns {Object}
 */
export const trimSpaceFromStringValueOfObject = (obj) => {
  const modifiedObj = { ...obj };
  if (gmiUtils.types(modifiedObj).isObject) {
    Object.entries(modifiedObj).forEach(([key, val]) => {
      modifiedObj[key] = val.trim();
    });
  }

  return modifiedObj;
};

/**
 * @private
 * @param path
 * @returns {Array|*}
 * @private
 * This is from GMI
 */
function stringToPath(path) {
  const containsBracketNotation = /\[[0-9]+\]/g;
  if (path.match(containsBracketNotation)) {
    // eslint-disable-next-line no-param-reassign
    path = path.replace(containsBracketNotation, (s) => `.${s.substring(1, s.length - 1)}`);
  }
  return path.split('.');
}

/**
 * @name get
 * @desc Gets the value at path of the object. Returns undefined if unresolved.
 * @param {Object} source
 * @param {String} path eg: path.path1.path2.key, omit the root object in path
 * @returns {*} Value at path, or `undefined` if no value is found
 * This is from GMI
 * @example
 * get(phones, 'phones[0].phone_number')
 * get(contact_info, 'contact_info.phone_set[0].phone_number')
 * get(driver_info, 'phone_number')
 */
export function get(source, path) {
  // eslint-disable-next-line no-param-reassign
  path = stringToPath(path);
  const { length } = path;
  let index = 0;
  // eslint-disable-next-line
  while (source !== void 0 && source !== null && index < length) {
    // eslint-disable-next-line no-param-reassign
    source = source[path[index]];
    index += 1;
  }
  // eslint-disable-next-line
  return index < length ? void 0 : source;
}

/**
 * isObject - Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * mergeDeep - Deep merge two objects.
 * @param target
 * @param sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    });
  }

  return mergeDeep(target, ...sources);
}

/**
 * transformKeyPathIntoObjectStructure
 * @param keyPath - receives a keyPath string
 * @param value - receives a value for the final object
 */
export const transformKeyPathIntoObjectStructure = (keyPath, value) => {
  const parts = keyPath.split('.');
  const last = parts[parts.length - 1];
  const tree = {};

  // eslint-disable-next-line arrow-body-style
  const node = parts.slice(0, -1).reduce((memo, current) => {
    // eslint-disable-next-line no-return-assign
    return (memo[current] = { ...memo[current] });
  }, tree);

  node[last] = value;
  return tree;
};

/**
 * reordering the arrya of object using specific order
 * @param code - comparing with code value with sort
 */

export const preferredOrder = (obj, sortList) => {
  const sortedObj = obj.sort((a, b) => sortList.indexOf(a.code) - sortList.indexOf(b.code));
  return sortedObj;
};

/**
 * returns the specific key from of the object based on the value provided
 * @param code - comparing with object key with value
 */

export const getKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value);

/**
 * returns the boolean value if object contains key property
 */
export const objectHasOwnProperty = (object, key) => Object.hasOwn(object, key);
