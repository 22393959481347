import { Map } from 'immutable';
import { gmaInitiateContractDetailsPath } from 'paths/reservationContract';
import RESERVATIONS from 'constants/reservations';

const initialState = Map({});

const ACTION_HANDLERS = {
  [RESERVATIONS.EDIT_CID_GMA]: (state, { data }) => state.setIn([...gmaInitiateContractDetailsPath], data),
};
const reducer = (state = initialState, action = null) => {
  const handler = action ? ACTION_HANDLERS[action.type] : null;
  return handler ? handler(state, action) : state;
};

export default {
  initialState,
  reducer,
};
