import { actions } from '@ehi/global-marketing-interface';
import utils from 'utils';
import { addSoldOutAreaInfo } from 'utils/analytics';
import { LOCATIONS, MODAL } from 'constants';
import RESERVATIONS from 'constants/reservations';
import { appLocationFinderPath } from 'paths/app';
import { fetchVehiclesAvailability } from 'actions/vehicles';
import { setVehiclesAvailabilityData } from 'actions/vehicleClass';
import {
  getUiStateShowPartnersLocations,
  getUiInitiateState,
  currentFiltersSelector,
  numFiltersSelectedFilterSelector,
} from 'selectors/uiStateSelectors';
import { hasCouponOrProductCodeSelector } from 'selectors/reservationSelectors';
import { locationFinderAlamoResultSelector, getSoldOutArea } from 'selectors/appSelectors';
import { isLocationWithNoVehiclesAvailableSelector } from 'selectors/errorSelectors';
import { getLocationFilters } from 'selectors/locationSelectSelectors';
import { setFiltersByValue } from "actions/filter/setFiltersByValue";
import { openModalWithProps } from "actions/modal/openModalWithProps";
import { locationsSpatialQuery } from './locationSearch';

export const LOCATION_FINDER = {
  SET_LOCATION_DATA: '@@locationFinder/SET_LOCATION_DATA',
  UNSET_LOCATION_DATA: '@@locationFinder/UNSET_LOCATION_DATA',
  SET_LOCATION: '@@locationFinder/SET_LOCATION',
  UNSET_LOCATION: '@@locationFinder/UNSET_LOCATION',
  SET_LOCATION_DATA_UNAVAILABLE_LOCATION_MODAL: '@@locationFinder/SET_LOCATION_DATA_UNAVAILABLE_LOCATION_MODAL',
  UNSET_LOCATION_DATA_UNAVAILABLE_LOCATION_MODAL: '@@locationFinder/UNSET_LOCATION_DATA_UNAVAILABLE_LOCATION_MODAL',
  SHOW_PARTNER_RESULTS: '@@locationFinder/SHOW_PARTNER_RESULTS',
  SET_LOCATION_RESULT: '@@locationFinder/SET_LOCATION_RESULT',
  SET_CURRENT_TIMESTAMP: '@@locationFinder/SET_CURRENT_TIMESTAMP',
  SHOW_SOLD_OUT_NEARBY_LOCATIONS: '@@locationFinder/SHOW_SOLD_OUT_NEARBY_LOCATIONS',
  SET_LAST_VALID_LOCATION_SEARCHED: '@@locationFinder/SET_LAST_VALID_LOCATION_SEARCHED',
  SET_ADJUST_LOCATION_ID: '@@locationFinder/SET_ADJUST_LOCATION_ID',
  SET_UPDATED_SOLD_OUT_DATE_TIME: '@@locationFinder/SET_UPDATED_SOLD_OUT_DATE_TIME',
  SET_SOLD_OUT_AREA: '@@locationFinder/SET_SOLD_OUT_AREA',
  SET_SKIP_ANALYTICS_FOR_LOCATION_TYPE_FILTER: '@@locationFinder/SET_SKIP_ANALYTICS_FOR_LOCATION_TYPE_FILTER',
};

/**
 * @name setLocationDataUnavailableLocationModal
 * @desc store for Unavailable Location Modal, sets it
 * @param
 * @returns {{type: string, data: Object}}
 * @memberof actions.locationFinder
 * @example
 * dispatch(setLocationDataUnavailableLocationModal())
 */
export const setLocationDataUnavailableLocationModal = (data) => ({
  type: LOCATION_FINDER.SET_LOCATION_DATA_UNAVAILABLE_LOCATION_MODAL,
  data,
});

/**
 * @name setLocationDataUnavailableLocationModal
 * @desc store for Unavailable Location Modal, unsets it
 * @param
 * @returns {{type: string, data: Object}}
 * @memberof actions.locationFinder
 * @example
 * dispatch(unSetLocationDataUnavailableLocationModal())
 */
export const unSetLocationDataUnavailableLocationModal = (data) => ({
  type: LOCATION_FINDER.UNSET_LOCATION_DATA_UNAVAILABLE_LOCATION_MODAL,
  data,
});

/**
 * @name setLocationData
 * @desc store the received location data into the redux store
 * @param
 * @returns {{type: string, data: Object}}
 * @memberof actions.locationFinder
 * @example
 * dispatch(setLocationData())
 */
export const setLocationData = (data) => ({ type: LOCATION_FINDER.SET_LOCATION_DATA, data });

/**
 * @name setLocationResult
 * @desc store the received location result into the redux store
 * @param
 * @returns {{type: string, data: Object}}
 * @memberof actions.locationFinder
 * @example
 * dispatch(setLocationResult())
 */
export const setLocationResult = (data) => ({ type: LOCATION_FINDER.SET_LOCATION_RESULT, data });

/**
 * @name unsetLocationData
 * @desc clear the location data from the store and set it back to initialState
 * @param
 * @returns {{type: string}}
 * @memberof actions.locationFinder
 * @example
 * dispatch(unsetLocationData())
 */
export const unsetLocationData = () => ({ type: LOCATION_FINDER.UNSET_LOCATION_DATA });

/**
 * @name setLocation
 * @desc set location used for search center
 * @param {Object} location
 * @returns {{type: string}}
 * @memberof actions.locationFinder
 * @example
 * dispatch(setLocation({name:'Chicago, IL', gps:{lat:0,lng:0}}))
 */
export const setLocation = (location) => ({ type: LOCATION_FINDER.SET_LOCATION, location });

/**
 * @name unsetLocation
 * @desc unset location used for search center
 * @param
 * @returns {{type: string}}
 * @memberof actions.locationFinder
 * @example
 * dispatch(unsetLocation())
 */
export const unsetLocation = () => ({ type: LOCATION_FINDER.UNSET_LOCATION });

/**
 * @name setCurrentTimestamp
 * @desc store the current timestamp used on google maps
 *
 * @memberof actions.locationFinder
 * @returns {{type: string}}
 * @example
 * dispatch(setCurrentTimestamp())
 */
export const setCurrentTimestamp = () => ({ type: LOCATION_FINDER.SET_CURRENT_TIMESTAMP });

export const showPartnerResults = (bool = true) => ({ type: LOCATION_FINDER.SHOW_PARTNER_RESULTS, bool });

/**
 * This is for the scenarios where searched location is sold out and we want to keep that location on the progress bar
 * instead of defaulting back to the GBO valid location
 */
export const setLastValidLocationSearched = (id) => ({ type: LOCATION_FINDER.SET_LAST_VALID_LOCATION_SEARCHED, id });

export const showSoldOutNearbyLocations = (bool) => ({ type: LOCATION_FINDER.SHOW_SOLD_OUT_NEARBY_LOCATIONS, bool });

export const setAdjustLocationId = (id) => ({ type: LOCATION_FINDER.SET_ADJUST_LOCATION_ID, id });

export const setUpdatedSoldOutDateTime = (bool) => ({ type: LOCATION_FINDER.SET_UPDATED_SOLD_OUT_DATE_TIME, bool });

const setSoldOutArea = (bool) => ({ type: LOCATION_FINDER.SET_SOLD_OUT_AREA, bool });

export const composeFilters = (storeKey, isResFlow) => (dispatch, getState) => {
  const state = getState();
  const { brands_in_result, result, showingPartnerLocations } = state.getIn(appLocationFinderPath).toJS();
  const locationFilters = getLocationFilters(state, isResFlow);
  const numFiltersSelected = numFiltersSelectedFilterSelector(state);
  const soldOutArea = getSoldOutArea(state);

  const crossSellShowPartnersLocations = getUiStateShowPartnersLocations(state);
  const hasAlamoLocations = brands_in_result.includes(LOCATIONS.BRAND_ALAMO);
  let items;

  if (showingPartnerLocations || !hasAlamoLocations) {
    items = result;
  } else if (hasAlamoLocations && crossSellShowPartnersLocations) {
    /**
     * When we are displaying partner locations for cross-sell, users should only
     * see Alamo and Enterprise locations. We also need to filter out co-located brands
     * so only the Alamo location gets displayed if it has a partner in the same place.
     */
    const removeUnwantedLocations = ({ brand, additional_data }) =>
      brand !== LOCATIONS.BRAND_NATIONAL && additional_data?.co_located_brand !== LOCATIONS.BRAND_ALAMO;
    items = result.filter(removeUnwantedLocations);
  } else {
    items = locationFinderAlamoResultSelector(state);
  }

  // If current location is sold out, and we have true availability filter we should select that by default
  const isLocationWithNoVehiclesAvailable = isLocationWithNoVehiclesAvailableSelector(state);

  // clear current filters
  numFiltersSelected > 0 && dispatch(actions.setFiltersUiByKey(storeKey, []));

  dispatch(
    actions.composeFiltersUiShape({
      items,
      storeKey,
      filters: locationFilters,
      labelFn: (value) => utils.i18n(`location_finder_filter_label_${value}`) || value,
    })
  );

  if (
    (isLocationWithNoVehiclesAvailable || JSON.parse(sessionStorage.getItem('soldOutLocation'))) &&
    utils.config.getIsSoldOutTrueAvailability() &&
    !soldOutArea
  ) {
    const currentFilters = currentFiltersSelector(state, storeKey);

    // check if AVAILABLE_LOCATIONS isn't already checked
    if (!currentFilters.filter(({ keyPath }) => keyPath === 'location_availability')[0]?.values) {
      dispatch(
        setFiltersByValue({
          storeKey,
          currentFilters,
          value: 'AVAILABLE_LOCATIONS',
          checked: 'AVAILABLE_LOCATIONS',
          keyPath: 'location_availability',
        })
      );
    } else {
      dispatch(actions.setFiltersUiByKey(storeKey, currentFilters));
    }
  }
};

export const getLocationsAvailability = (data, storeKey, isResFlow) => (dispatch) => {
  const { brands_in_result, result } = data;
  const hasAlamoLocations = brands_in_result?.includes(LOCATIONS.BRAND_ALAMO);

  if (!hasAlamoLocations) {
    addSoldOutAreaInfo(true);
    dispatch(setLocationResult(result));
    dispatch(composeFilters(storeKey, isResFlow));
    dispatch(setCurrentTimestamp());
    return;
  }

  const alamoLocations = result.filter(({ brand }) => brand === LOCATIONS.BRAND_ALAMO);

  // Get alamo locations, call availability service and merge available info with alamo locations
  // then merge it back to results

  dispatch(fetchVehiclesAvailability(alamoLocations)).then((response) => {
    if (!response.car_class_availability) {
      dispatch(setLocationResult(result));
      dispatch(composeFilters(storeKey, isResFlow));
      dispatch(setCurrentTimestamp());
      return;
    }
    dispatch(setVehiclesAvailabilityData(response));

    const dataWithAvailability = result.map((location, idx) => {
      if (location.brand === LOCATIONS.BRAND_ALAMO) {
        const isSoldOut = response?.car_class_availability[idx]?.no_vehicles_available;
        return {
          ...location,
          location_availability: isSoldOut ? LOCATIONS.UNAVAILABLE_LOCATIONS : LOCATIONS.AVAILABLE_LOCATIONS,
        };
      }
      return {
        ...location,
        location_availability: LOCATIONS.AVAILABLE_LOCATIONS,
      };
    });
    const isSoldOutArea = response.sold_out_area;
    addSoldOutAreaInfo(isSoldOutArea);
    dispatch(setSoldOutArea(isSoldOutArea));
    dispatch(setLocationResult(dataWithAvailability));
    dispatch(composeFilters(storeKey, isResFlow));
    dispatch(setCurrentTimestamp());
  });
};

export const fetchLocations =
  (gps, radius = 36, storeKey, pickupDate, pickupTime, dropoffDate, dropoffTime, isResFlow, postalCode) =>
  (dispatch) => {
    const { latitude, longitude } = gps;
    return dispatch(
      locationsSpatialQuery({
        latitude,
        longitude,
        includePartnerBranches: true,
        radius,
        pickupDate,
        pickupTime,
        dropoffDate,
        dropoffTime,
        zipcodeToSort: postalCode,
      })
    ).then((data) => {
      const { brands_in_result, radius_used_in_kilometers, result } = data;

      const currentStepHash = utils.url.getCurrentPageHash();
      const { location } = RESERVATIONS.RESFLOW_PATHS_CONFIG;

      dispatch(setLocationData({ brands_in_result, radius_used_in_kilometers }));
      // should call vailability if the flag is enabled, is in res flow and in map_pickup
      if (
        (utils.config.getIsSoldOutTrueAvailability() && isResFlow && currentStepHash === location[0]) ||
        currentStepHash === location[1]
      ) {
        dispatch(getLocationsAvailability(data, storeKey, isResFlow));
      } else {
        dispatch(setLocationResult(result));
        dispatch(composeFilters(storeKey, isResFlow));
        dispatch(setCurrentTimestamp());
      }
      dispatch(showPartnerResults(false)); // resets to show only alamo results
      dispatch(showSoldOutNearbyLocations(false)); // reset flag
      return data;
    });
  };

/**
 * @name fetchTimesForUnavailableLocationModal
 * @desc Does a spatial query, with the times included so we get a gmi message with the
 * location/time/date are valid
 * @param
 * @returns {{type: object}}
 * @memberof actions.locationFinder
 * @example
 * dispatch(fetchTimesForUnavailableLocationModal())
 */

export const fetchTimesForUnavailableLocationModal =
  (gps, pickupDate, pickupTime, dropoffDate, dropoffTime, location) => (dispatch) => {
    const { latitude, longitude } = gps;
    return dispatch(
      locationsSpatialQuery({
        latitude,
        longitude,
        includePartnerBranches: false,
        radius: 1,
        pickupDate,
        pickupTime,
        dropoffDate,
        dropoffTime,
      })
    ).then((data) => {
      const { result, cancelled } = data;
      if (!cancelled) {
        const currentStore = result.find((store) => store.id === location.id);
        dispatch(
          setLocationDataUnavailableLocationModal({
            location: currentStore,
            pickupDate,
            pickupTime,
            dropoffDate,
            dropoffTime,
          })
        );
      }
      return data;
    });
  };

/**
 * Deeplinks the user into the partner website with reservation details
 *
 * @param {object} config - Needed configuration we need to use in order to cross-sell accordingly
 * @param {string} config.location - The selected location
 * @param {boolean} config.goToBookPage - Should users be taken to the `book` page?
 */
export const deeplinkToPartnerLocation =
  ({ location = {}, goToBookPage = false }) =>
  (dispatch, getState) => {
    const state = getState();
    const stop = goToBookPage ? 'book' : '';

    const initiate = {
      ...(getUiInitiateState(state) || {}),

      // Builds an initiate object using the partner location as a reference
      // in order to be able to cross-sell to the correct location.
      pickup_location: location,
      return_location: location,
      pickup_location_id: location?.id,
      return_location_id: location?.id,
    };

    // Enterprise doesn't have `coupons` or `product_code` so they deal with coupons in deeplink as if they were contracts.
    // For that reason, we should delete our coupons before deeplinking to them.
    delete initiate.coupons;
    delete initiate.product_code;

    // Enterprise has a specific canadian english locale (`en_CA`) while Alamo uses the same
    // `en_US` one. For that reason, we should manually set the locale for this scenario.
    const isCanadianEnglish = utils.config.isCanadianDomain() && utils.config.getLanguage() === 'en';
    const isEnterpriseLocation = location?.brand === LOCATIONS.BRAND_ENTERPRISE;

    const deepLinkQs = utils.gmi.getReservationDeepLinkQs(initiate, stop);
    const alamoTrackingCode = 'cm_mmc=AlamoWebsite-_-cross.sell';

    const crossBrandConfig = {
      brand: location?.brand?.toLowerCase(),
      qs: deepLinkQs === '' ? `?${alamoTrackingCode}` : `${deepLinkQs}&${alamoTrackingCode}`,
      ...(isEnterpriseLocation && isCanadianEnglish && { locale: 'en_CA' }),
    };

    return dispatch(actions.crossBrandDeepLink(crossBrandConfig));
  };

/**
 * Either deeplinks the user into a partner website or display the
 * modal indicating the coupons and product code will be lost.
 *
 * @param {object} config - Needed configuration we need to use in order to cross-sell accordingly
 * @param {string} config.location - The selected partner location
 * @param {boolean} config.goToBookPage - Should users be taken to the `book` page?
 */
export const redirectOrDisplayBookWitPartnerModal = (config) => (dispatch, getState) => {
  const state = getState();
  const hasCouponOrProductCode = hasCouponOrProductCodeSelector(state);

  /**
   * If user has a coupon or product code in place, we need to display
   * a modal modal letting them know that this information will be lost.
   */
  hasCouponOrProductCode
    ? dispatch(openModalWithProps(MODAL.BOOK_WITH_PARTNER, config))
    : dispatch(deeplinkToPartnerLocation(config));
};

/* skip analytics for location type filter modal */
export const skipAnalyticsForLocationTypeFilter = (bool) => ({
  type: LOCATION_FINDER.SET_SKIP_ANALYTICS_FOR_LOCATION_TYPE_FILTER,
  bool,
});
