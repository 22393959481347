import { config } from 'utils/config';
import LOCATIONS from 'constants/locations';
import { getOneWayGmaRentalFlag } from 'selectors/reservationSelectors';

import { uiInitiateLocationsSelector } from 'selectors/uiStateSelectors';

// Returns if we need to show the Location Available Filter based on the AEM flag
// And it's a map pickup when return location type !== city
export const showLocationAvailableFilter = (returnLocationType, isOneWay, isResFlow) =>
  config.getIsSoldOutTrueAvailability() && (isOneWay ? returnLocationType !== LOCATIONS.TYPE_CITY : true) && isResFlow;

const filters = [
  {
    category: 'location_finder_filter_label_location_type', // this is an i18n content key
    keyPath: 'location_type',
    type: 'checkbox',
  },
];

export const getLocationFilters = (state, reservationFlow) => {
  const isOneWay = getOneWayGmaRentalFlag(state);
  const { uiReturnLocation } = uiInitiateLocationsSelector(state);

  return showLocationAvailableFilter(uiReturnLocation.type, isOneWay, reservationFlow)
    ? [
        ...filters,
        {
          keyPath: 'location_availability',
          type: 'checkbox',
        },
      ]
    : filters;
};
