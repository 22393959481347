/**
 * Breakpoint management; used to update the redux store with the current window breakpoint
 * @module
 * @author brian.fegan@isobar.com
 */

import { combineReducers } from '@ehi/global-marketing-interface/redux';
import resizeReducer from 'reducers/resize';

let initialized = false;
let delay;
let store;
let breakpoint;

/**
 * Save the updated breakpoint and fire a custom breakpointChange event
 * @param {String} newBreakpoint
 */
export function doBreakpointChange(newBreakpoint) {
  breakpoint = newBreakpoint;
  store.dispatch({
    type: 'BREAKPOINT_CHANGE',
    breakpoint,
  });
}

/**
 * Get the current breakpoint based on the body:after content value.
 * @returns {String}
 */
function getBreakpointFromDom() {
  return window
    .getComputedStyle(window.document.querySelector('body'), ':after')
    .getPropertyValue('content')
    .replace(/"/g, '');
}

/**
 * Check the current breakpoint; if its different from current, call doBreakpointChange.
 */
function checkBreakpointChange() {
  const newBreakpoint = getBreakpointFromDom();
  if (newBreakpoint !== breakpoint) {
    doBreakpointChange(newBreakpoint);
  }
}

/**
 * Delay execution of checkBreakpointChange until user has stopped resizing the browser.
 */
function resizeThrottler() {
  window.clearTimeout(delay);
  delay = window.setTimeout(checkBreakpointChange, 100);
}

export default {
  /**
   * Set up window listener to update breakpoint on resize and fire breakpointChange custom event.
   * @param {Object} storeInstance The redux store for the site; saved within this module
   */
  init(storeInstance) {
    if (!initialized) {
      initialized = true;
      store = storeInstance;
      combineReducers(resizeReducer);
      checkBreakpointChange();
      window.addEventListener('resize', resizeThrottler, false);
    }
  },
};
