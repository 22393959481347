import { Map } from 'immutable';
import { VEHICLES_FILTERS } from 'actions/uiState';
import { uiStatePassengersFilters } from 'paths/uiState';

const initialState = Map({});

const ACTION_HANDLERS = {
  [VEHICLES_FILTERS.SET_PASSENGER_FILTERS]: (state, { data }) => state.setIn(uiStatePassengersFilters, data),
};

/**
 * Action handling for modal state updates
 * @param {Object} state An immutable state object to check against
 * @param {Object} action Data to update the redux store state
 * @returns {Object} A (usually changed) immutable state object
 */

const reducer = (state = initialState, action = null) => {
  const handler = action ? ACTION_HANDLERS[action.type] : null;
  return handler ? handler(state, action) : state;
};

export default {
  initialState,
  reducer,
};
