/**
 * Add selectors that pertain custom path flow
 */
import { config } from 'utils/config';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import { createSelector } from 'reselect';
import {
  expeditedReservationTypePathFlag,
  virginReservationPathFlag,
  gmaCustomPathBrandInformationPathFlag,
} from 'paths/customPathCheckIn';
import CUSTOM_PATH from 'constants/customPathCheckIn';

/* Constants */
const { CUSTOM_PATH_RESERVATION_TYPE } = CUSTOM_PATH;

/* ---- Get functions ---- */
const getVirginReservation = state => state.getIn(virginReservationPathFlag);
const getExpeditedFlag = state => state.getIn(expeditedReservationTypePathFlag);
const getCustomPathBrandInformation = state => state.getIn(gmaCustomPathBrandInformationPathFlag);

/* ---- Create Selectors ---- */
// Return boolean - true if reservation is Virgin
export const isVirginReservationSelector = createSelector(
  [getVirginReservation, getExpeditedFlag],
  (isVirginReservation, reservationType) =>
    isVirginReservation || (reservationType && reservationType === CUSTOM_PATH_RESERVATION_TYPE.BRAND_VIRGIN)
);

// Returns true if reservation is Restricted Non-Virgin Reservation
export const isRestrictedReservationSelector = createSelector(
  [getExpeditedFlag],
  reservationType => reservationType && reservationType === CUSTOM_PATH_RESERVATION_TYPE.BRAND_RESTRICTED
);

export const isCustomPathReservationSelector = createSelector(
  [isRestrictedReservationSelector, isVirginReservationSelector],
  (isRestricted, isVirginReservation) => !!(isRestricted || isVirginReservation)
);

// Returns an object with non-virgin brand information from session/gma
export const gmaBrandInformationSelector = createSelector(
  [getCustomPathBrandInformation, isRestrictedReservationSelector],
  (brandInformation, isRestrictedReservation) =>
    isRestrictedReservation && brandInformation?.get('brand_logo')
      ? {
          brandCode: brandInformation?.get('brand_code'),
          brandName: brandInformation?.get('brand_name'),
          brandLogo: brandInformation?.get('brand_logo'),
          landingPage: brandInformation?.get('landing_page'),
        }
      : null
);

// Returns an object with brand information from session/gma
export const gmaBrandCaasTagsSelector = createSelector(
  [getCustomPathBrandInformation, isRestrictedReservationSelector],
  (brandInformation, isRestrictedReservation) =>
    isRestrictedReservation &&
    (brandInformation?.get('faq_caas_tag') || brandInformation?.get('customer_support_caas_tag'))
      ? {
          brandFaqCaasTag: brandInformation?.get('faq_caas_tag'),
          brandCustomerSupportCaasTag: brandInformation?.get('customer_support_caas_tag'),
        }
      : null
);

// Get Virgin Link Modal Information, since we always want Virgin information we are going to use the ehi object
export const virginBrandInformationSelector = createSelector(
  [isVirginReservationSelector],
  isVirginReservation => {
    const ehiVirginBrandInfo = config.getVirginBrandInformation();
    const ehiVirginLandingPage = config.getRedirectUrl(WINDOW_OBJECT_KEYS.VIRGIN_OCI_LANDING_PAGE);
    return isVirginReservation
      ? {
          brandCode: ehiVirginBrandInfo?.brand_code,
          brandName: ehiVirginBrandInfo?.brand_name,
          brandLogo: ehiVirginBrandInfo?.logo_path,
          landingPage: ehiVirginLandingPage,
        }
      : null;
  }
);

// Get Virgin Link Modal Information, since we always want Virgin information we are going to use the ehi object
export const virginCaasTagsSelector = createSelector(
  [isVirginReservationSelector],
  isVirginReservation => {
    const ehiVirginBrandInfo = config.getVirginBrandInformation();
    return isVirginReservation
      ? {
          virginFaqCaasTag: ehiVirginBrandInfo?.faq_caas_tag,
          virginCostumerSupportCaasTag: ehiVirginBrandInfo?.customer_support_caas_tag,
        }
      : null;
  }
);

// Returns true if reservation is custom path enabled and is custom path flow SPA
export const isCustomPathFlowSPASelector = createSelector(
  [isCustomPathReservationSelector],
  isReservationCustomPathEnabled => {
    const isCustomPathFlowPath = config.isCustomPathFlow();
    return isReservationCustomPathEnabled || isCustomPathFlowPath;
  }
);
