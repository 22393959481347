import { Map } from 'immutable';
import { appLocationFinderPath } from 'paths/app';
import { LOCATION_FINDER } from 'actions/locationFinder';
import { ANALYTICS, RESERVATIONS } from 'constants';
import utils from 'utils';

const initialState = Map({
  brands_in_result: [],
  radius_used_in_kilometers: null,
  result: [],
  selected_location: JSON.parse(sessionStorage.getItem(utils.config.locationSessionStorageKey)) || null,
  showingPartnerLocations: false,
  timestamp: null,
  showSoldOutNearbyLocations: false,
  lastValidSearchedLocation: null,
  skipAnalytics: false,
});

// this function is used to mimic the formatting of GMI
// it inject a "T" between the date and time, even if either the date or time are missing
// eg. '2020-02-03T11:30' / '2020-02-03T' / 'T11:30'
const injectLetterT = (date, time) => (date || time) && `${date || ''}T${time || ''}`;

// field interactions on the unavailable location modal do not get tracked by the analytics middleware
// because those changes do not effect GMI
// this detects which value has changed and calls the appropriate interaction for it
const fireUnavailableLocationModalDataInteractions = (newData, currentData) => {
  if (!newData || !currentData) {
    return;
  }

  const { pickupDate, pickupTime, dropoffDate, dropoffTime } = newData;
  let key;
  let value;

  if (pickupDate !== currentData.pickupDate) {
    key = ANALYTICS.GMI_PICKUP_DATE_NODE_NAME;
    value = injectLetterT(pickupDate, null);
  } else if (pickupTime !== currentData.pickupTime) {
    key = ANALYTICS.GMI_PICKUP_TIME_NODE_NAME;
    value = injectLetterT(pickupDate, pickupTime);
  } else if (dropoffDate !== currentData.dropoffDate) {
    key = ANALYTICS.GMI_RETURN_DATE_NODE_NAME;
    value = injectLetterT(dropoffDate, null);
  } else if (dropoffTime !== currentData.dropoffTime) {
    key = ANALYTICS.GMI_RETURN_TIME_NODE_NAME;
    value = injectLetterT(dropoffDate, dropoffTime);
  }

  key && value && utils.analytics.interaction(ANALYTICS.RESERVATION, key, value);
};

const ACTION_HANDLERS = {
  [LOCATION_FINDER.SET_LOCATION_DATA]: (state, { data }) =>
    state
      .setIn([...appLocationFinderPath, 'brands_in_result'], data.brands_in_result || [])
      .setIn([...appLocationFinderPath, 'radius_used_in_kilometers'], data.radius_used_in_kilometers),
  [LOCATION_FINDER.SET_LOCATION_RESULT]: (state, { data }) => state.setIn([...appLocationFinderPath, 'result'], data),
  [LOCATION_FINDER.UNSET_LOCATION_DATA]: state =>
    state
      .setIn([...appLocationFinderPath, 'brands_in_result'], initialState.get('brands_in_result'))
      .setIn([...appLocationFinderPath, 'radius_used_in_kilometers'], initialState.get('radius_used_in_kilometers'))
      .setIn([...appLocationFinderPath, 'result'], initialState.get('result')),
  [LOCATION_FINDER.SET_LOCATION]: (state, { location }) => {
    sessionStorage.setItem(utils.config.locationSessionStorageKey, JSON.stringify(location));
    sessionStorage.removeItem(RESERVATIONS.CURRENCY_TOGGLE_SELECTED);
    return state.setIn([...appLocationFinderPath, 'selected_location'], location);
  },
  [LOCATION_FINDER.UNSET_LOCATION]: state => {
    sessionStorage.removeItem(utils.config.locationSessionStorageKey);
    return state.setIn([...appLocationFinderPath, 'selected_location'], null);
  },
  [LOCATION_FINDER.SET_LOCATION_DATA_UNAVAILABLE_LOCATION_MODAL]: (state, { data }) => {
    const currentData = state.getIn([...appLocationFinderPath, 'unavailable_location_modal']);
    fireUnavailableLocationModalDataInteractions(data, currentData);
    return state.setIn([...appLocationFinderPath, 'unavailable_location_modal'], data);
  },
  [LOCATION_FINDER.UNSET_LOCATION_DATA_UNAVAILABLE_LOCATION_MODAL]: state =>
    state.setIn([...appLocationFinderPath, 'unavailable_location_modal'], null),
  [LOCATION_FINDER.SHOW_PARTNER_RESULTS]: (state, { bool }) =>
    state.setIn([...appLocationFinderPath, 'showingPartnerLocations'], bool),
  [LOCATION_FINDER.SET_CURRENT_TIMESTAMP]: state => state.setIn([...appLocationFinderPath, 'timestamp'], Date.now()),
  [LOCATION_FINDER.SHOW_SOLD_OUT_NEARBY_LOCATIONS]: (state, { bool }) =>
    state.setIn([...appLocationFinderPath, 'showSoldOutNearbyLocations'], bool),
  [LOCATION_FINDER.SET_LAST_VALID_LOCATION_SEARCHED]: (state, { id }) =>
    state.setIn([...appLocationFinderPath, 'lastValidSearchedLocation'], id),
  [LOCATION_FINDER.SET_ADJUST_LOCATION_ID]: (state, { id }) =>
    state.setIn([...appLocationFinderPath, 'adjustLocationId'], id),
  [LOCATION_FINDER.SET_UPDATED_SOLD_OUT_DATE_TIME]: (state, { bool }) =>
    state.setIn([...appLocationFinderPath, 'updatedSoldOutDateTime'], bool),
  [LOCATION_FINDER.SET_SOLD_OUT_AREA]: (state, { bool }) =>
    state.setIn([...appLocationFinderPath, 'soldOutArea'], bool),
  [LOCATION_FINDER.SET_SKIP_ANALYTICS_FOR_LOCATION_TYPE_FILTER]: (state, { bool }) =>
    state.setIn([...appLocationFinderPath, 'skipAnalytics'], bool),
};

/**
 * Action handling for modal state updates
 * @param {Object} state An immutable state object to check against
 * @param {Object} action Data to update the redux store state
 * @returns {Object} A (usually changed) immutable state object
 */
const reducer = (state = initialState, action = null) => {
  const handler = action ? ACTION_HANDLERS[action.type] : null;
  return handler ? handler(state, action) : state;
};

export default {
  initialState,
  reducer,
};
