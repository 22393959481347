import { Map, List } from 'immutable';
import utils from 'utils';
import ANALYTICS from 'constants/analytics';

const { ACTION_OPEN_MODAL, ACTION_CLOSE_MODAL, ACTION_CLEAR_MODAL_QUEUE } = ANALYTICS;

const initialState = Map({
  modalQueue: List([]),
  finalAction: null,
});

export const MODAL_PATH = {
  STATE_PATH: ['app', 'modal'],
  MODAL_QUEUE: ['app', 'modal', 'modalQueue'],
  FINAL_ACTION: ['app', 'modal', 'finalAction'],
};

const ACTION_HANDLERS = {
  [ACTION_OPEN_MODAL]: (state, { modalKey, props = {} }) => {
    if (modalKey) {
      let newState = state;
      const modalQueue = state.getIn(MODAL_PATH.MODAL_QUEUE).push(
        Map({
          modalKey,
          props,
        })
      );
      if (props.finalAction) {
        newState = state.setIn(MODAL_PATH.FINAL_ACTION, props.finalAction);
      }
      utils.phoneInput.selectedCountryDetails.remove();
      return newState.setIn(MODAL_PATH.MODAL_QUEUE, modalQueue);
    }
    return state;
  },

  [ACTION_CLOSE_MODAL]: (state) => {
    const modalQueue = state.getIn(MODAL_PATH.MODAL_QUEUE).shift();
    return state.setIn(MODAL_PATH.MODAL_QUEUE, modalQueue);
  },

  [ACTION_CLEAR_MODAL_QUEUE]: (state) => state.setIn(MODAL_PATH.STATE_PATH, initialState),
};

/**
 * Action handling for modal state updates
 * @param {Object} state An immutable state object to check against
 * @param {Object} action Data to update the redux store state
 * @returns {Object} A (usually changed) immutable state object
 */
const reducer = (state = initialState, action = null) => {
  const handler = action ? ACTION_HANDLERS[action.type] : null;
  return handler ? handler(state, action) : state;
};

export default {
  initialState,
  reducer,
};
