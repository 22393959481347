import { RESERVATIONS } from 'constants';
import { scrollLock } from './scrollLock';
/**
 * DOM Utilities
 */
const LOADINGOVERLAY = 'loading-overlay';
/**
 * A modern check to see if the DOM has been loaded
 * @param {Function} callback
 */
export const domReady = callback => {
  document.readyState === 'interactive' || document.readyState === 'complete'
    ? callback()
    : document.addEventListener('DOMContentLoaded', callback);
};

/**
 * Recursive check for attribute in the given scope
 * @param {DOMElement} domEl
 * @param {DOMElement} scopeEl
 * @param {string} attribute
 */
export const hasAttributeInScope = (domEl, scopeEl, attribute) => {
  if (domEl.hasAttribute(attribute)) {
    return true;
  }
  return domEl !== scopeEl ? hasAttributeInScope(domEl.parentElement, scopeEl, attribute) : false;
};

/**
 * Focuses the element and prevents scrolling the element into view
 *
 * @param {DOMElement} domEl
 */
export const focusWithoutScroll = domEl => {
  const x = window.scrollX || window.pageXOffset;
  const y = window.scrollY || window.pageYOffset;
  domEl.focus();
  window.scrollTo(x, y);
};

/**
 * Scrolls user to a position on page
 * scrolls to top by default
 * @param {number} x
 * @param {number} y
 */
export const scrollPage = (x = 0, y = 0) => {
  window.scrollTo(x, y);
};

/**
 * Scrolls user forcibly to top of page
 * Should be used when the overlay is causing a noscroll behavior.
 */
export const autoPageTop = () => {
  const html = document.querySelector('html');
  html.style.scrollBehavior = 'auto';
  scrollPage();
  html.style.scrollBehavior = '';
};

/**
 * Generates random uuid
 *
 */
export const uuid = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return `${s4()}${s4()}-${s4()}-${s4()}`;
};
/**
 * Toggles full screen overlay
 *
 */
export const loadingOverlay = (toggleValue = true) => {
  if (toggleValue) {
    const overlay = document.createElement('div');
    overlay.classList.add(LOADINGOVERLAY);
    document.body.append(overlay);
    scrollLock({ toggleValue: true });
  } else {
    const overlay = document.querySelector(`.${LOADINGOVERLAY}`);
    if (overlay && overlay.nodeType) {
      document.body.removeChild(overlay);
    }
    scrollLock({ toggleValue: false });
  }
};

/**
 * Returns true if event is instance of TouchEvent and prevents
 * TouchEvent reference errors thrown in browsers not using chromium
 *
 */
export const isTouchEvent = event => window.TouchEvent && event instanceof TouchEvent;
/**
 * Returns true if event is instance of MouseEvent
 *
 */
export const isMouseEvent = event => window.MouseEvent && event instanceof MouseEvent;

/**
 * Check if given dom element is contained inside a 6 columns grid
 *
 * @param {DOMElement} domEl
 * @returns {Boolean}
 */
export const isContainedInSixColumnGrid = domEl => {
  const parentGrid = domEl.closest('.aem-Grid');
  return parentGrid && parentGrid.classList.contains('aem-Grid--default--6');
};

//  This checks to see if we are at the start of the stand alone booking widget
//  to do this we look at the hash of the URL
export const isAtStartOfStandAloneBookingWidgetPage = () => {
  const windowHash = window.location.hash;
  return windowHash && windowHash.includes(RESERVATIONS.RESFLOW_PATHS_CONFIG.start[0]);
};

/**
 * Scrolls user to a top position on page using offsetTop
 * @param {number} x
 * @param {number} y
 */

export const onFocusScrollToTop = (id, isMobile, x = 0, y) => {
  const formElement = document.getElementById(id);
  y = y || formElement?.offsetTop - `${isMobile ? 70 : 30}`;
  window.scrollTo(x, y);
};
