/**
 * Rental flow routes config - should match GMA's go_to responses
 */
const RENTAL_VIEWS = {
  CONFIRM_MODIFY: ['/confirm_modify'],
  MODIFY_RENTAL: ['/modify-rental'],
  COMMIT: ['/commit'],
  DATE_TIME: ['/date_time'],
  DETAILS: ['/details'],
  CONFIRMED: ['/confirmed'],
  UPDATED: ['/updated'],
  TIMED_OUT: ['timedout'],
};

export default {
  RENTAL_VIEWS,
};
