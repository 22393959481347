import { createSelector } from 'reselect';
import utils from 'utils';
import { gmaUpcomingTripsPath, gmaCurrentTripsPath, gmaPastTripsPath, gmaPastTripReceiptsPath } from 'paths/trips';

// getters
const getUpcomingTrips = state => state.getIn(gmaUpcomingTripsPath);
const getCurrentTrips = state => state.getIn(gmaCurrentTripsPath);
const getPastTrips = state => state.getIn(gmaPastTripsPath);
const getPastTripReceipts = state => state.getIn(gmaPastTripReceiptsPath);
const getInvoiceId = (state, invoiceId) => invoiceId;

export const upcomingTripsSelector = createSelector(
  [getUpcomingTrips],
  trips => utils.safeToJS(trips, [])
);

export const firstUpcomingTripSelector = createSelector(
  [getUpcomingTrips],
  trips => utils.safeToJS(trips, [])[0]
);

export const currentTripsSelector = createSelector(
  [getCurrentTrips],
  trips => utils.safeToJS(trips, [])
);

export const firstCurrentTripSelector = createSelector(
  [getCurrentTrips],
  trips => utils.safeToJS(trips, [])[0]
);

export const pastTripsSelector = createSelector(
  [getPastTrips],
  trips => utils.safeToJS(trips, [])
);

export const totalCountTripsSelector = createSelector(
  [upcomingTripsSelector, currentTripsSelector, pastTripsSelector],
  (upcomingTrips, currentTrips, pastTrips) => upcomingTrips.length + currentTrips.length + pastTrips.length
);

export const pastTripReceiptsSelector = createSelector(
  [getPastTripReceipts, getInvoiceId],
  (invoices, id) => {
    const receipt = invoices.get(id);
    const invoice = utils.safeToJS(receipt, {});
    return invoice?.past_trip_detail;
  }
);

export const haveUpcomingOrCurrentTripSelector = createSelector(
  [firstUpcomingTripSelector, firstCurrentTripSelector],
  (upcomingTrip, currentTrip) => !!upcomingTrip || !!currentTrip
);
