import { gboReservationPath } from './session';

export const reservationDriverInfoPath = [...gboReservationPath, 'driver_info'];
export const reservationProfileDetailsPath = [...gboReservationPath, 'profile_details'];
export const reservationProfileLoyaltyDataPath = [...reservationProfileDetailsPath, 'loyalty_data'];
export const reservationProfileLoyaltyNumberPath = [...reservationProfileLoyaltyDataPath, 'loyalty_number'];
export const reservationDriverFirstName = [...reservationDriverInfoPath, 'first_name'];
export const reservationDriverLastName = [...reservationDriverInfoPath, 'last_name'];
export const reservationDriverEmail = [...reservationDriverInfoPath, 'email_address'];
export const reservationDriverPhoneNumber = [...reservationDriverInfoPath, 'phone', 'phone_number'];
