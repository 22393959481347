import { createSelector } from 'reselect';
import PLATFORMS from 'constants/platforms';
import { getPlatform } from 'utils/browser';

export const platformSelector = createSelector([getPlatform], (platform) => ({
  isAndroid: platform === PLATFORMS.ANDROID,
  isIOS: platform === PLATFORMS.IOS,
  isNonMobile: platform === PLATFORMS.NON_MOBILE,
  isOtherMobile: platform === PLATFORMS.OTHER_MOBILE,
}));
