import { createSelector } from 'reselect';
import utils from 'utils';
import { POLICIES } from 'constants';

// getters
const getPolicies = state => state.getIn(['gmi', 'session', 'gbo', 'reservation', 'policies']);

// selectors
export const formattedPoliciesSelector = createSelector(
  [getPolicies],
  policyObjectRaw => {
    if (!policyObjectRaw) {
      return [];
    }
    const policyObject = utils.safeToJS(policyObjectRaw);
    return policyObject.map(policy => ({
      name: policy.description,
      content: policy.policy_text,
      code: policy.code,
    }));
  }
);

export const modalRequiredPoliciesSelector = createSelector(
  [getPolicies],
  policies => {
    const useablePolicies = utils.safeToJS(policies, []);
    return useablePolicies.filter(policy => policy?.modal_display);
  }
);

export const guaranteedResPolicySelector = createSelector(
  [getPolicies],
  policies => {
    const ccgPolicy = policies?.find(policy => policy.get('code') === POLICIES.CREDIT_CARD_GUARANTEE);

    return ccgPolicy
      ? {
          text: ccgPolicy.get('policy_text'),
        }
      : null;
  }
);

export const additionalDriverPolicySelector = createSelector(
  [getPolicies],
  policies => {
    const addrPolicy = policies?.find(policy => policy.get('code') === POLICIES.ADDITIONAL_DRIVERS);

    return addrPolicy
      ? {
          text: addrPolicy.get('policy_text'),
        }
      : null;
  }
);

export const wayfindingPolicySelector = createSelector(
  [getPolicies],
  policies => {
    const ccgPolicy = policies?.find(policy => policy.get('code') === POLICIES.WAYFINDING_POLICY);

    return ccgPolicy
      ? {
          text: ccgPolicy.get('policy_text'),
        }
      : null;
  }
);
