/**
 * Check-In flow routes config - should match GMA's go_to responses
 */
const CUSTOM_PATH_CONFIG = {
  driverLookup: ['/driver_lookup'],
  profile: ['/profile'],
  checkInReview: ['/check_in_review'],
  skipTheCounter: ['/skip_the_counter'],
  confirmation: ['/stc_confirmation'],
};

/**
 * Accepted values on the `origin` param on custom path request requests
 */
const CUSTOM_PATH_ORIGIN = {
  LOOK_UP: 'LOOK_UP',
  CONFIRMATION: 'CONFIRMATION',
  RESERVATION_TILE: 'RESERVATION_TILE',
  RESERVATION_DETAILS: 'RESERVATION_DETAILS',
  ADDITIONAL_DRIVER_FLOW: 'ADDITIONAL_DRIVER_FLOW',
};

/* Custom Path */
const CUSTOM_PATH_RESERVATION_TYPE = {
  BRAND_VIRGIN: 'VIRGIN',
  BRAND_RESTRICTED: 'RESTRICTED',
  BRAND_RETAIL: 'RETAIL',
};

export default {
  CUSTOM_PATH_RESERVATION_TYPE,
  CUSTOM_PATH_CONFIG,
  CUSTOM_PATH_ORIGIN,
};
