/**
 * turns a string into a key
 * @param {string} name
 * @returns {string}
 */
export const stringUnderscorer = name => name.toLowerCase().replace(/\s/g, '_');

/**
 * Capitalizes the first letter of a text
 *
 * @param {string} text - string of text to capitalize
 */
export const capitalize = text => (text ? `${text.charAt(0).toUpperCase()}${text.substr(1).toLowerCase()}` : '');

/**
 * lowercase the first letter of a text
 *
 * @param {string} text - string of text to capitalize
 */
export const lowerCapitalize = text =>
  text ? `${text.charAt(0).toLocaleLowerCase()}${text.substr(1).toLowerCase()}` : '';

/**
 * Splits a given text using a dynamic separator and capitalizes each word.
 *
 * @param {string} text - string of text to capitalize all of
 * @param {string} separator - a way to specify the structure with delimeters
 */
export const capitalizeAll = (text, separator = ' ') => {
  if (!text) {
    return '';
  }
  return text
    .split(separator)
    .map(capitalize)
    .join(separator);
};

/**
 * Remove apostrophe " ' " character from string
 * @param {string} str - a string with an apostrophe
 * @returns {string}
 */
export const removeApostrophe = str => {
  if (!str) {
    return '';
  }
  return str.replace(/'/g, '');
};
