import { Map } from 'immutable';
import { countrySelectedPath } from 'paths/app';
import RESERVATION_LOOKUP from 'constants/reservationLookup';

const { LOOK_UP_TYPE } = RESERVATION_LOOKUP;

const initialState = Map({
  countrySelected: {},
});

const ACTION_HANDLERS = {
  [LOOK_UP_TYPE.COUNTRY_SELECTED]: (state, { data }) => state.setIn([...countrySelectedPath], data),
};
const reducer = (state = initialState, action = null) => {
  const handler = action ? ACTION_HANDLERS[action.type] : null;
  return handler ? handler(state, action) : state;
};

export default {
  initialState,
  reducer,
};
