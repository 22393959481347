import { utils } from '@ehi/global-marketing-interface';
import DISCOUNTS from 'constants/discounts';
import RESERVATIONS from 'constants/reservations';
import { i18n } from './i18n';
import { getFormattedPrice } from './prices';

const { AUTOMATIC, MANUAL } = RESERVATIONS.TRANSMISSION_FEATURE_CODES;

/**
 * Looks for transmission codes in features and returns the description text
 */
export const getTransmissionDescriptionFromFeaturesArray = features =>
  features?.find(feature => feature.code === MANUAL || feature.code === AUTOMATIC)?.description;

/**
 * Select vehicle image type, size, and quality
 */
export const getImageFromImagesObject = (images = {}, options = {}) => {
  const { quality = 'high', width = '180', type = 'ThreeQuarter' } = options;
  return images?.[type]?.path?.replace('{quality}', quality).replace('{width}', width) || null;
};

/**
 * Determines allIncluded status or sum of non-included taxes & fees
 */
export const getTaxesFeesTotal = (priceSummary, asterisk) => {
  if (
    priceSummary?.payment_line_items?.FEE?.every(({ status }) => status === RESERVATIONS.TAXES_FEES_INCLUDED_STATUS)
  ) {
    return i18n('rental_details_status_included');
  }
  return getFormattedPrice(priceSummary?.estimated_total_taxes_and_fees_view, { asterisk });
};

/**
 * This util is used to extract the correctly formatted distance unit (eg. mi or km).
 *
 * @param {object} mileageInfo - this is the `mileageInfo` as returned by the GBO session response.
 *                               (eg. `gbo.reservation.car_class_details.mileage_info` from current session)
 *
 * @return {string} the distance unit (eg. mi or km).
 */
export const getDistanceUnit = mileageInfo =>
  (mileageInfo?.distance_unit_measurement_code || mileageInfo?.distance_unit || '').toLowerCase();

/**
 * Checks if FREE_UPGRADE type coupon is available
 */
export const isFreeUpgradeAvailable = coupons =>
  !!(coupons[0]?.type && coupons[0].type === DISCOUNTS.COUPON_TYPE_UPGRADE);

/**
 * getVehicleMakeModel - Concat vehicle make and model
 * @param {object} vehicleDetails - Vehicle details object
 * @return {string} vehicle make and model concatenated
 */
export const getVehicleMakeModel = vehicleDetails =>
  vehicleDetails ? `${vehicleDetails.make} ${vehicleDetails.model}` : '';

/**
 * Checks if the string value contains only alphabets
 */

export const isAlphabetic = word => !/[^a-zA-Z]/.test(word);

export const getSearchFilterValue = filterValues => {
  if (utils.isArrayNotEmpty(filterValues?.selectedValues)) {
    return filterValues?.selectedValues.length > 1
      ? `${i18n('search_by_vehicles_filters_count', [filterValues?.selectedValues.length || 0])}`
      : filterValues?.selectedValues[0];
  }
  return i18n('all_vehicles');
};

export const getLocationSelectedValues = currentFilters => {
  if (utils.isArrayNotEmpty(currentFilters[0]?.values)) {
    return currentFilters[0]?.values.length > 1
      ? `${i18n('location_finder_filter_label_location_type_count', [currentFilters[0]?.values.length || 0])}`
      : i18n(`location_finder_filter_label_${currentFilters[0]?.values[0]}`);
  }
  return i18n('common_all');
};

export const getArrayValuesAsString = array =>
  !!array &&
  array
    .toString()
    .replace('[', '')
    .replace(']', '');
