export const updateMultiSelectFilter = ({ filters, keyPath, value, checked }) => {
    let filteredFilters = filters.slice();
    // find index of this specific filter in active filters
    const filtersIndex = filteredFilters.findIndex(filter => filter.keyPath === keyPath);

    if (filtersIndex !== -1) {
        // if filter is already active
        // find the index of the specific value within the active filter
        const valueIndex = filteredFilters[filtersIndex].values.findIndex(v => v === value);
        if (valueIndex !== -1 && !checked) {
            // if the value is in the filter, we want to remove it
            filteredFilters = filteredFilters.reduce((acc, filter) => {
                const updatedFilter = { ...filter };
                if (updatedFilter.keyPath === keyPath) {
                    updatedFilter.values = filter.values.filter(val => val !== value || checked);
                }
                if (updatedFilter.values) {
                    acc.push(updatedFilter);
                }
                return acc;
            }, []);
        } else if (checked) {
            // if the value is not present in the filter
            if (value) {
                filteredFilters[filtersIndex].values.push(value);
            } else {
                filteredFilters;
            }
        }
    } else if (checked) {
        // if the filter is not already active and setting the value to checked
        filteredFilters.push({ keyPath, values: [value] });
    }
    return filteredFilters;
};