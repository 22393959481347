import { createSelector } from 'reselect';
import utils from 'utils';

const DEFAULT_SERVICE = 'gma';
const getIsFetchingService = (state, { service = DEFAULT_SERVICE }) => state.getIn(['gmi', 'fetching', service]);
const getFetchingServices = (state, { service = DEFAULT_SERVICE }) =>
  state.getIn(['gmi', 'fetching', `${service}_services`]);
const getEndpoint = (state, { endpoint }) => endpoint;
const getExactMatch = (state, { exactMatch }) => exactMatch;

/**
 * isFetchingSelector
 * will check if a service is fetching and return false,
 * or return the array of services being fetched
 *
 * @param {object} state - redux state
 * @param {('cda'|'gma'|'solr')} [service='gma'] - one of the built-in gmi services
 * @return {(bool|Array<string>)}  returns null if no profile in redux state, otherwise returns an object with requested options
 */
const isFetchingSelector = createSelector(
  [getIsFetchingService, getFetchingServices],
  (isFetchingService, fetchingServices) => isFetchingService && utils.safeToJS(fetchingServices, false)
);

/**
 * isFetchingMatchedString
 * check if a fetching service matches the provided string
 * example: 'profile/login' if fetching 'profile/login?username=somethingsomething&password=somethingsomething&remember_credentials=false'
 *
 * @param {object} state - redux state
 * @param {('cda'|'gma'|'solr')} [service='gma'] - one of the built-in gmi services
 * @param {string} endpoint - the string to match in the service
 * @param {boolean} exactMatch - checks for exact string equality, instead match which may accept substrings
 * @return {bool}
 */
export const isFetchingEndpointSelector = createSelector(
  [isFetchingSelector, getEndpoint, getExactMatch],
  (isFetching, endpoint, exactMatch) =>
    !!isFetching &&
    Array.isArray(isFetching) &&
    endpoint &&
    isFetching.some(service => (exactMatch ? service === endpoint : service.includes(endpoint)))
);
