export const uiBasePath = ['gmi', 'ui'];
export const uiStateReservationsPath = [...uiBasePath, 'reservations'];
export const uiStateDriverProfilePath = [...uiStateReservationsPath, 'driver_profile'];
export const uiStateInitiatePath = [...uiStateReservationsPath, 'initiate'];
export const uiInitiatePickupLocationPath = [...uiStateInitiatePath, 'pickup_location'];
export const uiInitiateReturnLocationPath = [...uiStateInitiatePath, 'return_location'];
export const uiInitiatePickupTimePath = [...uiStateInitiatePath, 'pickup_time'];
export const uiInitiateReturnTimePath = [...uiStateInitiatePath, 'return_time'];

export const uiStateReservationCommitPath = [...uiStateReservationsPath, 'commit'];
export const uiStateShowPartnersLocations = [...uiBasePath, 'show_partners_locations'];
export const uiStatePassengersFilters = [...uiBasePath, 'passengers_filters'];
export const uiPickupLocationAdditionalData = [...uiStateInitiatePath, 'pickup_location', 'additional_data'];
export const uiPickupLocationTimeZone = [...uiPickupLocationAdditionalData, 'java_time_zone'];

export const uiOneWayRentalPath = [...uiStateInitiatePath, 'one_way_rental'];

export const uiStateFiltersPath = [...uiBasePath, 'filters'];
