import { gboReservationPath, gmaSessionPath } from './session';

export const gmaReservationPath = ['gmi', 'gma', 'reservation'];

export const afterHoursPickupSelectedPath = [...gboReservationPath, 'after_hours_pickup_selected'];

export const pickupLocationPath = [...gboReservationPath, 'pickup_location'];
export const pickupLocationWithDetailPath = [...gmaSessionPath, 'pickup_location_with_detail'];
export const reservationTravelIndicatorPath = [...pickupLocationPath, 'travel_indicator'];
export const pickupLocationTypePath = [...pickupLocationPath, 'location_type'];
export const pickupLocationMultiTerminalPath = [...pickupLocationPath, 'multi_terminal'];
export const reservationAirlineInfoPath = [...gboReservationPath, 'airline_info'];
export const pickupLocationAirlineDetailsPath = [...pickupLocationPath, 'airline_details'];
export const pickupLocationLocalAddendaPath = [...pickupLocationPath, 'branch_local_addenda'];
export const pickupLocationCurrencyCodePath = [...pickupLocationPath, 'currency_code'];

export const returnLocationPath = [...gboReservationPath, 'return_location'];

export const pickupTimePath = [...gboReservationPath, 'pickup_time'];
export const returnTimePath = [...gboReservationPath, 'return_time'];

export const oneWayRentalPath = [...gboReservationPath, 'one_way_rental'];
export const rentalTermsAndConditionsPath = [...gmaReservationPath, 'rental_terms', 'rental_terms_and_conditions'];
export const pickupWithDetailLicenseePath = [...pickupLocationWithDetailPath, 'licensee'];
export const licenseeNamePath = [...gboReservationPath, 'licensee_name'];
export const legalNamePath = [...gboReservationPath, 'pickup_location', 'legal_name'];
