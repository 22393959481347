import { actions, services } from '@ehi/global-marketing-interface';
import { gmiRequest } from 'actions/request';
import {
  getOneWayGmaRentalFlag,
  gmaInitiateLocationsSelector,
  getInitiatePickupTime,
  getInitiateReturnTime,
  getInitiateContractNumber,
} from 'selectors/reservationSelectors';
import { getSelectedCurrencyToggle } from 'selectors/uiStateSelectors';
import { locationFinderAlamoResultSelector } from 'selectors/appSelectors';
import { LOCATIONS, GLOBAL, RESERVATIONS } from 'constants';
import { setVehiclesClassData } from 'actions/vehicleClass';

/**
 * fetchVehiclesAvailability action to fetch vehicle availability for the selected car classes or all car classes
 * Returns data about each vehicle code and whether it is available
 * ** This was created only for Location Finder scenario, if used somewhere else might need to change the logic **
 *
 * @param {object} params
 * @param {array} params.newLocations - Locations coming from spatial query, used to get the id on locationPairs logic
 * @param {boolean} params.checkNoVehiclesAvailable - Flag to check if no vehicles are available for each location, default to true
 * @return {Promise}
 */
export const fetchVehiclesAvailability =
  (newLocations = null, checkNoVehiclesAvailable = true) =>
  (dispatch, getState) => {
    const state = getState();
    const isOneWay = getOneWayGmaRentalFlag(state);

    const { pickupLocation, returnLocation } = gmaInitiateLocationsSelector(state);
    const pickupTime = getInitiatePickupTime(state);
    const returnTime = getInitiateReturnTime(state);
    const contractNumber = getInitiateContractNumber(state);
    let locations = newLocations;
    if (!newLocations) {
      locations = locationFinderAlamoResultSelector(state);
    }

    // If there are no locations, return
    if (!locations.length) {
      return null;
    }

    const isReturnBranch = returnLocation.type !== LOCATIONS.TYPE_CITY;

    const locationPairs = [];
    locations.forEach(({ id }) => {
      locationPairs.push({
        pickup_location_id: isOneWay && !isReturnBranch ? pickupLocation.id : id,
        return_location_id: isOneWay && isReturnBranch ? returnLocation.id : id,
      });
    });

    const payload = {
      location_pairs: locationPairs,
      pickup_time: pickupTime,
      return_time: returnTime,
      contract_number: contractNumber || null,
      check_if_no_vehicles_available: checkNoVehiclesAvailable,
    };

    return dispatch(
      gmiRequest({
        loadingOverlay: true,
        action: actions.request({
          data: {
            payload,
          },
          service: services.gma.vehicles.availability,
        }),
      })
    );
  };

// Need to handle to call it once
export const getVehiclesList =
  (country = GLOBAL.DEFAULT_COUNTRY) =>
  (dispatch, getState) => {
    const vehiclesList = {};

    if (!vehiclesList?.[country]) {
      dispatch(
        gmiRequest({
          action: actions.request({
            data: {
              params: { cor: country },
            },
            service: services.gma.vehicles.categoriesByCountryCode,
          }),
          onComplete: (response) => {
            if (response) {
              const vehiclesByCountryCode = {
                [country]: true,
              };
              // store vehicles to sessionStorage to make an request only once for the session
              sessionStorage.setItem(RESERVATIONS.VEHICLES_INIT_LOCALSTORAGE, JSON.stringify(vehiclesByCountryCode));
              const filters = JSON.parse(sessionStorage.getItem(RESERVATIONS.VEHICLES_SEARCH_LOCALSTORAGE)) || {};
              const state = getState();
              const currency_toggle = getSelectedCurrencyToggle(state);
              // replace vehicle_categories from the gmi response for vehcile list
              dispatch(setVehiclesClassData({ vehiclesList: response, filters, currency_toggle }));
            }
          },
        })
      );
    }
  };
