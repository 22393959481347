import { createSelector } from 'reselect';
import utils from 'utils';
import CUSTOMER_SUPPORT from 'constants/customerSupport';

const { PHONE_TYPE } = CUSTOMER_SUPPORT;

export const getCountrySupportNumbers = (state, countryCode) =>
  state.getIn(['gmi', 'gma', 'content', 'support', 'contact', countryCode, 'support_phone_numbers']);

export const getAlamoInsidersSupportPhoneNumber = createSelector([getCountrySupportNumbers], (phoneNumbersObject) =>
  utils.safeToJS(phoneNumbersObject?.find((phoneNum) => phoneNum?.get('phone_type') === PHONE_TYPE.ALAMO_INSIDER))
);

export const getAlamoContactUsSupportPhoneNumber = createSelector([getCountrySupportNumbers], (phoneNumbersObject) =>
  utils.safeToJS(phoneNumbersObject?.find((phoneNum) => phoneNum?.get('phone_type') === PHONE_TYPE.CONTACT_US))
);

export const getAlamoRoadsideAssistanceSupportPhoneNumber = createSelector(
  [getCountrySupportNumbers],
  (phoneNumbersObject) =>
    utils.safeToJS(
      phoneNumbersObject?.find((phoneNum) => phoneNum?.get('phone_type') === PHONE_TYPE.ROADSIDE_ASSISTANCE)
    )?.phone_number
);

const getSupportContactByCountryCode = (state, { countryCode }) =>
  state.getIn(['gmi', 'gma', 'content', 'support', 'contact', countryCode]);
const getPhoneType = (_, { phone_type }) => phone_type;

export const supportPhoneNumberByCountryAndTypeSelector = createSelector(
  [getSupportContactByCountryCode, getPhoneType],
  (countrySupportData, phone_type) =>
    utils
      .safeToJS(countrySupportData, null)
      ?.support_phone_numbers?.find((phone_number) => phone_number?.phone_type === phone_type)?.phone_number
);
