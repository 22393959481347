import { loadingOverlay } from '../dom';
import { setSrcToElement } from '../lazyLoad';
import './print.css';

const PRINT_AREA_ID = 'printable';
const DEFAULT_PRINT_AREA = '#non-printable .root';
const getPrintableAreaElement = () => document.querySelector(`#${PRINT_AREA_ID}`);

/**
 * print - This method create a section that will be showed only in the print mode and hide all elements from the site
 * creating a copy from the specified elements and appending into the print section called #printable at the end of the body
 * and being removed after the print window be closed
 * @param {string/array} elements - One or more elements identified by className or id
 * @return {void}
 */
const print = (elements = DEFAULT_PRINT_AREA) => {
  loadingOverlay(true);
  let printImages = [];
  let wrapper = getPrintableAreaElement();

  if (!wrapper) {
    const printNode = document.createElement('div');
    printNode.id = PRINT_AREA_ID;
    document.body.appendChild(printNode);
  }

  wrapper = getPrintableAreaElement();
  const selectors = Array.isArray(elements) ? elements : [elements];

  selectors.forEach(elem => {
    const element = document.querySelector(elem);
    const elementCopy = element.cloneNode(true);
    const images = Array.from(elementCopy.getElementsByTagName('img'));
    printImages = [...images];
    printImages.forEach(setSrcToElement);
    wrapper.appendChild(elementCopy);
  });

  const nonPrintableElement = document.querySelector('#non-printable');
  nonPrintableElement.classList.add('printing');
  wrapper.classList.add('printing');

  const interval = setInterval(() => {
    // Avoiding images not load before print
    if (!printImages.some(img => !img.complete)) {
      loadingOverlay(false);
      clearInterval(interval);
      window.print();
      // remove wrapper to avoid duplication content if user clicks on print more than once
      wrapper.remove();
      nonPrintableElement.classList.remove('printing');
    }
  }, 200);
};

export default print;
