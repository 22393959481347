const WINDOW_OBJECT_KEYS = {
  BRANCH_PAGE_REDIRECT: 'branch-page-redirect',
  LOCATION_FINDER_URL: 'location-finder-url',
  HOME_PAGE_URL: 'home-page',
  DEALS: 'deals',
  RENTAL_DETAILS: 'viewmodifycanceldetails',
  FIND_A_RESERVATION: 'viewmodifycancel',
  CUSTOMER_SUPPORT: 'customer-support',
  LOCATIONS_LANDING_PAGE: 'locations-landing-page',
  GOOGLE_MAPS_API_KEY: 'googleMapsApiKey',
  PRIVACY_POLICY: 'privacyPolicy',
  COOKIE_POLICY: 'cookiePolicy',
  SMS_TERMS: 'smsTerms',
  FAQ_PAGE_URL: 'faq-page',
  ANALYTICS: '_analytics',
  TIMEOUT: 'timeout',
  RESTRICTED_TIMEOUT: 'restricted-path-timeout-page',
  RESERVATION_INFLOW: 'reservation-inflow',
  CONFIRMATION: 'confirmation',
  CONFIRMATION_MODIFY: 'confirmation-modify',
  CHECKIN_FLOW: 'online-check-in-flow',
  CHECKIN_LOOKUP: 'online-check-in-lookup',
  TERMS_OF_USE_REDIRECT: 'terms-of-use-page',
  SIGN_IN_PAGE: 'sign-in-page',
  ENROLL_PAGE: 'enroll-page',
  ENROLL_CONFIRMATION_PAGE: 'enroll-confirmation-page',
  FORGOT_USERNAME_PAGE: 'forgot-username-page',
  FORGOT_PASSWORD_PAGE: 'forgot-password-page',
  AUTH_HOME_PAGE: 'auth-home-page',
  PROFILE_PAGE: 'profile-page',
  RECEIPTS_PAGE: 'receipts-page',
  STANDALONE_BOOKING_WIDGET: 'standalone-booking-widget-page',
  EMAIL_SPECIALS: 'email-specials',
  EMAIL_SPECIALS_CONFIRMATION_PAGE: 'email-specials-confirmation-page',
  SKIP_THE_COUNTER_FLOW: 'counter-by-pass-flow',
  SKIP_THE_COUNTER_LOOKUP: 'counter-by-pass-lookup',
  ADDITIONAL_DRIVER_MINI_FLOW: '',
  UNAUTH_PAST_TRIPS_PAGE: 'past-trips',
  UNAUTH_RECEIPT_PAGE: 'receipt-details-page',
  CUSTOM_PATH_FLOW: 'custom-path-check-in-flow',
  CUSTOM_PATH_RENTAL_DETAILS: 'custom-path-viewmodifycanceldetails',
  RESTRICTED_OCI_LANDING_PAGE: 'restricted-oci-landing-page',
  VIRGIN_OCI_LANDING_PAGE: 'virgin-oci-landing-page',
  MODIFY_RENTAL: 'modify-rental',
  RENTAL_SEARCH: 'rental-search',
};

export default WINDOW_OBJECT_KEYS;
