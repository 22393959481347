/**
 * Alamo reCAPTCHA related constants
 *
 * @type {object}
 *
 */
const RECAPTCHA = {
  ACTION_NAMES: {
    SEND_DEEPLINK: 'SendDeepLink',
    SEARCH_TRIPS: 'SearchTrips',
    CREATE_PROFILE: 'CreateProfile',
  },
  ACTION_TYPE_RECAPTCHA: 'recaptchaRequest',
};

export default RECAPTCHA;
