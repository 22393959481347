import { config } from './config';

/**
 * Checks if a value is a number and returns it if so, otherwise returns 0
 * @param {any} val
 * @returns {number}
 */
export const isSafeNumberVal = (val) => (typeof val === 'number' && !isNaN(val) ? val : 0);

/**
 * Check if a value is a parsable number and returns it if so, otherwise returns 0
 * @param {(number|string)} val
 * @returns {number}
 */
export const getIntegerNumber = (val) => {
  const number = parseInt(val, 10);
  return isSafeNumberVal(number) ? number : 0;
};

/**
 * formatNumber - Get number formatted based by locale (e.g. 4,600)
 * @param {string} value - Number value
 * @return {string} formatted number
 */
export const formatNumber = (value) => {
  const locale = config.getLocale().replace('_', '-');
  return new Intl.NumberFormat(locale, { maximumSignificantDigits: 3 }).format(value);
};
