import { actions, services } from '@ehi/global-marketing-interface';
import utils from 'utils';
import { WINDOW_OBJECT_KEYS } from 'constants';
import { requestStart, errorEncountered } from './global';

export const LOCATION_SEARCH = {
  SET_RECENT_SEARCH_ITEM: '@@locationSearch/SET_RECENT_SEARCH_ITEM',
  CLEAR_RECENT_SEARCH: '@@locationSearch/CLEAR_RECENT_SEARCH',
  FETCH_RECENT_SEARCHES: '@@locationSearch/FETCH_RECENT_SEARCHES',
};

let cancelLocationsTextQuery = null;
/**
 * locationsTextQuery - Wrapped Action for requesting locations via solr text query endpoint
 *
 * @param {String} query Location text query
 *
 * @returns {Promise}
 */
export const locationsTextQuery = query => dispatch => {
  if (cancelLocationsTextQuery !== null) {
    utils.safeExecute(cancelLocationsTextQuery);
  }
  if (query.length < 3) {
    return;
  }
  dispatch(
    actions.request({
      data: {
        endpoint: { query },
      },
      opts: {
        cancelFn: c => {
          cancelLocationsTextQuery = c;
        },
        clearStateOnRequest: true,
      },
      service: services.solr.text,
    })
  ).then(() => {
    cancelLocationsTextQuery = null;
  });
};

/**
 * Wrapped Action for requesting locations via solr spatial query endpoint
 * @returns {Promise}
 */
let cancelLocationsSpatialQuery = null;
export const locationsSpatialQuery = params => dispatch => {
  if (cancelLocationsSpatialQuery !== null) {
    utils.safeExecute(cancelLocationsSpatialQuery);
  }
  return dispatch(
    actions.request({
      data: {
        params: {
          includePartnerBranches: false,
          rows: 2000,
          tribrand: false,
          ...params,
        },
      },
      opts: {
        cancelFn: c => {
          cancelLocationsSpatialQuery = c;
        },
      },
      service: services.solr.spatial,
    })
  ).then(response => {
    cancelLocationsSpatialQuery = null;
    return response;
  });
};

/**
 * receiveBranchURL - Redirect user to branch URL
 *
 * @param {String} branchURL Description
 */
export const receiveBranchURL = branchUrlResponse => {
  if (branchUrlResponse && branchUrlResponse.redirect) {
    window.location.assign(branchUrlResponse.redirect);
  } else {
    utils.redirectToNotFound();
  }
};

/**
 * redirectToLocationFinder - Stores location information in sessionStorage and redirects user to location finder page
 *
 * @param {Object} location Location object
 */
export const redirectToLocationFinder = location => () => {
  const {
    name,
    gps: { latitude, longitude },
  } = location;
  sessionStorage.setItem(
    utils.config.locationSessionStorageKey,
    JSON.stringify({ name, gps: { latitude, longitude } })
  );
  window.location.assign(utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.LOCATION_FINDER_URL));
};

/**
 * resolveBranchURL - Request branch URL from AEM servlet based on provided branch id
 *
 * @param {String} branchId ID of the branch that needs to be located
 */
// TODO: Use GMI
export const resolveBranchURL = branchId => dispatch => {
  dispatch(requestStart());
  const requestUrl = `${utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.BRANCH_PAGE_REDIRECT)}?peopleSoftId=${branchId}`;
  return fetch(requestUrl, {
    method: 'GET',
  })
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => receiveBranchURL(json))
    .catch(error => dispatch(errorEncountered(error.message)));
};

export const setRecentSearchItem = location => ({ type: LOCATION_SEARCH.SET_RECENT_SEARCH_ITEM, location });
export const clearRecentSearches = () => ({ type: LOCATION_SEARCH.CLEAR_RECENT_SEARCH });
export const fetchRecentSearches = () => ({ type: LOCATION_SEARCH.FETCH_RECENT_SEARCHES });
export const clearTextQueryLocations = () => actions.setGmiState(['gmi', 'solr', 'text'], {}); // TODO: should this be a GMI action?
