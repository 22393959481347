import { createSelector } from 'reselect';
import { BREAKPOINTS } from 'constants';

const breakpointCursor = ['app', 'breakpoint'];
const getBreakpoint = state => state.getIn(breakpointCursor);

export const breakpointSelector = createSelector(
  [getBreakpoint],
  breakpoint => ({
    isMobile: breakpoint === BREAKPOINTS.SMALL,
    isTablet: breakpoint === BREAKPOINTS.MEDIUM,
    isDesktop: breakpoint === BREAKPOINTS.LARGE || breakpoint === BREAKPOINTS.XL,
    isTabletOrMobile: breakpoint === BREAKPOINTS.MEDIUM || breakpoint === BREAKPOINTS.SMALL,
  })
);
