/**
 * Skip The Counter flow routes config - should match GMA's go_to responses
 */
const STC_PATH_CONFIG = {
  reviewSelections: ['/review_selections'],
  skipTheCounter: ['/skip_the_counter'],
  confirmation: ['/confirmation'],
};

/**
 * Accepted values on the `origin` param on skip the counter requests
 */
const STC_ORIGIN = {
  LOOK_UP: 'LOOK_UP',
  CONFIRMATION: 'CONFIRMATION',
  RESERVATION_TILE: 'RESERVATION_TILE',
  RESERVATION_DETAILS: 'RESERVATION_DETAILS',
  EMAIL: 'EMAIL',
  ONLINE_CHECK_IN_FLOW: 'ONLINE_CHECK_IN_FLOW',
  ADDITIONAL_DRIVER_FLOW: 'ADDITIONAL_DRIVER_FLOW',
};

/**
 * STC flow origins that will start in "Review Selections" step, therefore
 * should show the step counter with step details
 */
const STC_ORIGINS_WITH_STEP_COUNT = [
  STC_ORIGIN.LOOK_UP,
  STC_ORIGIN.RESERVATION_DETAILS,
  STC_ORIGIN.RESERVATION_TILE,
  STC_ORIGIN.EMAIL,
];

export default {
  STC_PATH_CONFIG,
  STC_ORIGIN,
  STC_ORIGINS_WITH_STEP_COUNT,
};
