import { Map } from 'immutable';
import utils from 'utils';
import { ASSOCIATE } from 'actions/associate';

const sessionData = sessionStorage.getItem(utils.config.reservationAssociatedToProfile);

const initialState = Map({
  data: (sessionData && sessionData !== 'undefined' && JSON.parse(sessionData)) || {},
});

export const ASSOCIATE_BASE_PATH = ['app', 'unauth_reservation'];
export const ASSOCIATE_RESERVATION__PATH = [...ASSOCIATE_BASE_PATH, 'data'];

const ACTION_HANDLERS = {
  [ASSOCIATE.SET_ASSOCIATE_RESERVATION_DATA]: (state, { data }) => {
    sessionStorage.setItem(utils.config.reservationAssociatedToProfile, JSON.stringify(data));
    return state.setIn(ASSOCIATE_RESERVATION__PATH, data);
  },

  [ASSOCIATE.CLEAR_ASSOCIATE_RESERVATION_DATA]: state => {
    sessionStorage.removeItem(utils.config.reservationAssociatedToProfile);
    return state.setIn(ASSOCIATE_RESERVATION__PATH, {});
  },
};

/**
 * Action handling for modal state updates
 * @param {Object} state An immutable state object to check against
 * @param {Object} action Data to update the redux store state
 * @returns {Object} A (usually changed) immutable state object
 */

const reducer = (state = initialState, action = null) => {
  const handler = action ? ACTION_HANDLERS[action.type] : null;
  return handler ? handler(state, action) : state;
};

export default {
  initialState,
  reducer,
};
