import LOCATIONS from './locations';

const ZOOM_LEVEL = 14;

const URL = Object.freeze({
  resPathBranchRedirect: '?peopleSoftId=',
  GOOGLE_MAPS_STATIC_CALL: (googleAPIKey, iconURL, latitude, longitude, width, height) =>
    `https://maps.googleapis.com/maps/api/staticmap?center=&zoom=${ZOOM_LEVEL}&scale=1&size=${width}x${height}&maptype=roadmap&key=${googleAPIKey}&format=png&visual_refresh=true&markers=icon:${iconURL}%7Cshadow:true%7C${latitude},${longitude}`,
  GOOGLE_MAPS_BASE_LINK: 'https://www.google.com/maps/search',
  ENTERPRISE_SITE_URL: 'https://www.enterprise.com/',
  NATIONAL_SITE_URL: 'https://www.nationalcar.com/',
});

export const PARTNER_URL = Object.freeze({
  [LOCATIONS.BRAND_ENTERPRISE]: URL.ENTERPRISE_SITE_URL,
  [LOCATIONS.BRAND_NATIONAL]: URL.NATIONAL_SITE_URL,
});

export default URL;
