import { utils as gmiUtils } from '@ehi/global-marketing-interface';

/**
 * Check if the current $lazyImage has a `data-src` or a `data-srcset`.
 * We must not set a `src` attribute to <source/> elements, otherwise
 * that affects lighthouse's best practices.
 */
export const setSrcToElement = $element => {
  const $lazyImage = $element;
  const srcKey = $lazyImage?.dataset?.src ? 'src' : 'srcSet';
  $lazyImage[srcKey] = $lazyImage?.dataset[srcKey];
};

/**
 * Initializes the IntersectionObserver to lazy load
 * visible pictures if the image is intersecting.
 *
 * @see https://developers.google.com/web/updates/2016/04/intersectionobserver
 */
const imageObserver = new IntersectionObserver(entries =>
  entries.forEach(entry => {
    if (!entry.isIntersecting) {
      return;
    }

    const $lazyPicture = entry.target;

    // Remove lazy-load class from picture and remove observer
    $lazyPicture?.classList?.remove('lazy-load');
    imageObserver.unobserve($lazyPicture);

    // Gets lazy elements from lazy loaded `picture` and sets the src to them
    const $lazyElements = $lazyPicture.querySelectorAll('source, img');
    $lazyElements.forEach(setSrcToElement);
  })
);

/**
 * This method will look for existing images in the dom with the `lazy-load`
 * class and initialize the observer for those so they can be lazy loaded.
 * It is debounced in case we have too many images mounting at the same
 * time, like in the vehicles list page.
 */
const observeImages = gmiUtils.debounce(() => {
  const $lazyImages = document.querySelectorAll('picture.lazy-load');
  $lazyImages.forEach($img => imageObserver.observe($img));
}, 500);

/**
 * Entry point for the lazy load logic. It initializes the observer
 * for the existing images in the dom and listens for the custom
 * `reactpicturemounted` event. If this event is fired, it means a
 * image has been mounted in a react component and we need to observe
 * it in order to lazy load.
 */
const lazyLoadSetup = () => {
  observeImages();
  window.addEventListener('reactpicturemounted', observeImages);
};

export default lazyLoadSetup;
