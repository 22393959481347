/**
 * CustomEvent Polyfill
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
 */

(() => {
  if (typeof window.CustomEvent === 'function') {
    return false;
  }

  function CustomEvent(event, param) {
    const params = param || { bubbles: false, cancelable: false, detail: null };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  window.CustomEvent = CustomEvent;

  return CustomEvent;
})();
