import React, { Component, forwardRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';
import { selectedCountryDetails } from 'utils/phoneInput';
import { Iterable } from 'immutable';

export const toJS = (WrappedComponent) => {
  const ToJS = (wrappedComponentProps) => {
    const KEY = 0;
    const VALUE = 1;

    const propsJS = Object.entries(wrappedComponentProps).reduce((newProps, wrappedComponentProp) => {
      const newNewProps = newProps;
      newNewProps[wrappedComponentProp[KEY]] = Iterable.isIterable(wrappedComponentProp[VALUE])
        ? wrappedComponentProp[VALUE].toJS()
        : wrappedComponentProp[VALUE];
      return newNewProps;
    }, {});

    return <WrappedComponent {...propsJS} />;
  };

  ToJS.displayName = `ToJS(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return ToJS;
};

export const withForwardedRef = (WrappedComponent) =>
  forwardRef((props, ref) => <WrappedComponent {...props} forwardedRef={ref} />);

export class OnUpdateWrapper extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.pathname !== this.props.pathname) {
      this.props.onUpdate();
      selectedCountryDetails.remove();
    }
  }

  render() {
    return this.props.children;
  }
}

/* withRouter has been deprecated in react-router-dom v6, 
   we can use this custom withRouter which can be wrapped to component 
   to get the location/navigate/params details.
 */
export const withRouter = (ComponentContainer) => {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <ComponentContainer {...props} match={{ params }} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
};
