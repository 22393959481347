export const PROGRESS_BAR_ACTIONS = {
  SET_EXPANDED_TAB: '@@PROGRESS_BAR_ACTIONS/SET_EXPANDED_TAB',
  SET_MOBILE_EXPANDED: '@@PROGRESS_BAR_ACTIONS/SET_MOBILE_EXPANDED',
  SET_TOTAL_DROPDOWN_MOBILE_EXPANDED: '@@PROGRESS_BAR_ACTIONS/SET_TOTAL_DROPDOWN_MOBILE_EXPANDED',
  SET_PROGRESS_BAR_LOCATION_INITIATE: '@@PROGRESS_BAR_ACTIONS/SET_PROGRESS_BAR_LOCATION_INITIATE',
};

export const setExpandedTab = (id) => ({
  type: PROGRESS_BAR_ACTIONS.SET_EXPANDED_TAB,
  id,
});

export const setMobileExpanded = (expanded) => ({
  type: PROGRESS_BAR_ACTIONS.SET_MOBILE_EXPANDED,
  expanded,
});

export const setTotalDropdownMobileExpanded = (id) => ({
  type: PROGRESS_BAR_ACTIONS.SET_TOTAL_DROPDOWN_MOBILE_EXPANDED,
  id,
});

export const setProgressBarLocationInitiateState = (initiateState) => ({
  type: PROGRESS_BAR_ACTIONS.SET_PROGRESS_BAR_LOCATION_INITIATE,
  initiateState,
});
