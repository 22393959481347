import { createSelector } from 'reselect';
import utils from 'utils';

const getCountriesObject = (state) =>
  utils.safeToJS(state.getIn(['gmi', 'gma', 'location', 'countries', 'countries']), []);
const getStatesOrProvincesObject = (state) =>
  utils.safeToJS(state.getIn(['gmi', 'gma', 'location', 'states_or_provinces']), {});

/**
 * countriesSelector
 * Parse GBO country info object into an array of object sorted by country name
 * @param {Selector} getCountriesObject - Getter for GBO country info in the form of an object
 *  where each key is a country code and its value is the country info
 * @return {array} - Countries info from GBO parsed into a sorted array
 */
export const countriesSelector = createSelector([getCountriesObject], (countries) => countries);

/**
 * statesOrProvincesSelector
 * Parse GBO state/province info object, sorting each country's array of subdivisions by name
 *
 * @param {Selector} getStatesOrProvincesObject - Getter for GBO subdivision info
 * in the form of an object where each key is a country code and its value is an
 * array of subdivisions
 * @return {object} - Subdivision info from GBO, with each country's array sorted by name
 */
export const statesOrProvincesSelector = createSelector([getStatesOrProvincesObject], (statesOrProvinces) =>
  Object.entries(statesOrProvinces).reduce((allStateData, [country, stateData]) => {
    const updatedStateData = allStateData;
    let states = [];
    if (!utils.gmi.isObjectEmpty(stateData) && stateData?.region_lists.length > 0) {
      states = stateData.region_lists;
    }
    updatedStateData[country] = states;
    return allStateData;
  }, {})
);
