export const updateSingleSelectFilter = ({ filters, keyPath, value }) => {
    const filteredFilters = filters.slice();
    const values = value ? [value] : [];
    const filtersIndex = filteredFilters.findIndex(filter => filter.keyPath === keyPath);

    if (filtersIndex !== -1) {
        filteredFilters[filtersIndex].values = values;
    } else {
        filteredFilters.push({ keyPath, values });
    }

    return filteredFilters;
};