/**
 * Scope Tab Util
 * @module
 * @author react-modal
 * @author thomas.miller@isobar.com
 * Usage:
 *    <div onKeyDown={e => {
        if (e.key === 'Tab' && this.state.containFocus()) {
          scopeTab(this.refs.filtersDrawer, e);
        }
      }}>
 */

import { findTabbableDescendants } from './tabbable';

/**
 * Entry point of Scope Tab Util
 * @param {DOMElement} node
 * @param {SyntheticEvent} event
 * @param {boolean} constraintFocusInNode
 */
export function scopeTab(node, event, constraintFocusInNode = false) {
  // create an array of tabbable elements inside of node
  const tabbable = findTabbableDescendants(node);

  if (!tabbable.length) {
    // there are no tabbable elements in the given node so we let the browser handle tabbing
    if (constraintFocusInNode) {
      // if we still want to retain focus in the given node even though there are no tabbable elements
      event.preventDefault();
    }
    return;
  }

  const finalTabbable = tabbable[event.shiftKey ? 0 : tabbable.length - 1];
  // determine if the user is navigating out of the passed node and store it
  const leavingFinalTabbable =
    finalTabbable === document.activeElement ||
    // handle immediate shift+tab after opening with mouse
    node === document.activeElement;
  // if not leaving, return
  if (!leavingFinalTabbable) {
    return;
  }
  event.preventDefault();
  // target the opposite end of the tabbable array, from the finalTabbable element
  const target = tabbable[event.shiftKey ? tabbable.length - 1 : 0];
  // then focus
  target.focus();
}
