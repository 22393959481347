/**
 * Themes Names
 */
export default {
  LIGHT: 'light',
  MEDIUM: 'medium',
  DARK: 'dark',
  LIGHT_BLUE: 'light-blue',
  DARK_BLUE: 'dark-blue',
  DARKEST_BLUE: 'darkest-blue',
  MIDNIGHT_BLUE: 'midnight-blue',
};
