// TODO: revist service workers when there a clear measurable outcome to achieve.
// previous sw logic appeared minimally benefical, if at all (provided apache server is configured properly)
/**
 * Register service worker
 * @module
 */

/**
 * Register service worker if supported by the browser
 */
export function registerSw() {
  // if ('serviceWorker' in navigator) {
  //   window.addEventListener('load', () => {
  //     navigator.serviceWorker
  //       .register(`/etc/aweb/sw.js`)
  //       .then(registration => {
  //         /* eslint-disable-next-line no-param-reassign */
  //         registration.onupdatefound = () => {
  //           const installingWorker = registration.installing;
  //           if (installingWorker == null) {
  //             return;
  //           }
  //           installingWorker.onstatechange = () => {
  //             if (installingWorker.state === 'installed') {
  //               if (navigator.serviceWorker.controller) {
  //                 utils.safeExecute(config.onUpdate);
  //               } else {
  //                 utils.safeExecute(config.onSuccess);
  //               }
  //             }
  //           };
  //         };
  //       })
  //       .catch(error => {
  //         /* eslint-disable-next-line no-console */
  //         console.error('Error during service worker registration:', error);
  //       });
  //   });
  // }
}
