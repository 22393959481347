/*
 * Text Localization Utility
 */
import { utils } from '@ehi/global-marketing-interface';
import STACKS from 'constants/stacks';
import i18nFallbacks from 'data/i18nFallbacks.json';
import { config } from './config';

const stack = config.getStack();
const usei18nFallbacks = stack === STACKS.LOCAL || stack === STACKS.INT;
/**
 * String replacement function, returns an array if you pass it jsx
 * @param {Object} [{text: string, replacements: array|object}]
 * @returns {Array|String}
 */
const replacementsMapper = ({ text, replacements }, { jsx }) => {
  let template = text && text.split(/\{|}/g);
  const join = true;

  if (jsx) {
    template = template.filter(item => item !== '');
    return template.map(item => {
      const parsedInt = parseInt(item, 10);
      return isNaN(parsedInt) ? item : replacements[item];
    });
  }

  const textMap = template.map(part => {
    if (!part || isNaN(parseInt(part, 10))) {
      return part || '';
    }

    const { isDefined } = utils.types(replacements[part]);
    // i don't get the reason for this (DA)
    // if (isDefined && !isString) {
    //   join = false;
    // }

    return isDefined ? replacements[part] : '';
  });

  // TODO: show key if there's no fallback/in prod
  return join ? textMap.join('') : textMap;
};

/**
 * String localization util
 * @param {String} key
 * @param {Array|Object} replacements - index based object or array
 */
export const i18n = (key, replacements, opts = { jsx: false }) => {
  const text = config.getI18nTranslation(key) || (usei18nFallbacks ? i18nFallbacks[key] : false) || key;
  if (replacements && text) {
    return replacementsMapper({ text, replacements }, opts);
  }
  return text;
};
