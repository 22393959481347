/**
 * Object used to map configurations into cookie keys.
 * For example, we can pass a config object like `{ maxAge: 60 }`
 * and that will be mapped into `max-age=60`.
 */
const cookieKeys = Object.freeze({
  maxAge: 'max-age',
});

/**
 * Builds a config in the shape of a string to save as a cookie.
 * @param {array} objectEntry - The entry of a config object
 */
const buildConfig = ([key, val]) => {
  const optionKey = cookieKeys[key] || key;
  return `${optionKey}=${val}`;
};

/**
 * Gets a cookie based on a given key.
 * @param {string} key - Cookie key to look for
 */
const get = key => {
  const regEx = new RegExp(`(?:(?:^|.*;\\s*)${key}\\s*\\=\\s*([^;]*).*$)|^.*$`);
  const [, cookieValue] = document.cookie.match(regEx);

  if (key && cookieValue) {
    return unescape(cookieValue);
  }

  return null;
};

/**
 * Sets a cookie.
 *
 * @param {string} cookieKey - The new cookie key
 * @param {any} cookieValue - The value to be set to the cookie
 * @param {object} options - A config object for the new cookie
 */
const set = (cookieKey, cookieValue, config = {}) => {
  const cookieConfig = {path: '/', ...config};
  const mappedConfigs = Object.entries(cookieConfig)
    .map(buildConfig)
    .join(';');
  document.cookie = `${cookieKey}=${cookieValue};${mappedConfigs}`;
};

const remove = key => {
  document.cookie = `${key}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export default { get, set, remove };
