import { getActiveFilters } from "actions/filter/getActiveFilters";
import { updateRangeFilter } from "actions/filter/updateRangeFilter";
import { updateSingleSelectFilter } from "actions/filter/updateSingleSelectFilter";
import { updateMultiSelectFilter } from "actions/filter/updateMultiSelectFilter";
import { actions } from "@ehi/global-marketing-interface";

export const setFiltersByValue = ({
    storeKey,
    currentFilters,
    value,
    checked,
    keyPath,
    isRadio,
    isSingleSelect,
}) => dispatch => {
    const currentActiveFilters = getActiveFilters(currentFilters);
    let filters;

    if (isRadio) {
        filters = updateRangeFilter({ filters: currentActiveFilters, keyPath, value });
    } else if (isSingleSelect) {
        filters = updateSingleSelectFilter({ filters: currentActiveFilters, keyPath, value });
    } else {
        filters = updateMultiSelectFilter({ filters: currentActiveFilters, keyPath, value, checked });
    }

    dispatch(actions.setFiltersUiByKey(storeKey, filters));
};