export default {
  AVAILABLE_AT_CONTRACT_RATE: 'AVAILABLE_AT_CONTRACT_RATE',
  AVAILABLE_AT_PROMOTIONAL_RATE: 'AVAILABLE_AT_PROMOTIONAL_RATE',
  PROMOTION_TYPE: 'PROMOTION',
  CORPORATE_TYPE: 'CORPORATE',
  CONTRACT_TYPE: 'CONTRACT',
  COUPON_TYPE: 'COUPON',
  COUPON_TYPE_DISCOUNT: 'CURRENCY_OFF',
  COUPON_TYPE_UPGRADE: 'UPGRADE',
  COUPON_TYPE_FREE_DAY: 'FREE_DAY',
};
