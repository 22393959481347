import utils from 'utils';
import { GLOBAL, LOCATIONS } from 'constants';
import { actions, services } from '@ehi/global-marketing-interface';
import { clearVehiclesFilterData, setSelectedCurrencyToggleData } from 'actions/vehicleClass';
import { getVehiclesList } from 'actions/vehicles';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import { initiateReservationFlow } from 'actions/reservation/initiateReservationFlow';
import { getBranchHours } from './location';

export const BOOKING_WIDGET_ACTIONS = {
  SET_EXPANDED_TAB: 'BOOKING_WIDGET_ACTIONS/SET_EXPANDED_TAB',
};

const { initiateState } = actions.reservations;
let cancelAgeRangeDefaults = null;

/**
 * handleInitiate - takes updated props when called and initiates reservation with conditional settings based on props
 *
 * @param {object}    props - BookingWidget props
 * @param {string}    props.carClass - if initiate is called from Vehicle booking widget, pass car_class to initiate
 * @param {number}    props.profileData - if profileData includes age, pass user's age to initiate
 * @param {boolean}   props.isTrueModify - flag to check if it's true modify
 * @param {boolean}   props.isDeeplink - flag to check if it's deeplink
 * @return {Promise}  Promise that resolves into the initiate service call response object
 */
export const handleInitiate =
  ({ carClass, profileData, reservationObject, isTrueModify, isDeeplink, isInResBookingWidget }) =>
  (dispatch) => {
    if (carClass) {
      dispatch(initiateState.set('car_class', { code: carClass }));
    }
    if (profileData?.age) {
      dispatch(initiateState.set('renter_age', { renter_age: profileData.age }));
    }
    if (reservationObject?.iata_number) {
      dispatch(initiateState.set('iata_number', { iata_number: reservationObject?.iata_number }));
    }
    return dispatch(
      initiateReservationFlow({
        freshSession: !isInResBookingWidget && !isTrueModify && !isDeeplink,
        saveRecentSearches: true,
        saveLatestSelections: true,
      })
    );
  };

/**
 * getAgeRangeDefaults - Fetches default renters age options for the country from solr
 *
 * @param {String} [country=US] Country to fetch default options for
 *
 */
export const getAgeRangeDefaults =
  (country = GLOBAL.DEFAULT_COUNTRY) =>
  (dispatch) => {
    if (cancelAgeRangeDefaults !== null) {
      utils.safeExecute(cancelAgeRangeDefaults);
    }
    dispatch(
      actions.request({
        data: {
          endpoint: { country },
        },
        opts: {
          cancelFn: (c) => {
            cancelAgeRangeDefaults = c;
          },
          clearStateOnRequest: false,
        },
        service: services.solr.renterAge,
      })
    ).then(() => {
      cancelAgeRangeDefaults = null;
    });
  };

export const getAgeRangeDefaultsByLocation =
  (country = GLOBAL.DEFAULT_COUNTRY) =>
  (dispatch) => {
    if (cancelAgeRangeDefaults !== null) {
      utils.safeExecute(cancelAgeRangeDefaults);
    }
    return dispatch(
      actions.request({
        data: {
          endpoint: { country },
        },
        opts: {
          cancelFn: (c) => {
            cancelAgeRangeDefaults = c;
          },
          clearStateOnRequest: false,
        },
        service: services.solr.renterAge,
      })
    );
  };

export const setProductCode = (productCode) => (dispatch) => {
  dispatch(actions.reservations.initiateState.set('product_code', { product_code: productCode }));
};

export const setContractNumber = (contract_number) => (dispatch) => {
  if (contract_number) {
    dispatch(actions.reservations.initiateState.set('contract', { contract_number }));
  } else {
    dispatch(actions.reservations.initiateState.unset('contract'));
  }
};

export const setLocation = (location, exchangeType) => (dispatch, getState) => {
  const state = getState();
  const country_code = location.address?.country_code;
  const isFetchingGetVehiclesList = isFetchingEndpointSelector(state, {
    endpoint: `vehicles/categories?cor=${country_code}`,
  });
  if (location.type && location.type === LOCATIONS.TYPE_BRANCH) {
    dispatch(getBranchHours(location.id));
  }
  !isFetchingGetVehiclesList && dispatch(getVehiclesList(location.address?.country_code));
  dispatch(clearVehiclesFilterData());
  dispatch(setSelectedCurrencyToggleData());
  dispatch(actions.reservations.initiateState.set('location', { location, exchangeType }));
};

//  Sets additional Information, which is related to the contracts the users sets
//  if we want to set an array we put `null` for the first argument
export const setPrerateAdditionalInformation = (infoArray) => (dispatch) => {
  dispatch(actions.reservations.initiateState.set(null, { additional_information: infoArray }));
};

export const setPostRateAdditionalInformation = (infoArray) => (dispatch) => {
  dispatch(actions.reservations.commitState.set(null, { additional_information: infoArray }));
};

export const setPartnerRewardProgram = (partnerRewardProgram) => (dispatch) => {
  if (partnerRewardProgram) {
    const { code, program_name, name } = partnerRewardProgram;
    dispatch(
      actions.reservations.initiateState.set('partner_reward_program_code', { partner_reward_program_code: code })
    );
    dispatch(
      actions.reservations.initiateState.set('partner_reward_program_name', {
        partner_reward_program_name: program_name || name,
      })
    );
  } else {
    dispatch(actions.reservations.initiateState.unset('partner_reward_program_code'));
    dispatch(actions.reservations.initiateState.unset('partner_reward_program_name'));
  }
};

export const setPartnerRewardProgramMemberId = (memberId) => (dispatch) => {
  if (memberId) {
    dispatch(
      actions.reservations.initiateState.set('partner_reward_program_member_id', {
        partner_reward_program_member_id: memberId,
      })
    );
  } else {
    dispatch(actions.reservations.initiateState.unset('partner_reward_program_member_id'));
  }
};

export const setUpdateProfileWithPartnerRewards = (flagValue) => (dispatch) => {
  if (utils.gmi.types(flagValue).isDefined) {
    dispatch(
      actions.reservations.initiateState.set('update_profile_with_partner_rewards', {
        update_profile_with_partner_rewards: flagValue,
      })
    );
  } else {
    dispatch(actions.reservations.initiateState.unset('update_profile_with_partner_rewards'));
  }
};

export const setAbandonedRes = () => (dispatch) => {
  const resForState = utils.localStorage.getReservationLocalStorage();
  if (!resForState) {
    return Promise.resolve();
  }

  return dispatch(actions.reservations.initiateState.set(null, resForState)).then(() => resForState);
};

export const setDiscountCodeExpanded = (expanded) => ({
  type: BOOKING_WIDGET_ACTIONS.SET_EXPANDED_TAB,
  expanded,
});
