import CUSTOMER_SUPPORT from 'constants/customerSupport';
import COUNTRY_CODE from 'constants/countryCode';
import { SESSION_STORAGE } from 'constants/session';
import Storage from 'utils/storageManager';

const {
  PHONE_TYPE: {
    UNSUPPORTED_FORMATTING_MASK,
    GBO_DIGIT_REGEX_REPLACE_FROM,
    GBO_DIGIT_REPLACE_TO,
    GBO_MAX_PHONE_DIGITS_ALLOWED,
  },
} = CUSTOMER_SUPPORT;

export const isUnsupportedMask = (mask) =>
  !mask || typeof mask !== 'string' || mask.length === 0 || mask.toUpperCase() === UNSUPPORTED_FORMATTING_MASK;

// VMasker does use 9 as placeholder for numbers, not # as GBO does.
export const parseMaskToVMasker = (mask) => {
  if (isUnsupportedMask(mask)) {
    return null;
  }

  return mask.replace(GBO_DIGIT_REGEX_REPLACE_FROM, GBO_DIGIT_REPLACE_TO);
};

export const countMaskDigits = (mask) => {
  if (isUnsupportedMask(mask)) {
    return null;
  }
  return (mask.match(GBO_DIGIT_REGEX_REPLACE_FROM) || []).length;
};

export const parseCountryInfo = (selectedCountry, countries, updateStorage) => {
  const { country_content: content, country_name: countryName } = selectedCountry || {};
  const {
    code,
    dialing_code: dialingCode,
    phone_number_format: phoneNumberFormat = UNSUPPORTED_FORMATTING_MASK,
  } = content;

  const data = {
    countryCode: code,
    countryName,
    currentDialingCode: dialingCode,
    phoneNumberFormat: parseMaskToVMasker(phoneNumberFormat),
    phoneNumberFormatDigitsCount: countMaskDigits(phoneNumberFormat),
    countryCodesList: countries.map((item) => item.country_content?.dialing_code),
    previouslySelectedDialingCode: selectedCountry?.code,
  };

  if (updateStorage) Storage.SessionStorage.set(SESSION_STORAGE.COUNTRY_SELECTED, data, true);

  return data;
};

export const isValidCountryPhoneNumber = (country, phoneNumber) => {
  if (!country) {
    return false;
  }
  const { phoneNumberFormatDigitsCount, countryCode } = country;
  const shouldValidateCountry = countryCode === COUNTRY_CODE.CA || countryCode === COUNTRY_CODE.US;
  if (!shouldValidateCountry) {
    return phoneNumber;
  }

  const countryDoesntSupportsFormatting = isUnsupportedMask(country.phoneNumberFormat);
  const numberMatchMaskDigits = phoneNumber.toString().length === phoneNumberFormatDigitsCount;

  return countryDoesntSupportsFormatting || numberMatchMaskDigits;
};

export const getRawPhoneNumber = (country, phoneNumber) => {
  const formattedNumber = phoneNumber || '';
  if (!country) return formattedNumber;

  const { phoneNumberFormatDigitsCount = null } = country;
  const hasFormattingMask = phoneNumberFormatDigitsCount;
  const onlyDigitsNumber = formattedNumber
    .replace(country.currentDialingCode || '', '')
    .replace(/[^\d.]/g, '')
    .replace(/\./g, '')
    .substr(0, GBO_MAX_PHONE_DIGITS_ALLOWED);

  if (hasFormattingMask) {
    return onlyDigitsNumber.substr(0, phoneNumberFormatDigitsCount);
  }

  return onlyDigitsNumber;
};

export const selectedCountryDetails = {
  get: () => Storage.SessionStorage.get(SESSION_STORAGE.COUNTRY_SELECTED, true),
  remove: () => Storage.SessionStorage.remove(SESSION_STORAGE.COUNTRY_SELECTED),
};

export const isDialingCode = (phoneNumber) => {
  const parsedCountryObject = selectedCountryDetails.get();
  const { countryCodesList = [] } = parsedCountryObject || {};
  return countryCodesList?.some((item) => item === phoneNumber?.trimEnd());
};

export const getMaskedPhoneNumber = (phoneNumber) => phoneNumber?.replace(/.(?=.{4})/g, '*');

export const getMaskedEmail = (emailId) => emailId?.replace(/(\w{1})(.*)(\w{1})@(.*)/, '$1******$3@$4');
