import { utils as gmiUtils } from '@ehi/global-marketing-interface';
import RESERVATIONS from 'constants/reservations';

export const storeReservationLocalStorage = (state) => {
  const initiateUiState = state.getIn(['gmi', 'ui', 'reservations', 'initiate']);

  const reservation = {
    one_way_rental: initiateUiState.get('one_way_rental'),
    pickup_location: initiateUiState.get('pickup_location').toJS(),
    pickup_location_id: initiateUiState.get('pickup_location_id'),
    pickup_time: initiateUiState.get('pickup_time'),
    renter_age: initiateUiState.get('renter_age'),
    return_location: initiateUiState.get('return_location')?.toJS(),
    return_location_id: initiateUiState.get('return_location_id'),
    return_time: initiateUiState.get('return_time'),
    contract_number: initiateUiState.get('contract_number'),
    coupons: initiateUiState.get('coupons'),
  };

  const abandonedRes = { reservation, timestamp: new Date().valueOf() };

  localStorage.setItem(RESERVATIONS.RES_INIT_LOCALSTORAGE, JSON.stringify(abandonedRes));
};

export const getReservationLocalStorage = () => {
  const abandonedRes = JSON.parse(localStorage.getItem(RESERVATIONS.RES_INIT_LOCALSTORAGE));
  if (abandonedRes) {
    const { timestamp, reservation } = abandonedRes;
    // abandoned reservations older than 7 days are removed
    const isOlderThanSevenDays = gmiUtils.getCurrentDateTimeObj().subtract(7, 'days').isAfter(timestamp); // new Date().valueOf() - timestamp >= 604800000;
    // abandoned reservations with pick-up dates in past are removed
    const isPickUpDateInPast = gmiUtils.getCurrentDateTimeObj().isAfter(reservation.pickup_time);
    const shouldRemoveReservation = isOlderThanSevenDays || isPickUpDateInPast;

    if (shouldRemoveReservation) {
      localStorage.removeItem(RESERVATIONS.RES_INIT_LOCALSTORAGE);
      return null;
    }

    return reservation;
  }

  return null;
};

export const deleteReservationLocalStorage = () => {
  localStorage.removeItem(RESERVATIONS.RES_INIT_LOCALSTORAGE);
};

const GLOBAL_GATEWAY_PREFERRED_DOMAIN = 'GLOBAL_GATEWAY_PREFERRED_DOMAIN';

// user's choice is stored in session if they don't choose "remember my choice"
// this way we prevent showing the modal on subsequent pages during the session
export const storeGlobalGatewayPreferredDomain = (preferredDomain, useLocalStorage = false) => {
  const storage = useLocalStorage ? localStorage : sessionStorage;
  storage.setItem(GLOBAL_GATEWAY_PREFERRED_DOMAIN, preferredDomain);
};

export const getGlobalGatewayPreferredDomain = () =>
  localStorage.getItem(GLOBAL_GATEWAY_PREFERRED_DOMAIN) || sessionStorage.getItem(GLOBAL_GATEWAY_PREFERRED_DOMAIN);

export const getTabIdSession = () => sessionStorage.getItem('TAB_ID_SESSION');

export function ClearLocalStorageKeyStartsWith(startsWith) {
  const myLength = startsWith.length;
  Object.keys(localStorage).forEach((key) => {
    if (key.substring(0, myLength) === startsWith) {
      localStorage.removeItem(key);
    }
  });
}

export const deleteSessionStorageKey = (key) => sessionStorage.removeItem(key);
