/**
 * some elements go into a modal-like state and we need to maintain focus inside a containing element to prevent tabbing through
 * background content use these functions to set the first and last focusable elements so that while a user tabs
 * through, focus does not leave the desired container.
 * approach taken from https://uxdesign.cc/how-to-trap-focus-inside-modal-to-make-it-ada-compliant-6a50f9a70700
 */

/**
 * @param {string} container - the css selector of the parent element to trap focus within
 * @param {string} focusableElements - comma seperated css selectors of all focusable elements
 */
export const trapFocus = (container, focusableElements, shouldTrapFocus) => {
  document.addEventListener('keydown', e => {
    const el = document.querySelector(container); // select the modal by it's id or class
    // add all the elements inside modal which you want to make focusable
    if (el) {
      const firstFocusableElement = el?.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
      const focusableContent = el?.querySelectorAll(focusableElements);
      const lastFocusableElement = focusableContent[focusableContent?.length - 1];
      if (!shouldTrapFocus(el)) {
        return;
      }
      const isTabPressed = e.key === 'Tab';

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          e.preventDefault();
        }
      } else if (document.activeElement === lastFocusableElement) {
        // if tab key is pressed
        firstFocusableElement.focus();
        e.preventDefault();
      }
    }
  });
};
