import { Map } from 'immutable';
import { VEHICLE_GRID } from 'actions/vehicleGrid';

const initialState = Map({
  isVehicleLandingPage: true,
  categories: [],
});

export const VEHICLE_PATH = ['app', 'vehicle_grid'];

const ACTION_HANDLERS = {
  [VEHICLE_GRID.VEHICLE_GRID_DATA]: (state, { data }) => state.setIn(VEHICLE_PATH, Map(data)),
};

/**
 * Action handling for modal state updates
 * @param {Object} state An immutable state object to check against
 * @param {Object} action Data to update the redux store state
 * @returns {Object} A (usually changed) immutable state object
 */

const reducer = (state = initialState, action = null) => {
  const handler = action ? ACTION_HANDLERS[action.type] : null;
  return handler ? handler(state, action) : state;
};

export default {
  initialState,
  reducer,
};
