import { Map } from 'immutable';
import utils from 'utils';
import { LOCATION_SEARCH } from 'actions/locationSearch';

const initialState = Map({
  recent_search_items: JSON.parse(localStorage.getItem(utils.config.locationRecentSearchesKey)) || [],
});

export const LOCATION_SEARCH_PATH = ['app', 'location_search'];

const ACTION_HANDLERS = {
  [LOCATION_SEARCH.SET_RECENT_SEARCH_ITEM]: (state, { location }) => {
    const prevItems = JSON.parse(localStorage.getItem(utils.config.locationRecentSearchesKey)) || [];
    const newItems = [
      { ...location, localStorageTimestamp: utils.gmi.getCurrentDateTimeObj() },
      ...prevItems.slice(0, 2),
    ];
    // Insure location exists and is not redundant
    if (!location || prevItems.some(item => item.id === location.id)) {
      return state;
    }
    localStorage.setItem(utils.config.locationRecentSearchesKey, JSON.stringify(newItems));

    return state.setIn([...LOCATION_SEARCH_PATH, 'recent_search_items'], newItems);
  },
  [LOCATION_SEARCH.CLEAR_RECENT_SEARCH]: state => {
    localStorage.removeItem(utils.config.locationRecentSearchesKey);
    return state.setIn([...LOCATION_SEARCH_PATH, 'recent_search_items'], []);
  },
  [LOCATION_SEARCH.FETCH_RECENT_SEARCHES]: state => {
    const items = JSON.parse(localStorage.getItem(utils.config.locationRecentSearchesKey)) || [];
    // Filter out recent searches older than one month
    return state.setIn(
      [...LOCATION_SEARCH_PATH, 'recent_search_items'],
      items.filter(item =>
        utils.gmi
          .getDateTimeObjFromTs(item.localStorageTimestamp)
          .add(1, 'months')
          .isSameOrAfter(utils.gmi.getCurrentDateTimeObj())
      )
    );
  },
};

/**
 * Action handling for modal state updates
 * @param {Object} state An immutable state object to check against
 * @param {Object} action Data to update the redux store state
 * @returns {Object} A (usually changed) immutable state object
 */
const reducer = (state = initialState, action = null) => {
  const handler = action ? ACTION_HANDLERS[action.type] : null;
  return handler ? handler(state, action) : state;
};

export default {
  initialState,
  reducer,
};
