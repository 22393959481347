import { KEYS } from 'constants';

/**
 * Tabbable Util
 * @module
 * @author react-modal
 * @author jQuery UI core
 * @author thomas.miller@isobar.com
 * @author yauheni.mukavozchyk@isobar.com
 * Usage:
 *    findTabbableDescendants(el); // find all tabbable elements in el and create array
 *    findTabbableDescendants(el)[0].focus(); // focus first tabbable element in el
 *    findTabbableDescendants(document.querySelector('.element'))[0].focus(); // focus first tabbable element in body
 *
 *    let tabs = findTabbableDescendants(el);
 *    tabs[tabs.length - 1].focus(); // focus last tabbable element in el
 */

const tabbableNode = /input|select|textarea|button|object/;

// determines if the element should be hidden based on the various checks
const hidden = element => {
  // determine if element has negative or zero dimensions
  const zeroSize = element.offsetWidth <= 0 && element.offsetHeight <= 0;
  if (zeroSize && !element.innerHTML) {
    return true;
  }
  // if element has positive dimensions we need to check style
  const style = window.getComputedStyle(element);
  return style.getPropertyValue('display') === 'none' || style.getPropertyValue('visibility') === 'hidden';
};

const visible = element => {
  let parentElement = element;
  while (parentElement) {
    // if you've reached the body, break
    if (parentElement === document.body) {
      break;
    }
    // if the parent element is hidden, return false
    if (hidden(parentElement)) {
      return false;
    }
    // else set parentElement to its parentNode and continue the loop
    parentElement = parentElement.parentNode;
  }
  return true;
};

const focusable = (element, isTabIndexNotNaN, checkVisibility) => {
  const nodeName = element.nodeName.toLowerCase();
  // if element is a tabbableNode and not disabled, or a link with an href, or has a tabIndex
  const res =
    (tabbableNode.test(nodeName) && !element.disabled) ||
    (nodeName === 'a' ? element.href || isTabIndexNotNaN : isTabIndexNotNaN);
  // return true if the element passes the previous conditional and the element is visible

  return res && (!checkVisibility || visible(element));
};

const tabbable = (element, checkVisibility) => {
  let tabIndex = element.getAttribute('tabindex');
  // if element does not have a tabIndex, set tabIndex to undefined
  if (tabIndex === null) {
    tabIndex = 'undefined';
  }
  // store if tabIndex is a number
  // important to use isNaN -vs- Number.isNaN since:
  // isNaN('undefined') -> true
  // Number.isNaN('undefined') -> false
  // eslint-disable-next-line no-restricted-globals
  const isTabIndexNaN = isNaN(tabIndex);
  // if tabIndex is not negative, return if the element is focusable
  return (isTabIndexNaN || tabIndex >= 0) && focusable(element, !isTabIndexNaN, checkVisibility);
};

/**
 * Entry point of Tabbable Util
 * returns an array of all tabbable elements
 * @param {DOMElement} element
 */
export function findTabbableDescendants(element, checkVisibility = true) {
  return [].slice.call(element.querySelectorAll('*'), 0).filter(el => tabbable(el, checkVisibility));
}

/**
 * custom util (not part of tabbable) to enable focus styles only on keyboard navigation
 */
export const a11yFocusOutline = () => {
  const focusClass = 'enable-focus-outline';
  // Disable focus outlines when clicking through elements
  document.body.addEventListener('click', e => {
    // Only trigger the class removal if the click event came from a true click, not a keyboard event (buttons)
    const isKeyboardEvent = e.detail === 0 || (e.screenX === 0 && e.screenY === 0);
    if (!isKeyboardEvent) {
      document.documentElement.classList.remove(focusClass);
    }
  });

  // Reenable focus outlines when tabbing through elements
  document.body.addEventListener('keyup', e => {
    const key = e.which || e.keyCode;
    key === KEYS.TAB && document.documentElement.classList.add(focusClass);
  });
};

/**
 * Get next tabbable element
 * @param {DOMElement} element - active/tabbed element, the element which you want the next tabbable element (default to document active element)
 * @param {DOMElement} scopeElement - parent element, the scope where to find the next tabbable element (default to body)
 */
export const getNextTabbable = (element = document.activeElement, scopeElement = document.body) => {
  const allTabbables = findTabbableDescendants(scopeElement);
  const elementIndex = allTabbables.indexOf(element);
  const newIndex = elementIndex + 1;
  // If elementIndex is valid and the next element index exist, return the next element
  return elementIndex > -1 && allTabbables[newIndex] ? allTabbables[newIndex] : null;
};
