export const ASSOCIATE = {
  SET_ASSOCIATE_RESERVATION_DATA: 'SET_ASSOCIATE_RESERVATION_DATA',
  CLEAR_ASSOCIATE_RESERVATION_DATA: 'CLEAR_ASSOCIATE_RESERVATION_DATA',
};

export const setAssociateReservationData = data => ({
  type: ASSOCIATE.SET_ASSOCIATE_RESERVATION_DATA,
  data,
});

export const clearAssociateReservationData = () => ({
  type: ASSOCIATE.CLEAR_ASSOCIATE_RESERVATION_DATA,
});
