import { createSelector } from 'reselect';
import { profileProgramMatchPRP } from 'utils/profile';
import { safeToJS } from 'utils/immutable';
import { uiInitiatePartnerRewardDetails } from 'selectors/uiStateSelectors';
import { profileDataSelector } from 'selectors/profileSelectors';
import { gboPartnerRewardProgramPath } from 'paths/session';

/** GMI * */
// Returns the available Partner Rewards Programs by country
export const getAvailablePartnerRewardsPrograms = (state) =>
  safeToJS(state.getIn(['gmi', 'gma', 'reference', 'partner_rewards']), {});

// Returns the existing content for PRP Surcharge
export const getSurchargeContent = (state) =>
  state.getIn(['gmi', 'gma', 'content', 'partner_reward_programs_surcharge']);

/** GBO * */
// Returns an object if the reservation has partner_reward_program
const getReservationPartnerRewardProgram = (state) => state.getIn(gboPartnerRewardProgramPath);
export const reservationPartnerRewardsProgramSelector = createSelector(
  [getReservationPartnerRewardProgram],
  (partnerRewardProgram) =>
    partnerRewardProgram
      ? {
          code: partnerRewardProgram.get('code'),
          memberId: partnerRewardProgram.get('member_id'),
          programName: partnerRewardProgram.get('program_name'),
        }
      : null
);

export const getProfilePartnerRewards = (state) => profileDataSelector(state, { partnerRewardsProgram: true });

/**
 * setResPartnerRewardsDetails - comparing the PRP(partner rewards program) with existing gbo reservation , profilePRP, uiPRP ,
 * and checking whether we should update PRP to our res initiateState.
 * profileProgramMatchPRP -   If authenticated user have a preferred PRP and it's the same, just initiate the reservation without initiating the PRP data.
 * @param {selector}    getProfilePartnerRewards - ProfileData Partner rewards for auth user that returns object
 * @param {selector}    reservationPartnerRewardsProgramSelector - returns gboReservation partner rewards data if exists
 * @param {selector}    uiInitiatePartnerRewardDetails - UI initiate state partnerRewards data if exists
 */
export const setResPartnerRewardsDetails = createSelector(
  [getProfilePartnerRewards, reservationPartnerRewardsProgramSelector, uiInitiatePartnerRewardDetails],
  (profilePartnerRewardsProgram, resPartnerRewardsProgram, uiPartnerRewards) => {
    if (
      resPartnerRewardsProgram &&
      !profileProgramMatchPRP(profilePartnerRewardsProgram, resPartnerRewardsProgram) &&
      !uiPartnerRewards?.code
    ) {
      return {
        code: resPartnerRewardsProgram.code,
        name: resPartnerRewardsProgram.programName,
        member_id: resPartnerRewardsProgram.memberId,
      };
    }
    return null;
  }
);
