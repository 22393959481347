import { createSelector } from 'reselect';
import { config } from 'utils/config';
import {
  appLastValidSearchedLocationPath,
  appShowSoldOutNerabyLocationsPath,
  appLocationFinderResultPath,
  appLocationFinderBrandsPath,
  appLocationFinderTimestampPath,
  appAdjustLocationIdPath,
  updatedSoldOutDateTimePath,
  soldOutAreaPath,
  skipAnalyticsLocationFinderPath,
  appUnauthReservationPath,
} from 'paths/app';
import LOCATIONS from 'constants/locations';

export const getAppLastValidSearchedLocation = (state) => state.getIn(appLastValidSearchedLocationPath);

export const getAppShowSoldOutNerabyLocations = (state) => state.getIn(appShowSoldOutNerabyLocationsPath);

const getAppLocationFinderResult = (state) => state.getIn(appLocationFinderResultPath);

const getAppLocationFinderBrands = (state) => state.getIn(appLocationFinderBrandsPath);

export const getAppLocationFinderTimestamp = (state) => state.getIn(appLocationFinderTimestampPath);

export const getAdjustLocationId = (state) => state.getIn(appAdjustLocationIdPath);

export const getUpdatedSoldOutDateTime = (state) => state.getIn(updatedSoldOutDateTimePath);

export const getSoldOutArea = (state) => state.getIn(soldOutAreaPath);

export const locationFinderAlamoResultSelector = createSelector([getAppLocationFinderResult], (result) =>
  result.filter(({ brand }) => brand === LOCATIONS.BRAND_ALAMO)
);

export const locationFinderDataSelector = createSelector(
  [getAppLocationFinderResult, getAppLocationFinderBrands],
  (result, brands_in_result) => ({
    result,
    brands_in_result,
  })
);

export const locationFinderAnalyticsStateSelector = (state) => state.getIn(skipAnalyticsLocationFinderPath);

export const getUnauthReservationStateSelector = (state) => state.getIn(appUnauthReservationPath);

export const getSmsOptInEnabled = () => config.isNADomain() && config.isEnabledSmsOptIn();

export const getAutoCommasSmsImplicitConsentEnabled = () => !config.isNADomain() && config.isEnabledEuImpliedConsent();

export const getShowConsentToText = () => {
  const isConsentEnabled = getSmsOptInEnabled() || getAutoCommasSmsImplicitConsentEnabled();
  return isConsentEnabled;
};
