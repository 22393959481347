export const VEHICLE_LIST = {
  VEHICLE_CLASS_DATA: 'VEHICLE_CLASS_DATA',
  SET_VEHICLE_CLASS_FILTER_DATA: 'SET_VEHICLE_CLASS_FILTER_DATA',
  CLEAR_VEHICLE_CLASS_FILTER_DATA: 'CLEAR_VEHICLE_CLASS_FILTER_DATA',
  SET_VEHICLE_AVAILABILITY_DATA: 'SET_VEHICLE_AVAILABILITY_DATA',
  SET_SELECTED_VEHICLE_CURRENCY_TOGGLE_DATA: 'SET_SELECTED_VEHICLE_CURRENCY_TOGGLE_DATA',
};

export const setVehiclesClassData = data => ({
  type: VEHICLE_LIST.VEHICLE_CLASS_DATA,
  data,
});

export const setVehiclesAvailabilityData = data => ({
  type: VEHICLE_LIST.SET_VEHICLE_AVAILABILITY_DATA,
  data,
});

export const setVehiclesFilterData = (type, data) => dispatch =>
  dispatch({
    type,
    data,
  });

export const clearVehiclesFilterData = () => ({
  type: VEHICLE_LIST.CLEAR_VEHICLE_CLASS_FILTER_DATA,
});

export const setSelectedCurrencyToggleData = (data = 0) => ({
  type: VEHICLE_LIST.SET_SELECTED_VEHICLE_CURRENCY_TOGGLE_DATA,
  data,
});
