export const GLOBAL = {
  START_REQUEST: '@@global/START_REQUEST',
  COMPLETE_REQUEST: '@@global/COMPLETE_REQUEST',
  ERROR_ENCOUNTERED: '@@global/ERROR_ENCOUNTERED',
  RECEIVE_DEFAULT_AGE_RANGE: '@@global/RECEIVE_DEFAULT_AGE_RANGE',
};

export const requestStart = () => ({
  type: GLOBAL.START_REQUEST,
});

export const requestComplete = () => ({
  type: GLOBAL.COMPLETE_REQUEST,
});

export const errorEncountered = error => ({
  type: GLOBAL.ERROR_ENCOUNTERED,
  error,
});
